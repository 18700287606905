import axios from 'axios';
import getAppConfig from '../../config';
import { setGlobalLoader } from '../ui/uiActions';
import { setSnackBarAlert, caughtError } from '../ui/uiActions';
import { getQueryString } from '../../components/common/functions';

import {
  USER_SET_INITIAL_STATE,
  USER_FIND_ALL,
  USER_VIEW
} from './userTypes';

const config = getAppConfig();

export const viewUser = (userId) => {
  let URL = `${config.api.URL}/user/${userId}`;
  return (dispatch) => {
    dispatch(onSuccessViewUser({data: false}))
    axios.get(URL).then(response => {
      dispatch(onSuccessViewUser(response))
    }).catch(error => {
      dispatch(caughtError(error))
    })
  }
}

export const deleteUser = (userId, filterInput) => {
  let URL = `${config.api.URL}/user/mark-delete/${userId}`;
  if(filterInput.deleted === true) {
    // if record already deleted
    URL = `${config.api.URL}/user/unmark-delete/${userId}`;
  }
  return (dispatch) => {
    axios.patch(URL).then(response => {

      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'success',
        message: `Success! user ${filterInput.deleted ? 'restored' : ' moved to trash'}`
      }))

      dispatch(findAllUsers(filterInput));

    }).catch(error => {
      dispatch(caughtError(error))
    })
  }
}

export const findAllUsers  = (filters="") => {
  let URL = `${config.api.URL}/user`;
  if(filters !== '') {
    URL = `${URL}?${getQueryString(filters)}`;
  }
  return (dispatch) => {
    dispatch(setGlobalLoader(true))
    axios.get(URL).then(response => {
      dispatch(setGlobalLoader(false))
      dispatch(onSuccessFindAllUser(response))
    }).catch(error => {
      dispatch(setGlobalLoader(false))
      dispatch(setUserInitialState())
      dispatch(caughtError(error))
    })
  }
}
export const setUserInitialState = () => {
  return {
    type: USER_SET_INITIAL_STATE
  }
}

export const onSuccessViewUser = response => {
  return {
    type: USER_VIEW,
    payload: response.data
  }
}

export const onSuccessFindAllUser = response => {
  return {
    type: USER_FIND_ALL,
    payload: response.data
  }
}

import React, { useState, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box } from '@mui/system';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { findAllCustomScreens, caughtError, setSnackBarAlert } from '../../redux';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import getAppConfig from '../../config';
import draftToHtml from 'draftjs-to-html';
const config = getAppConfig();

const EditorToolBar = {
  inline: { inDropdown: true },
  list: { inDropdown: true },
  textAlign: { inDropdown: true },
  link: { inDropdown: true },
  history: { inDropdown: false },
}

function createMarkup(data) {
  return {__html: data };
}

const initialState = {
   title: '',
   contentType: 'rawHtml',
   rawHtmlContent: '',
   iframeUrl: '',
   active: true,
   subscribed: true
}

const reducer = (state, action) => {
  return {
  ...state,
  ...action
  }
}

const CreateForm = (props) => {

  const [state, updateState] = useReducer(reducer, initialState);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [rawHtmlContentPreview, setRawHtmlContentPreview] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    if(event.target.type === 'checkbox') {
      updateState({ [event.target.name]: event.target.checked });
    } else {
      updateState({ [event.target.name]: event.target.value });
    }
  }

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
  }

  const isValidHttpUrl = (string) => {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setBtnDisabled(true);
    const input = {
      title: state.title,
      contentType: state.contentType,
      rawHtmlContent: state.rawHtmlContent,
      iframeUrl: state.iframeUrl,
      active: state.active,
      subscribed: state.subscribed
    }

    if(input.contentType === 'WYSIWIG') {
      input.htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    }

    if(input.contentType === 'iframe') {
      if(input.iframeUrl === '') {
        dispatch(setSnackBarAlert({
          openSnackBar: true,
          severity: 'error',
          message: `iframeUrl is required if selected contentType iframe`
        }));
        return false;
      }
      if(isValidHttpUrl(input.iframeUrl) === false) {
        dispatch(setSnackBarAlert({
          openSnackBar: true,
          severity: 'error',
          message: `iframeUrl is not a valid URL`
        }));
        return false;
      }
    }

    axios.post(`${config.api.URL}/custom-screen`, input).then(response => {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'success',
        message: `Success! ${input.title} page created`
      }));
      dispatch(findAllCustomScreens(props.filterInput));
      props.setOpen(false);
    }).catch(error => {
      setBtnDisabled(false);
      dispatch(caughtError(error));
    })

  }

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent sx={{ marginTop: "-20px" }} >

        <Box sx={{ p: 2, border: '1px dashed black' }} >
          <div className="row">
            <div className="col-lg-4 col-12">
              <TextField
                value={state.title}
                onChange={handleInputChange}
                name="title"
                label="Title"
                margin="dense"
                type="text"
                fullWidth
                variant="outlined"
                required
              />
            </div>
            <div className={ props.session.roleId === 2 ? "col-lg-3 col-12" : "col-lg-4 col-12"}>
              <FormControl fullWidth sx={{ marginTop: "9px" }}>
                <InputLabel>ContentType</InputLabel>
              <Select
                fullWidth
                label="contentType"
                name="contentType"
                variant="outlined"
                value={state.contentType}
                onChange={handleInputChange}
              >
                <MenuItem value={''}>-- SELECT --</MenuItem>
                <MenuItem key={'rawHtml'} value={'rawHtml'}>Raw HTML</MenuItem>
                <MenuItem key={'WYSIWIG'} value={'WYSIWIG'}>WYSIWIG</MenuItem>
                <MenuItem key={'iframe'} value={'iframe'}>iframe</MenuItem>
              </Select>
              </FormControl>
            </div>
            <div className={ props.session.roleId === 2 ? "col-lg-5 col-12" : "col-lg-4 col-12"} style={{ textAlign: 'center' }}>
              {props.session.roleId === 2 && <FormControlLabel sx={{ margin: "auto", padding:'15px' }} className="float-end" control={<Switch name="subscribed" checked={state.subscribed} onChange={handleInputChange} />} label="Subscribed" labelPlacement="end" /> }
              <FormControlLabel sx={{ margin: "auto", padding:'15px' }} control={<Switch name="active" checked={state.active} onChange={handleInputChange} />} label="Active" labelPlacement="end" />
            </div>
            <div className="col-12" style={{ display: state.contentType === 'rawHtml' ? 'block' : 'none' }}>
              <TextField
                value={state.rawHtmlContent}
                onChange={handleInputChange}
                name="rawHtmlContent"
                label="Raw Html Content"
                margin="dense"
                type="text"
                fullWidth
                variant="outlined"
                multiline
                rows={4}
              /><br/>
            <FormControlLabel control={<Switch name="rawHtmlContentPreview" checked={rawHtmlContentPreview} onChange={ e => setRawHtmlContentPreview(e.target.checked) } />} label="Preview Raw HTML" labelPlacement="end" />
            <br/>
            { rawHtmlContentPreview && <div dangerouslySetInnerHTML={createMarkup(state.rawHtmlContent)} /> }

            </div>
            <div className="col-12" style={{ display: state.contentType === 'WYSIWIG' ? 'block' : 'none' }}>
              <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                toolbar={EditorToolBar}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </div>
            <div className="col-12" style={{ display: state.contentType === 'iframe' ? 'block' : 'none' }}>
              <TextField
                value={state.iframeUrl}
                onChange={handleInputChange}
                name="iframeUrl"
                label="iframeUrl"
                margin="dense"
                type="text"
                fullWidth
                variant="outlined"
              />
            </div>
          </div>
        </Box>

      </DialogContent>
      <DialogActions>
        <button className="btn btn-secondary" onClick={ (e) => {
            e.preventDefault();
            props.setOpen(false);
          } }>Cancel</button>
        <button disabled={btnDisabled} type="submit" className="btn btn-primary" >Add New Page</button>
      </DialogActions>
    </form>
  )

}


export default CreateForm;

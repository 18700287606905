const Footer = (props) => {

  const {
    totalPages,
    page,
    setPrevPage,
    setNextPage
  } = props;


  return (
    <ul className="pagination" style={{ fontFamily: 'Poppins, sans-serif' }}>
      <li className="page-item page-link" role="button" onClick={ (e) => setPrevPage() }><span aria-hidden="true">«</span></li>
      <li className="page-item page-link" style={{ color: 'white', background: `#808080` }}>{page}/{totalPages}</li>
      <li className="page-item page-link" role="button" onClick={ (e) => setNextPage() }><span aria-hidden="true">»</span></li>
    </ul>
  );
}

export default Footer;

import axios from 'axios';
import getAppConfig from '../../config';
import { setGlobalLoader } from '../ui/uiActions';
import { setSnackBarAlert, caughtError } from '../ui/uiActions';
import { getQueryString } from '../../components/common/functions';

import {
  CUSTOM_SCREEN_SET_INITIAL_STATE,
  CUSTOM_SCREEN_FIND_ALL,
  CUSTOM_SCREEN_VIEW
} from './customScreenTypes';

const config = getAppConfig();

export const viewCustomScreen = (screenId) => {
  let URL = `${config.api.URL}/custom-screen/${screenId}`;
  return (dispatch) => {
    dispatch(onSuccessViewCustomScreen({data: false}))
    axios.get(URL).then(response => {
      dispatch(onSuccessViewCustomScreen(response))
    }).catch(error => {
      dispatch(caughtError(error))
    })
  }
}

export const deleteCustomScreen = (screenId, filterInput) => {
  let URL = `${config.api.URL}/custom-screen/mark-delete/${screenId}`;
  if(filterInput.deleted === true) {
    // if record already deleted
    URL = `${config.api.URL}/custom-screen/unmark-delete/${screenId}`;
  }
  return (dispatch) => {
    axios.patch(URL).then(response => {

      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'success',
        message: `Success! page ${filterInput.deleted ? 'restored' : ' moved to trash'}`
      }))

      dispatch(findAllCustomScreens(filterInput));
    }).catch(error => {
      dispatch(caughtError(error))
    })
  }
}

export const findAllCustomScreens  = (filters="") => {
  let URL = `${config.api.URL}/custom-screen`;
  if(filters !== '') {
    URL = `${URL}?${getQueryString(filters)}`;
  }
  return (dispatch) => {
    dispatch(setGlobalLoader(true))
    axios.get(URL).then(response => {
      dispatch(setGlobalLoader(false))
      dispatch(onSuccessFindAllCustomScreen(response))
    }).catch(error => {
      dispatch(setGlobalLoader(false))
      dispatch(setCustomScreenInitialState())
      dispatch(caughtError(error))
    })
  }
}
export const setCustomScreenInitialState = () => {
  return {
    type: CUSTOM_SCREEN_SET_INITIAL_STATE
  }
}

export const onSuccessViewCustomScreen = response => {
  return {
    type: CUSTOM_SCREEN_VIEW,
    payload: response.data
  }
}

export const onSuccessFindAllCustomScreen = response => {
  return {
    type: CUSTOM_SCREEN_FIND_ALL,
    payload: response.data
  }
}

import axios from 'axios';
import getAppConfig from '../../config';
import { setGlobalLoader } from '../ui/uiActions';
import { setSnackBarAlert, caughtError } from '../ui/uiActions';
import { getQueryString } from '../../components/common/functions';

import {
  MODULE_SET_INITIAL_STATE,
  MODULE_FIND_ALL,
  MODULE_VIEW
} from './moduleTypes';

const config = getAppConfig();

export const viewModule = (moduleId) => {
  let URL = `${config.api.URL}/module/${moduleId}`;
  return (dispatch) => {
    dispatch(onSuccessViewModule({data: false}))
    axios.get(URL).then(response => {
      dispatch(onSuccessViewModule(response))
    }).catch(error => {
      dispatch(caughtError(error))
    })
  }
}

export const deleteModule = (moduleId, filterInput) => {
  let URL = `${config.api.URL}/module/mark-delete/${moduleId}`;
  if(filterInput.deleted === true) {
    // if record already deleted
    URL = `${config.api.URL}/module/unmark-delete/${moduleId}`;
  }
  return (dispatch) => {
    axios.patch(URL).then(response => {

      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'success',
        message: `Success! module ${filterInput.deleted ? 'restored' : ' moved to trash'}`
      }))

      dispatch(findAllModules(filterInput));
    }).catch(error => {
      dispatch(caughtError(error))
    })
  }
}

export const findAllModules  = (filters="") => {
  let URL = `${config.api.URL}/module`;
  if(filters !== '') {
    URL = `${URL}?${getQueryString(filters)}`;
  }
  return (dispatch) => {
    dispatch(setGlobalLoader(true))
    axios.get(URL).then(response => {
      dispatch(setGlobalLoader(false))
      dispatch(onSuccessFindAllModule(response))
    }).catch(error => {
      dispatch(setGlobalLoader(false))
      dispatch(setModuleInitialState())
      dispatch(caughtError(error))
    })
  }
}
export const setModuleInitialState = () => {
  return {
    type: MODULE_SET_INITIAL_STATE
  }
}

export const onSuccessViewModule = response => {
  return {
    type: MODULE_VIEW,
    payload: response.data
  }
}

export const onSuccessFindAllModule = response => {
  return {
    type: MODULE_FIND_ALL,
    payload: response.data
  }
}

//import { Link } from "react-router-dom";
import TopLogo from '../misc/TopLogo';
import Alert from '@mui/material/Alert';
import LoginForm from '../misc/LoginFormContainer';

const NotConnectedVPn = (props) => {

  return <main>
    <div className="container">
      <section className="section register min-vh-100 d-flex flex-column align-items-center py-4">

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

              <TopLogo />

              { props.message !== '' && <Alert severity="error">{props.message}</Alert> }

                <div className="col-12">
                  <div className="hrdivider">
                      <hr/>
                      <span>OR</span>
                  </div>
                  <br />
                </div>

                <LoginForm showOnlyLoginButton={true} />

                {
                /* <p className="text-center">
                  <span style={{ marginRight: '18px', fontSize: "1.1em" }}><Link to="/users/login">Login</Link></span>
                  </p>
              */ }

            </div>
          </div>
        </div>

      </section>
      </div>
  </main>
}

export default NotConnectedVPn;

import getAppConfig from '../../config';
const config = getAppConfig();

function getIOSocket() {
  const socketIOClient = require('socket.io-client');
  const sailsIOClient = require('sails.io.js');
  let io = sailsIOClient(socketIOClient);
  io.sails.url = config.api.HOST;
  io.sails.transports = ["websocket"];
  io.sails.autoConnect = true;
  io.sails.reconnection = true;
  io.sails.environment = 'development';
  return io;
}

const io = getIOSocket();
export const socket = io.socket;

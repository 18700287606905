import React, { useState } from 'react';
import CreateForm from './CreateForm';
import CustomDialog from '../ui/CustomDialog';

const HeaderSection = (props) => {

  const [open, setOpen] = useState(false);
  const [maxWidth, setMaxWidth] = useState('sm');

  return (
    <>
      <div className="row" style={{marginBottom: "10px" }}>
        <div className="col-lg-12">
          <button type="button" className="btn btn-primary float-end" onClick={ (e) => setOpen(true) } >Add New Sale</button>
        </div>
      </div>
      <CustomDialog maxWidth={maxWidth}  title="Add New Sale" open={open} setOpen={setOpen} >
        <CreateForm
          setModalSize={setMaxWidth}
          setOpen={setOpen}
          filterInput={props.filterInput}
          youtubeLibrary={props.youtubeLibrary}
          industriesList={props.industriesList}
          />
      </CustomDialog>
    </>
  );
}

export default HeaderSection;

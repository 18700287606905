import React from 'react';
import { connect } from 'react-redux'
import AdminLinks from "./roleLinks/AdminLinks";

import {
  GetDashboardLink,
  GetSaleLink,
  GetMilestoneLink,
  GetUserLink,
  GetCustomScreenLink,
  GetSideBarLinks,
  GetYoutubeLibraryLink
} from "./roleLinks/OtherLinks";

class SideBar extends React.Component {

  // constructor(props) {
  //   super(props);
  // }

  render() {
    let siderbarLinks = '';
    if(this.props.session.roleId === 3) {
      siderbarLinks =  <AdminLinks />
    } else {
      let sideBarModules = [];
      sideBarModules.push(<GetDashboardLink />);
      /**  for rest users **/
      if(this.props.profile.myModules.length > 0) {
        const myModules = this.props.profile.myModules;
        for(const rowModule of myModules) {
          switch(rowModule.moduleId) {
            case 1: /* USER MODULE */
              if(rowModule.readAccess || rowModule.writeAccess) {
                sideBarModules.push(<GetUserLink />)
              }
              break;
            case 2: /* Sale MODULE */
              if(rowModule.readAccess || rowModule.writeAccess) {
                sideBarModules.push(<GetSaleLink />)
              }
              break;
            case 3: /* Milestone MODULE */
              if(rowModule.readAccess || rowModule.writeAccess) {
                sideBarModules.push(<GetMilestoneLink />)
              }
              break;
          case 4: /* CustomScreen MODULE */
            if(rowModule.readAccess || rowModule.writeAccess) {
              sideBarModules.push(<GetCustomScreenLink />)
            }
            break;
            default: break;
          }
        }
      }
      sideBarModules.push(<GetYoutubeLibraryLink />);
      siderbarLinks = <GetSideBarLinks sideBarModules={sideBarModules} />
    }

    return (
        <aside id="sidebar" className="sidebar">
          <ul className="sidebar-nav" id="sidebar-nav">
            {siderbarLinks}
          </ul>
          {/*
            <div style={{ position: 'absolute', left: '35%', bottom: 2 }}>
              { this.props.session.logoFileName &&
                <img src={this.props.session.logoResizedUrl} alt="" />
              }
            </div>

            */}
        </aside>
    );
  }

}

const mapStateToProps = state => {
  return {
    session: state.session,
    profile: state.profile
  }
}

export default connect(
  mapStateToProps
)(SideBar)

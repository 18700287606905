import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import { CircularProgress } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircleIcon from '@mui/icons-material/Circle';
import { viewUser } from '../../redux';

const ViewDetails = (props) => {

  const viewResult = useSelector((state) => state.user.viewResult);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if(loading && props.modalDiaLogId && props.modalDiaLogId > 0) {
      dispatch(viewUser(props.modalDiaLogId));
      setLoading(false);
    }
  }, [props, loading, viewResult, dispatch]);


  const details = (
    loading === false && viewResult ?
    <ul className="list-group">
    <li className="list-group-item">
    <strong className="p-2">UserId:</strong> {viewResult.id}
    <Avatar variant="square" className="float-end" src={viewResult.googlePicture} sx={{ width: 48, height: 48, bgcolor: deepOrange[500] }}>
      {viewResult.name.charAt(0)}
    </Avatar>
    </li>
    <li className="list-group-item"><strong className="p-2">ProfileName:</strong>{viewResult.name}</li>
    <li className="list-group-item"><strong className="p-2">LastAccess:</strong> {viewResult.lastAccess}</li>
    <li className="list-group-item"><strong className="p-2">LastRequestIp:</strong> {viewResult.lastRequestIp}</li>
    <li className="list-group-item"><strong className="p-2">TeamName:</strong> <CircleIcon style={{ color: viewResult.colorCode }} /> {viewResult.teamName}</li>
    <li className="list-group-item"><strong className="p-2">Email:</strong> {viewResult.email}</li>
    <li className="list-group-item"><strong className="p-2">Contact:</strong> {viewResult.contact}</li>
    <li className="list-group-item"><strong className="p-2">LastLogin:</strong> {viewResult.lastLogin}</li>
    <li className="list-group-item"><strong className="p-2">JobTitle:</strong> {viewResult.jobTitle}</li>
    <li className="list-group-item"><strong className="p-2">Role:</strong> {viewResult.roleName}</li>
    <li className="list-group-item"><strong className="p-2">Active:</strong> {viewResult.active ? "YES" : "NO"}</li>
    <li className="list-group-item"><strong className="p-2">Theme:</strong> {viewResult.theme}</li>
    <li className="list-group-item"><strong className="p-2">CreatedAt:</strong> {viewResult.createdAt}</li>
    <li className="list-group-item"><strong className="p-2">UpdatedAt:</strong> {viewResult.updatedAt}</li>
  </ul> : <CircularProgress /> )

  return (
    <>
    <DialogContent sx={{ marginTop: "-10px" }} >
      { details }
    </DialogContent>
    <DialogActions>
      <button className="btn btn-secondary" onClick={ () => props.setOpen(false) } >Close</button>
    </DialogActions>
    </>
  )

}

export default ViewDetails;

import { combineReducers } from 'redux';
import uiReducer from './ui/uiReducer';
import teamReducer from './team/teamReducer';
import roleReducer from './role/roleReducer';
import sessionReducer from './session/sessionReducer';
import profileReducer from './profile/profileReducer';
import userReducer from './user/userReducer';
import moduleReducer from './module/moduleReducer';
import customScreenReducer from './custom-screen/customScreenReducer';
import teamGroupReducer from './team-group/teamGroupReducer';
import milestoneTypeReducer from './milestone-type/milestoneTypeReducer';
import saleReducer from './sale/saleReducer';
import milestoneReducer from './milestone/milestoneReducer';
import screenReducer from './screen/screenReducer';
import youtubeLibraryReducer from './youtube-library/youtubeLibraryReducer';
import logReducer from './logs/logReducer';
import industryReducer from './industry/industryReducer';
import webhookReducer from './webhook/webhookReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  screen: screenReducer,
  ui: uiReducer,
  role: roleReducer,
  team: teamReducer,
  profile: profileReducer,
  user: userReducer,
  module: moduleReducer,
  customScreen: customScreenReducer,
  teamGroup: teamGroupReducer,
  milestoneType: milestoneTypeReducer,
  sale: saleReducer,
  milestone: milestoneReducer,
  youtubeLibrary: youtubeLibraryReducer,
  log: logReducer,
  industry: industryReducer,
  webhook: webhookReducer
})

export default rootReducer

import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector, useDispatch } from 'react-redux';
import Slide from '@mui/material/Slide';
import { setSnackBarAlert } from '../../redux';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const CustomSnackBar  = (props) => {
  const ui = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setSnackBarAlert({
      openSnackBar: false
    }))
  }

  let _content = null;

  if(ui.message !== '' && ui.openSnackBar) {
    _content = <>
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={ui.openSnackBar}
      onClose={handleClose}
      key={ui.message}
      autoHideDuration={4000}
      TransitionComponent={TransitionLeft}
    >
    <Alert variant="standard" onClose={handleClose} severity={ui.severity} sx={{ fontFamily: 'Poppins, sans-serif', width: '100%' }}>
        {ui.message}
    </Alert>
    </Snackbar>
    </>
  }

  return (
    <>{_content}</>
  );
}

export default CustomSnackBar;

import React, { useEffect, useState, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { Box } from '@mui/system';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { findAllCustomScreens, caughtError, setSnackBarAlert } from '../../redux';
import getAppConfig from '../../config';
const config = getAppConfig();

const EditorToolBar = {
  inline: { inDropdown: true },
  list: { inDropdown: true },
  textAlign: { inDropdown: true },
  link: { inDropdown: true },
  history: { inDropdown: false },
}

function createMarkup(data) {
  return {__html: data };
}

const initialState = {
   title: '',
   contentType: 'rawHtml',
   rawHtmlContent: '',
   iframeUrl: '',
   active: true
}

const reducer = (state, action) => {
  return {
  ...state,
  ...action
  }
}

const EditForm   = (props) => {

  const [state, updateState] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(true);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [rawHtmlContentPreview, setRawHtmlContentPreview] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    if(event.target.type === 'checkbox'){
      updateState({ [event.target.name]: event.target.checked });
    } else {
      updateState({ [event.target.name]: event.target.value });
    }
  }

  useEffect(() => {
    if(loading && props.modalDiaLogId && props.modalDiaLogId > 0) {
      let URL = `${config.api.URL}/custom-screen/${props.modalDiaLogId}`;
      axios.get(URL).then(response => {

        updateState({
          title: response.data.data.title,
          contentType: response.data.data.contentType,
          rawHtmlContent: response.data.data.rawHtmlContent,
          iframeUrl: response.data.data.iframeUrl,
          active: Boolean(response.data.data.active),
        })

        const contentBlock = htmlToDraft(response.data.data.htmlContent);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);

        setBtnDisabled(false);
        setLoading(false);
      }).catch(error => {
        setLoading(false);
        console.log(error);
      })
    }
  }, [loading, props]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  }

  const isValidHttpUrl = (string) => {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const input = {
      title: state.title,
      contentType: state.contentType,
      rawHtmlContent: state.rawHtmlContent,
      iframeUrl: state.iframeUrl,
      active: state.active
    }

    if(input.contentType === 'WYSIWIG') {
      input.htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    }

    if(input.contentType === 'iframe') {
      if(input.iframeUrl === '') {
        dispatch(setSnackBarAlert({
          openSnackBar: true,
          severity: 'error',
          message: `iframeUrl is required if selected contentType iframe`
        }));
        return false;
      }
      if(isValidHttpUrl(input.iframeUrl) === false) {
        dispatch(setSnackBarAlert({
          openSnackBar: true,
          severity: 'error',
          message: `iframeUrl is not a valid URL`
        }));
        return false;
      }
    }

    setBtnDisabled(true);
    axios.patch(`${config.api.URL}/custom-screen/${props.modalDiaLogId}`, {
      ...input
    }).then(response => {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'success',
        message: `Success! ${input.title} page updated`
      }));

      dispatch(findAllCustomScreens(props.filterInput));
      props.setOpen(false);
    }).catch(error => {
      setBtnDisabled(false);
      dispatch(caughtError(error));
    })

  }

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent sx={{ marginTop: "-20px" }} >

        <Box sx={{ p: 2, border: '1px dashed black' }} >
          <div className="row">
            <div className="col-lg-4 col-12">
              <TextField
                value={state.title}
                onChange={handleInputChange}
                name="title"
                label="Title"
                margin="dense"
                type="text"
                fullWidth
                variant="outlined"
                required
              />
            </div>
            <div className="col-lg-4 col-12">
              <FormControl fullWidth sx={{ marginTop: "9px" }}>
                <InputLabel>ContentType</InputLabel>
              <Select
                fullWidth
                label="contentType"
                name="contentType"
                variant="outlined"
                value={state.contentType}
                onChange={handleInputChange}
              >
                <MenuItem value={''}>-- SELECT --</MenuItem>
                <MenuItem key={'rawHtml'} value={'rawHtml'}>Raw HTML</MenuItem>
                <MenuItem key={'WYSIWIG'} value={'WYSIWIG'}>WYSIWIG</MenuItem>
                <MenuItem key={'iframe'} value={'iframe'}>iframe</MenuItem>
              </Select>
              </FormControl>
            </div>
            <div className="col-lg-4 col-12" style={{ textAlign: 'center' }}>
              <FormControlLabel sx={{ margin: "auto", padding:'15px' }} control={<Switch name="active" checked={state.active} onChange={handleInputChange} />} label="Active" labelPlacement="end" />
            </div>
            <div className="col-12" style={{ display: state.contentType === 'rawHtml' ? 'block' : 'none' }}>
              <TextField
                value={state.rawHtmlContent}
                onChange={handleInputChange}
                name="rawHtmlContent"
                label="Raw Html Content"
                margin="dense"
                type="text"
                fullWidth
                variant="outlined"
                multiline
                rows={4}
              /><br/>
            <FormControlLabel control={<Switch name="rawHtmlContentPreview" checked={rawHtmlContentPreview} onChange={ e => setRawHtmlContentPreview(e.target.checked) } />} label="Preview Raw HTML" labelPlacement="end" />
            <br/>
            { rawHtmlContentPreview && <div dangerouslySetInnerHTML={createMarkup(state.rawHtmlContent)} /> }

            </div>
            <div className="col-12" style={{ display: state.contentType === 'WYSIWIG' ? 'block' : 'none' }}>
              <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                toolbar={EditorToolBar}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </div>
            <div className="col-12" style={{ display: state.contentType === 'iframe' ? 'block' : 'none' }}>
              <TextField
                value={state.iframeUrl}
                onChange={handleInputChange}
                name="iframeUrl"
                label="iframeUrl"
                margin="dense"
                type="text"
                fullWidth
                variant="outlined"
              />
            </div>

          </div>
        </Box>

      </DialogContent>
      <DialogActions>
        <button className="btn btn-secondary" onClick={ (e) => {
            e.preventDefault();
            props.setOpen(false);
          } }>Cancel</button>
        <button disabled={btnDisabled} type="submit" className="btn btn-primary" >Update Screen</button>
      </DialogActions>
    </form>
  )

}

export default EditForm;

const Overview = ({ profile, session }) => {

  return (
    <>
    <h5 className="card-title">Profile Details</h5>
    <div className="row">
      <div className="col-lg-3 col-md-4 label ">Email</div>
      <div className="col-lg-9 col-md-8">{profile.email}</div>
    </div>
    <div className="row">
      <div className="col-lg-3 col-md-4 label ">Full Name</div>
      <div className="col-lg-9 col-md-8">{profile.name}</div>
    </div>
    <div className="row">
      <div className="col-lg-3 col-md-4 label ">Contact Number</div>
      <div className="col-lg-9 col-md-8">{profile.mobile}</div>
    </div>
    <div className="row">
      <div className="col-lg-3 col-md-4 label ">Theme</div>
      <div className="col-lg-9 col-md-8">{profile.theme}</div>
    </div>
    <div className="row">
      <div className="col-lg-3 col-md-4 label ">JobTitle</div>
      <div className="col-lg-9 col-md-8">{profile.jobTitle}</div>
    </div>
    <div className="row">
      <div className="col-lg-3 col-md-4 label ">Team</div>
      <div className="col-lg-9 col-md-8">{session.teamName}</div>
    </div>
    </>
  );
}

export default Overview;

import { Link } from 'react-router-dom';

const TopLogo = () => {

  return (
    <div className="d-flex justify-content-center py-4">
      <Link to="/screen" className="btn">
        <div className="logo d-flex align-items-center w-auto">
          <img src="/assets/img/logo.png" alt="" />
        </div>
      </Link>
    </div>
  );
}


export default TopLogo;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import { setSnackBarAlert, caughtError } from '../../redux';
import getAppConfig from '../../config';
const config = getAppConfig();

const ClientSearch = (props) => {

  const [accountNumber, setAccountNumber] = useState('');
  const [externalAccountNumber, setExternalAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const dispatch = useDispatch();

  const handleSearchClients = () => {
    const input = {
      accountNumber: accountNumber,
      externalAccountNumber: externalAccountNumber,
      accountName: accountName
    }

    let apiUrl = `${config.api.URL}/search/client-accounts`;
    let queryParms = [];
    if(input.accountNumber !== '') {
      if(input.accountNumber.length < 3) {
        dispatch(setSnackBarAlert({
          openSnackBar: true,
          severity: 'error',
          message: `accountNumber: Minimum 3 charactors required OR leave empty`
        }));
        return false;
      }
      queryParms.push(`accountNumber=${input.accountNumber}`);
    }

    if(input.externalAccountNumber !== '') {
      if(input.externalAccountNumber.length < 3) {
        dispatch(setSnackBarAlert({
          openSnackBar: true,
          severity: 'error',
          message: `externalAccountNumber: Minimum 3 charactors required OR leave empty`
        }));
        return false;
      }
      queryParms.push(`externalAccountNumber=${input.externalAccountNumber}`);
    }

    if(input.accountName !== '') {
      if(input.accountName.length < 3) {
        dispatch(setSnackBarAlert({
          openSnackBar: true,
          severity: 'error',
          message: `accountName: Minimum 3 charactors required OR leave empty`
        }));
        return false;
      }
      queryParms.push(`accountName=${input.accountName}`);
    }

    apiUrl = `${apiUrl}?${queryParms.join('&')}`;

    axios.get(apiUrl).then(response => {
      setSearchResult(response.data.data);

    }).catch(error => {

      dispatch(caughtError(error));
    })

  }

  let searchResultContent = null;
  if(searchResult.length > 0) {
    searchResultContent = searchResult.map( (row) =>
    <tr key={row.accountNumber}>
      <td>{ row.accountNumber }</td>
      <td>{ row.externalAccountNumber }</td>
      <td>{ row.displayName }</td>
      <td>
        <span className="btn btn-link"
          onClick={ (e) => props.updateAccountNumber(row.accountNumber)}>
          Select
        </span>
      </td>
    </tr>
  )
  }


  return (
    <>
      <h5 className="cart-title" >Search Clients:</h5>
      <div className="row">
      <div className="col-4">
        <TextField
          value={accountNumber}
          onChange={ e => setAccountNumber(e.target.value) }
          name="accountNumber"
          label="Account Number"
          margin="dense"
          type="text"
          fullWidth
          variant="outlined"
          onKeyPress={ (e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearchClients();
              }
            }
          }
        /><br/>
      <small>e.g. A2ANmad00000000001 </small>
      </div>
      <div className="col-4">
        <TextField
          value={externalAccountNumber}
          onChange={ e => setExternalAccountNumber(e.target.value) }
          name="externalAccountNumber"
          label="External Account Number"
          margin="dense"
          type="text"
          fullWidth
          variant="outlined"
          onKeyPress={ (e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearchClients();
              }
            }
          }
        /><br/>
      <small>e.g. D1000 </small>
      </div>
      <div className="col-4">
        <TextField
          autoFocus
          value={accountName}
          onChange={ e => setAccountName(e.target.value) }
          name="accountName"
          label="Display Name"
          margin="dense"
          type="text"
          fullWidth
          variant="outlined"
          onKeyPress={ (e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearchClients();
              }
            }
          }
        /><br/>
      <small>e.g. Madwire CRM </small>
      </div>
      </div>

      { searchResult.length > 0 && <table className="table table-striped" >
        <tbody>
          <tr>
            <th>AccountNumber</th>
            <th>ExternalAccountNumber</th>
            <th>DisplayName</th>
            <th>Action</th>
          </tr>
        </tbody>
        <tbody>
          { searchResultContent }
        </tbody>
      </table>
      }

    </>
  )

}

export default ClientSearch;

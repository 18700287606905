import AppLogoSection from './partialsHeader/AppLogoSection';
import NavItemSection from './partialsHeader/NavItemSection';
import CustomTimer from "../components/ui/CustomTimer";

const Header = () => {

  return (
    <header id="header" className={"header fixed-top d-flex align-items-center"}>
      <AppLogoSection />

      <div className="mx-3" style={{ fontFamily: '"Poppins", sans-serif' }}>
        <CustomTimer />
      </div>

      <NavItemSection />
    </header>
  );
}

export default Header;

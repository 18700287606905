import React, { useEffect, useCallback, useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { setScreenTeamList } from '../../../redux';
import CircleIcon from '@mui/icons-material/Circle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import getAppConfig from '../../../config';
const config = getAppConfig();

const Header = (props) => {

  const session = useSelector((state) => state.session);
  const common = useSelector((state) => state.screen.common);
  const teamList = useSelector((state) => state.screen.teamList);
  const [teamId, setTeamId] = useState(0);
  const [showTeamDropdown, setShowTeamDropdown] = useState(false);
  const dispatch = useDispatch();

  const getAllTeams = useCallback(() => {
    axios.get(`${config.api.URL}/public/all-teams`).then(response => {
      const { teamId, teamName, teamSlug, colorCode, logoFileName, logoUrl } = session;

      let newList = [];
      const selectedTeam = {
        id: teamId,
        teamName,
        slug: teamSlug,
        colorCode,
        logoFileName: logoFileName,
        logoUrl: logoUrl,
        active: true
      };

      newList.push({
        id: 0,
        teamName: "MAIN",
        slug: "noslugexist",
        colorCode: "#FFF",
        active: true,
        logoFileName: "",
        logoUrl: "",
      });

      newList.push(selectedTeam);

      for(const _row of response.data.data){
        if(teamId !== _row.id) {
          newList.push(_row);
        }
      }

      setTeamId(teamId);
      dispatch(setScreenTeamList(newList));
      props.setScreenTeamId(teamId, selectedTeam);

    }).catch(error => {
      console.log(`public/all-teams error => `,error);
    })
  }, [session, props, dispatch]);

  const handleTeamSelection = (event) => {
    const target = event.target;
    const teamId = parseInt(target.value);
    let selectedTeam = null;

    if(teamId > 0) {
      selectedTeam = teamList.find((_row) => _row.id === teamId )
    }

    setTeamId(teamId);

    props.setScreenTeamId(teamId, selectedTeam);

  }

  useEffect(() => {
    setTimeout(() => {
      if(session.isAuthenticated && showTeamDropdown === false) {
        getAllTeams();
        setShowTeamDropdown(true);
      }
    }, 1000);

  }, [session, showTeamDropdown, getAllTeams]);

  let teamsSelect = '';
  if(teamList.length > 0) {
    const teamSelectionInput = teamList.map( (row) =>
      <MenuItem key={`${row.id}`} value={`${row.id}`} >
       {
         row.logoFileName ?
         <img
           src={row.logoUrl}
           alt={row.teamName}
           style={{
             maxHeight: '60px',
             width: '80px',
           }}
          /> :
           <>
           { row.id > 0 && <CircleIcon style={{ color: row.colorCode }} fontSize="large" />}
           {''}
           </>
        }
       <span className="mx-2">{row.teamName}</span>
      </MenuItem>
    )

    teamsSelect = <div className={"theme"+session.theme+"selection"} ><Select
      disableUnderline={true}
      size="small"
      label="Team"
      name="teamId"
      variant="standard"
      margin="dense"
      value={teamId}
      onChange={handleTeamSelection} >
      {teamSelectionInput}
  </Select></div>
  }

  const {
    todaySalesCount,
    todaySalesTotalAmount,
    todaySalesByGroup
  } = common;

  let todaySalesByGroupContent = null;
  if(todaySalesByGroup.length > 0){
    todaySalesByGroupContent = todaySalesByGroup.map((row) =>
      <span key={row.shortCode} className="mx-2">
        <strong>{row.shortCode}</strong>: ${row.amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
      </span>
    )
  }

  let appLogo = <img src="/assets/img/logo.png" alt="" />
  if(session.theme === 'dark') {
    appLogo = <img src="/assets/img/madwire-logo-white-text.png" alt="" />
  }


  return (
    <>
    <header id="header" className="header fixed-top d-flex align-items-center" style={{ height: '70px', fontFamily: "'Circular', poppins" }} >

      <div className="col-lg-2 d-none d-lg-block">
        <Link to={session.isAuthenticated ? '/': '/users/login'} className="logo d-flex align-items-center">
          {appLogo}
        </Link>
      </div>

      <div className="text-center col-lg-3 col-md-6 col-sm-8 col-xs-8">
        <span><strong>Sales Count</strong>: {todaySalesCount}</span>
        <span className="mx-2" ><strong>Total Sales</strong>: ${todaySalesTotalAmount ? todaySalesTotalAmount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}): '0'}</span>
      </div>

      <div className="text-center col-lg-5 col-md-6 d-none d-lg-block d-md-block">
        { todaySalesByGroupContent }
      </div>

     <div className="col d-none d-lg-block">
       <div id="screen-team-selection" className="float-end" style={{ marginRight: '5%' }}>
         { session.isAuthenticated && teamsSelect }
       </div>
     </div>

    </header>
    </>
  );
}


export default Header;

import React from 'react';
import { CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Divider from '@mui/material/Divider';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


export const GetTcolumns = (props) => {
  const result = <tr>
    <th width="5%" scope="col">{ props.openGlobalLoader ? <CircularProgress size={'1rem'} /> : '#' }</th>
    <th width="35%" scope="col">Title</th>
    <th width="15%" scope="col" style={{ textAlign: 'center' }}>contentType</th>
    <th width="20%" scope="col">AddedBy</th>
    <th width="10%" scope="col" style={{ textAlign: 'center' }}>
      <span style={{marginLeft: "25%" }}>Active</span>
    </th>
    <th width="15%" scope="col" style={{ textAlign: 'center' }}>
      <span style={{marginLeft: "33%" }}>Action</span>
    </th>
  </tr>
  return result;
}

export const GetTsearch = (props) => {

  const result = <tr>
    <td></td>
    <td>
      <TextField
        onKeyPress={ (e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              props.onSearchInputEnterKey();
            }
          }
        }
        fullWidth
        label="Title"
        size="small"
        name="title"
        value={props.title}
        onChange={props.handleInputChange}
      />
    </td>
    <td></td>
    <td><TextField
      onKeyPress={ (e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            props.onSearchInputEnterKey();
          }
        }
      }
      fullWidth
      label="AddedBy"
      size="small"
      name="screenOwner"
      value={props.screenOwner}
      onChange={props.handleInputChange}
    /></td>
    <td>
      <Select
        fullWidth
        label="Active"
        size="small"
        name="active"
        variant="standard"
        margin="dense"
        value={props.active}
        onChange={props.handleInputChange}
      >
        <MenuItem value={''}>-- SELECT --</MenuItem>
        <MenuItem key={'1'} value={'1'}>YES</MenuItem>
        <MenuItem key={'0'} value={'0'}>NO</MenuItem>
      </Select>
    </td>
    <td><button type="button" className="btn btn-link float-end" onClick={props.resetTableSearchInput}>Clear Filters</button></td>
  </tr>

  return result;
}

const ListItem = (props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    row,
    rowIndex
  } = props;

  let menuItems = null;

  if(props.session.roleId === 3) {
    menuItems = (
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} >
        <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.title, 'VIEW'); }} disableRipple>
          <VisibilityIcon />{'  '} View
        </MenuItem>
        <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.title, 'EDIT'); }} disableRipple>
          <EditIcon />{'  '} Edit
        </MenuItem>
        <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.title, 'DELETE'); }} disableRipple>
          { props.deleted ? <><RestoreFromTrashIcon />Restore</> : <><DeleteIcon />Delete</> }
        </MenuItem>
        <Divider />
        <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.title, 'SUBSCRIPTION'); }} disableRipple>
          <SubscriptionsIcon />{'  '} Subscription
        </MenuItem>
      </Menu>
    );
  } else if(props.session.roleId === 2 && props.session.id === row.addedBy) {
    menuItems = (
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} >
        <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.title, 'VIEW'); }} disableRipple>
          <VisibilityIcon />{'  '} View
        </MenuItem>
        <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.title, 'EDIT'); }} disableRipple>
          <EditIcon />{'  '} Edit
        </MenuItem>
        <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.title, 'DELETE'); }} disableRipple>
          { props.deleted ? <><RestoreFromTrashIcon />Restore</> : <><DeleteIcon />Delete</> }
        </MenuItem>
        <Divider />
        <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.title, 'SUBSCRIPTION'); }} disableRipple>
          <SubscriptionsIcon />{'  '} Subscription
        </MenuItem>
      </Menu>
    );
  } else {
    menuItems = (
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} >
        <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.title, 'VIEW'); }} disableRipple>
          <VisibilityIcon />{'  '} View
        </MenuItem>
        <Divider />
        <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.title, 'SUBSCRIPTION'); }} disableRipple>
          <SubscriptionsIcon />{'  '} Subscription
        </MenuItem>
      </Menu>
    );
  }

  return (
      <tr>
      <td>{ rowIndex }</td>
      <td valign="middle">{ row.title }</td>
      <td valign="middle" align="center">{ row.contentType }</td>
      <td valign="middle">{ row.screenOwner }</td>
      <td valign="middle" align="center">{ row.active ? 'YES' : 'NO' }</td>
      <td align="right">
        <Button
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Action
      </Button>
      {menuItems}
      </td>

    </tr>
  );
}


export const GetTvalues = (props) => {
  let rowIndex = props.t_index;
  const result = props.t_data.map((row) =>
    <ListItem
      rowIndex={++rowIndex}
      key={row.id}
      row={row}
      setOpen={props.setOpen}
      deleted={props.deleted}
      session={props.session}
    />
  )

  return result;
}

import _ from "lodash";
import {
  SET_GLOBAL_LOADER,
  SET_SNACKBAR_ALERT,
} from './uiTypes';

import { logOutUserSession } from '../session/sessionActions';

export const setGlobalLoader = (value) => {
  return {
    type: SET_GLOBAL_LOADER,
    payload: value
  }
}
export const setSnackBarAlert = (value) => {
  if(value.openSnackBar === false) {
    return {
      type: SET_SNACKBAR_ALERT,
      payload: {
        openSnackBar: false,
        severity: "",
        message: "",
      }
    }
  } else {
    return {
      type: SET_SNACKBAR_ALERT,
      payload: value
    }
  }
}
export const caughtError = (error) => {
  return (dispatch) => {
    //console.log('in caughtError =>',JSON.stringify(error));
    const snackBarMsg = _.get(error,['response','data','error','message']) ? error.response.data.error.message : error.message;
    dispatch(setGlobalLoader(false));
    if (_.get(error,['response','status']) && error.response.status === 401) {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: snackBarMsg
      }));
      dispatch(logOutUserSession());
    } else {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: snackBarMsg
      }));
    }

  }
}

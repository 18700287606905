const PageTitleContainer = (props) => {
  return (
    <div className="row justify-content-start">
    <div className="col-4">
      <div className="pagetitle">{ props.pageTitle ? <h1>{props.pageTitle}</h1> : '' }</div>
    </div>
    <div className="col-8">
      {props.children}
    </div>
  </div>

  );
}

export default PageTitleContainer;

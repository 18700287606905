import { Link } from "react-router-dom";
import {
  GetDashboardLink,
  GetSaleLink,
  GetMilestoneLink,
  GetTeamLink,
  GetUserLink,
  GetCustomScreenLink,
  GetSideBarLinks
} from "./OtherLinks";

const AdminLinks = (props) => {

  let sideBarModules = [];
  sideBarModules.push(<GetDashboardLink />);
  sideBarModules.push(<GetSaleLink />);
  sideBarModules.push(<GetMilestoneLink />);
  sideBarModules.push(<GetUserLink />);
  sideBarModules.push(<GetTeamLink />);
  sideBarModules.push(<GetCustomScreenLink />);
  const siderbarLinks = <GetSideBarLinks sideBarModules={sideBarModules} />

  return (
    <>
      {siderbarLinks}

    <li className="nav-item">
      <a className="nav-link collapsed" data-bs-target="#icons-nav" data-bs-toggle="collapse" href="/">
        <i className="bi bi-gem"></i><span>Infrequent</span><i className="bi bi-chevron-down ms-auto"></i>
      </a>
      <ul id="icons-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
        <li className="nav-item">
          <Link to="/milestone-type" className="nav-link " >
            <i className="bi bi-circle" />
            <span>MilestoneTypes</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/role" className="nav-link " >
            <i className="bi bi-circle" />
            <span>Roles</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/team-group" className="nav-link " >
            <i className="bi bi-circle" />
            <span>TeamGroups</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/module" className="nav-link " >
            <i className="bi bi-circle" />
            <span>Modules</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/industries" className="nav-link " >
            <i className="bi bi-circle" />
            <span>Industries</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/webhooks" className="nav-link " >
            <i className="bi bi-circle" />
            <span>Webhooks</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/log" className="nav-link " >
            <i className="bi bi-circle" />
            <span>Logs</span>
          </Link>
        </li>
      </ul>
    </li>
    </>
  );
}


export default AdminLinks;

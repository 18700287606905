import * as React from 'react';
import { useSelector } from 'react-redux';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default function BasicPopover(props) {
  const salesscreen = useSelector((state) => state.screen);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <span className="btn btn-sm btn-link" aria-describedby={id} variant="contained" onClick={handleClick} >Settings</span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }} >
        <Box sx={{ p: 2, border: '1px solid grey' }}>

          <FormGroup>
            <FormControlLabel
              control={<Switch size="small" name="screenMuteAll" checked={salesscreen.screenMuteAll}
              onChange={props.handelScreenSetting} />}
              label="Mute All"
              labelPlacement="end"
            />
          </FormGroup>
          <br/>


          <FormGroup>
            <FormControlLabel
              control={<Switch size="small" name="screenAutoSlidePages" checked={salesscreen.screenAutoSlidePages}
              onChange={props.handelScreenSetting} />}
              label="AutoSlide"
              labelPlacement="end"
            />
          </FormGroup>
          <br/>

          <FormGroup>
            <FormControlLabel
              control={<Switch size="small" name="screenAutoPlay" checked={salesscreen.screenAutoPlay}
              onChange={props.handelScreenSetting} />}
              label="Autoplay"
              labelPlacement="end"
            />
          </FormGroup>

        </Box>
      </Popover>
    </>
  );
}

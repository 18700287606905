import { Link } from "react-router-dom";

export const GetDashboardLink = () => {
  return  <Link to="/" className="nav-link " >
    <i className="bi bi-grid" />
    <span>Dashboard</span>
  </Link>
}

export const GetYoutubeLibraryLink = () => {
  return  <Link to="/my-youtube-library" className="nav-link " >
    <i className="bi bi-youtube" />
    <span>My Youtube Library</span>
  </Link>
}

export const GetSaleLink = () => {
  return  <Link to="/sale" className="nav-link " >
    <i className="bi bi-currency-dollar" />
    <span>Sales</span>
  </Link>
}

export const GetMilestoneLink = () => {
  return  <Link to="/milestone" className="nav-link " >
    <i className="bi bi-ladder" />
    <span>Milestones</span>
  </Link>
}

export const GetUserLink = () => {
  return <Link to="/user" className="nav-link " >
    <i className="bi bi-person" />
    <span>Users</span>
  </Link>
}

export const GetTeamLink = () => {
  return <Link to="/team" className="nav-link " >
    <i className="bi bi-people" />
    <span>Teams</span>
  </Link>
}

export const GetCustomScreenLink = () => {
  return <Link to="/custom-screen" className="nav-link " >
    <i className="bi bi-journal-text" />
    <span>Screen Pages</span>
  </Link>
}

/****** dynamic links *******/
export const GetSideBarLinks = (props) => {
  if(props.sideBarModules.length === 0) {
    return <></>
  }
  const links = props.sideBarModules.map((link, index) => <li key={index} className="nav-item"> {link}</li>);
  return <>{links}</>
}

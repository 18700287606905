import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackBarAlert } from '../../redux';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import getAppConfig from '../../config';
const config = getAppConfig();

const TextFieldSetting = (props) => {

  const metaKey = props.item.metaKey;
  const display = props.item.display;
  const [metaValue, setMetaValue] = useState(props.item.metaValue);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    //const name = target.name;

    setMetaValue(value);

  }

  const updateSetting = () => {

    if(edit) {

      if(metaValue === '') {
        dispatch(setSnackBarAlert({
          openSnackBar: true,
          severity: 'error',
          message: `${metaKey} empty not allowed`
        }));
        return false;
      }

      const URL = `${config.api.URL}/app-setting/${props.item.id}`;
      axios.patch(URL,{
        metaValue: metaValue
      }).then(response => {
        const result = response.data.data;
        dispatch(setSnackBarAlert({
          openSnackBar: true,
          severity: 'success',
          message: `${result.metaKey} updated`
        }));

        toggleEdit()
      }).catch(error => {
         dispatch(setSnackBarAlert({
          openSnackBar: true,
          severity: 'error',
          message: `Unable to update ${metaKey}`
        }));
      });
    }
  }

  const toggleEdit = () => {
    setEdit(!edit);
  }

  if(display) {
    return (
      <div className="row">
        <div className="col-lg-4 col-md-4 label">
          {metaKey} <EditIcon fontSize="small" color="primary" onClick={toggleEdit} />
        </div>
        <div className="col-lg-8 col-md-8">
          {
            edit ? <TextField
              value={metaValue}
              onChange={handleInputChange}
              name="metaValue"
              label={`${metaKey}`}
              margin="dense"
              type="text"
              fullWidth
              variant="outlined"
              required
              onKeyPress={ (e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    updateSetting();
                  }
                }}
            /> : metaValue
          }
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default TextFieldSetting;

export const getQueryString = (params) => {
  const queryStr = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  return queryStr;
}

const createMarkup = (data) => {
  return {__html: data };
}

export const  HtmlScreen = (props) => {
  return  <div dangerouslySetInnerHTML={createMarkup(props.data)} />
}

const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export const ConvertUrlToLink = (props) => {
  const counHttp = (props.text.match(new RegExp("http://", "g")) || []).length;
  const countHttps = (props.text.match(new RegExp("https://", "g")) || []).length;

  //console.log(`${props.text}--->${count}`);
  if(counHttp === 0 && countHttps === 0) {
    return  <>{props.text}</>
  } else {
    const textArray = props.text.split(" ");
    const textArrayLength = textArray.length;
    let _text = "";
    for(let i=0; i<textArrayLength; i++) {
      if(textArray[i].length < 6) {
        _text = <>{_text} {textArray[i]}</>
      } else {
        if(isValidHttpUrl(textArray[i])) {
          _text = <>{_text} <a href={textArray[i]} target="_blank" rel="noreferrer">{textArray[i]}</a></>
        } else {
          _text = <>{_text} {textArray[i]}</>
        }
      }

    }
    return  <>{_text}</>
  }

}

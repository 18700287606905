import React, { useState, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { setSnackBarAlert, findAllMilestoneTypes, caughtError } from '../../redux';
import getAppConfig from '../../config';
const config = getAppConfig();

const initialState = {
   name: ''
}

const reducer = (state, action) => {
  return {
  ...state,
  ...action
  }
}

const CreateForm = (props) => {

  const [state, updateState] = useReducer(reducer, initialState);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    updateState({ [event.target.name]: event.target.value });
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if(state.name === '') {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `name is required`
      }));
      return false;
    }

    setBtnDisabled(true);
    axios.post(`${config.api.URL}/milestone-type`, state).then(response => {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'success',
        message: `Success! ${state.name} added`
      }));
      dispatch(findAllMilestoneTypes(props.filterInput));
      props.setOpen(false);
    }).catch(error => {
      setBtnDisabled(false);
      dispatch(caughtError(error));
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent sx={{ marginTop: "-20px" }} >

        <TextField
          autoFocus
          value={state.name}
          onChange={handleInputChange}
          name="name"
          label="MilestoneType Name"
          margin="dense"
          type="text"
          fullWidth
          variant="outlined"
          required
        />

      </DialogContent>
      <DialogActions>
        <button className="btn btn-secondary" onClick={ (e) => {
            e.preventDefault();
            props.setOpen(false);
          } }>Cancel</button>
        <button disabled={btnDisabled} type="submit" className="btn btn-primary" >Add New MilestoneType</button>
      </DialogActions>
    </form>
  )

}


export default CreateForm;

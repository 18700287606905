import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { setSnackBarAlert, caughtError, updateSessionTeamLogo } from '../../../redux';
import axios from 'axios';
import getAppConfig from '../../../config';
const config = getAppConfig();

const LogoForm = (props) => {

  const session = useSelector((state) => state.session);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showNewLogo, setShowNewLogo] = useState(true);
  const dispatch = useDispatch();

  const onImageChange = event => {
    if (event.target.files && event.target.files[0]) {
     let file = event.target.files[0];
     setImage(URL.createObjectURL(file));

     let formData = new FormData();
     formData.append("file", file);
     setLoading(true);
     setShowNewLogo(false);
     axios.put(`${config.api.URL}/team/logo/${session.teamId}`, formData).then(apiResponse => {
       const response = apiResponse.data;
       dispatch(updateSessionTeamLogo({
         logoFileName: response.data.logoFileName,
         logoUrl: response.data.logoUrl,
         logoResizedUrl: response.data.logoResizedUrl,
       }));

       setLoading(false);
       dispatch(setSnackBarAlert({
         openSnackBar: true,
         severity: 'success',
         message: `Success! ${response.message}`
       }));
       setTimeout(() => {
           setShowNewLogo(true);
           setImage(null);
       }, 10000);
     }).catch(error => {
       console.log(error);
       setLoading(false);
       dispatch(caughtError(error));
       setTimeout(() => {
         setShowNewLogo(true);
         setImage(null);
       }, 10000);
     });
   }

  }

  return (
    <form>

      { session.logoFileName && showNewLogo &&
        <img className="float-left" src={session.logoUrl} style={{ maxHeight: '100px' }} alt="No Logo Exist" />
      }

      <div className="float-end">
        <p><i>To change logo, just select file</i></p>
        <input type="file" onChange={onImageChange} accept="image/png, image/jpeg" /><br/>
        {loading && <CircularProgress className="mt-4" />}
        { image && <img src={image} alt="team logo" height="200" /> }
      </div>

    </form>
  )

}


export default LogoForm;

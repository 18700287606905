import React, { useState, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { setSnackBarAlert, caughtError, findAllTeams } from '../../redux';
import getAppConfig from '../../config';
const config = getAppConfig();

const initialState = {
   teamName: '',
   colorCode: '#FF8C00',
   groupId: 1,
}

const reducer = (state, action) => {
  return {
  ...state,
  ...action
  }
}

const CreateForm = (props) => {

  const [state, updateState] = useReducer(reducer, initialState);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    updateState({ [event.target.name]: event.target.value });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setBtnDisabled(true);

    const input = {
      teamName: state.teamName,
      colorCode: state.colorCode ? state.colorCode : '#FF8C00',
      groupId: state.groupId
    }

    axios.post(`${config.api.URL}/team`, input).then(response => {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'success',
        message: `Success! ${state.teamName} team created`
      }));
      dispatch(findAllTeams(props.filterInput));
      props.setOpen(false);
    }).catch(error => {
      setBtnDisabled(false);
      dispatch(caughtError(error));
    })

  }

  const teamGroupData = props.teamGroup.data;
  let teamGroupSelect = <MenuItem value={''}>NONE</MenuItem>
  if(teamGroupData.length > 0) {
    teamGroupSelect = teamGroupData.map( (row) => <MenuItem key={row.id} value={row.id}>{row.groupName}</MenuItem>)
  }

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent sx={{ marginTop: "-20px" }} >


          <div className="row">
            <div className="col-12">
              <TextField
                autoFocus
                value={state.teamName}
                onChange={handleInputChange}
                name="teamName"
                label="Team Name"
                margin="dense"
                type="text"
                fullWidth
                variant="outlined"
                required
              />
            </div>

            <div className="col-12">
              <FormControl fullWidth sx={{ marginTop: "8px" }}>
                <InputLabel>Group</InputLabel>
                <Select
                  name="groupId"
                  label="Group"
                  variant="outlined"
                  margin="dense"
                  value={state.groupId}
                  onChange={handleInputChange}
                  required
                >
                {teamGroupSelect}
                </Select>
              </FormControl>
            </div>

            <div className="col-12">
              <TextField
                autoFocus
                value={state.colorCode}
                onChange={handleInputChange}
                name="colorCode"
                label="colorCode"
                margin="dense"
                type="text"
                fullWidth
                variant="outlined"
                required
              />
            </div>

          </div>


      </DialogContent>
      <DialogActions>
        <button className="btn btn-secondary" onClick={ (e) => {
            e.preventDefault();
            props.setOpen(false);
          } }>Cancel</button>
        <button disabled={btnDisabled} type="submit" className="btn btn-primary" >Add New Team</button>
      </DialogActions>
    </form>
  )
}

export default CreateForm;

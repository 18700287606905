import axios from 'axios';
import {
  SCREEN_SET_CURRENT_MONTH,
  SCREEN_SET_INITIAL_STATE,
  SCREEN_SET_TEAM_LIST,
  SCREEN_ADD_ALERT_ITEM,
  SCREEN_CLEAR_ALERT_ITEMS,
  SCREEN_ALERT_PLAYING_COUNTER,
  SCREEN_SET_VIEW_ALERT,
  SCREEN_SET_AUTO_PLAY,
  SCREEN_SET_MUTE_ALL,
  SCREEN_SET_AUTO_SLIDE_PAGES,
  SCREEN_SET_VPN_STATUS
} from './screenTypes';

import getAppConfig from '../../config';
const config = getAppConfig();

export const callScreenPageServices = () => {
  return (dispatch) => {
    dispatch(setRealTimeData(`${config.api.URL}/public/list-screen-common`,'SCREEN_SET_COMMON'));

    setTimeout(()=>{
      dispatch(setRealTimeData(`${config.api.URL}/public/list-today-sales`,'SCREEN_SET_TODAY_SALES'));
      dispatch(setRealTimeData(`${config.api.URL}/public/list-today-sales?sort=sales.amount&direction=DESC&pagesize=10`,'SCREEN_SET_TODAY_TOP_SALES'));
    },500);

    setTimeout(()=>{
      dispatch(setRealTimeData(`${config.api.URL}/public/list-milestones`,'SCREEN_SET_MONTH_MILESTONES'));
      dispatch(setRealTimeData(`${config.api.URL}/public/list-revenue`,'SCREEN_SET_MONTH_REVENUES'));
    },1500);

  }
}

export const callDashboardPageServices = () => {
  return (dispatch) => {
    dispatch(setRealTimeData(`${config.api.URL}/public/list-screen-common`,'SCREEN_SET_COMMON'));
    dispatch(setRealTimeData(`${config.api.URL}/graph/sales-group-by-days`,'SCREEN_SALES_DAILY_AMOUNT'));
    dispatch(setRealTimeData(`${config.api.URL}/graph/individual-top-collector`,'SCREEN_TOP_INDIVISUAL_COLLECTORS'));
    dispatch(setRealTimeData(`${config.api.URL}/graph/sales-group-by-industries`,'SCREEN_SALES_INDUSTRIES'));

    setTimeout(()=>{
      dispatch(setRealTimeData(`${config.api.URL}/graph/sales-group-by-month?selfTeam=1`,'SCREEN_TEAM_SALES_MONTHLY_AMOUNT'));
      dispatch(setRealTimeData(`${config.api.URL}/graph/sales-group-by-month`,'SCREEN_SALES_MONTHLY_AMOUNT'));
      dispatch(setRealTimeData(`${config.api.URL}/graph/team-top-collector`,'SCREEN_TEAM_TOP_COLLECTORS'));
    },1500);
  }
}

const setRealTimeData = (apiUrl, actionName) => {
  return (dispatch) => {
    axios.get(apiUrl).then(apiResponse => {
      const response = apiResponse.data;
      dispatch(_setRealTimeData(actionName,response.data));
      if(actionName === 'SCREEN_SET_MONTH_MILESTONES') {
        if(response.monthText !== undefined) {
          dispatch(setScreenCurrentMonth(response.monthText.toUpperCase()));
        }
      }
    }).catch(error => {
      console.log("error-->",error);
    })
  }
}

const _setRealTimeData = (actionName, response) => {
  return {
    type: actionName,
    payload: response
  }
}

const setScreenCurrentMonth = response => {
  return {
    type: SCREEN_SET_CURRENT_MONTH,
    payload: response
  }
}

export const setScreenInitialState = () => {
  return {
    type: SCREEN_SET_INITIAL_STATE
  }
}

export const setScreenTeamList = response => {
  return {
    type: SCREEN_SET_TEAM_LIST,
    payload: response
  }
}


export const addScreenAlertItem = (response) => {
  return {
    type: SCREEN_ADD_ALERT_ITEM,
    payload: response
  }
}

export const clearScreenAlertItem = () => {
  return {
    type: SCREEN_CLEAR_ALERT_ITEMS,
  }
}

export const setScreenAlertPlayingCounter = (response) => {
  return {
    type: SCREEN_ALERT_PLAYING_COUNTER,
    payload: response
  }
}

export const setScreenViewAlert = (response) => {
  return {
    type: SCREEN_SET_VIEW_ALERT,
    payload: response
  }
}

export const setScreenAutoPlay = (response) => {
  return {
    type: SCREEN_SET_AUTO_PLAY,
    payload: response
  }
}

export const setScreenMuteAll = (response) => {
  return {
    type: SCREEN_SET_MUTE_ALL,
    payload: response
  }
}

export const setScreenAutoSlidePages = (response) => {
  return {
    type: SCREEN_SET_AUTO_SLIDE_PAGES,
    payload: response
  }
}

export const setScreenVpnStatus = (response) => {
  return {
    type: SCREEN_SET_VPN_STATUS,
    payload: response
  }
}

let sortByFields = {}
/********** user **********/
sortByFields.user = [
  {
    value: 'user_profile.name',
    displayName: 'Name'
  },
  {
    value: 'users.email',
    displayName: 'Email'
  },
  {
    value: 'users.lastAccess',
    displayName: 'LastAccess'
  },
  {
    value: 'teams.teamName',
    displayName: 'Team Name'
  },
  {
    value: 'roles.roleName',
    displayName: 'User Role'
  },
  {
    value: 'users.createdAt',
    displayName: 'CreatedAt'
  }
];

/********** module **********/
sortByFields.module = [
  {
    value: 'moduleName',
    displayName: 'Module Name'
  },
  {
    value: 'createdAt',
    displayName: 'CreatedAt'
  }
];


/********** module **********/
sortByFields.industry = [
  {
    value: 'industryName',
    displayName: 'Industry Name'
  },
  {
    value: 'createdAt',
    displayName: 'CreatedAt'
  },
  {
    value: 'updatedAt',
    displayName: 'UpdatedAt'
  }
];

/********** custom-screen **********/
sortByFields.customScreen = [
  {
    value: 'custom_screens.title',
    displayName: 'Title'
  },
  {
    value: 'user_profile.name',
    displayName: 'AddedBy'
  },
  {
    value: 'custom_screens.createdAt',
    displayName: 'CreatedAt'
  }
];

/********** teamGroup **********/
sortByFields.teamGroup = [
  {
    value: 'groupName',
    displayName: 'TeamGroup Name'
  },
  {
    value: 'createdAt',
    displayName: 'CreatedAt'
  }
];


/********** milestoneType **********/
sortByFields.milestoneType = [
  {
    value: 'name',
    displayName: 'milestoneType'
  },
  {
    value: 'createdAt',
    displayName: 'CreatedAt'
  }
];

/********** log **********/
sortByFields.log = [
  {
    value: 'logs.createdAt',
    displayName: 'CreatedAt'
  },
  {
    value: 'logs.requestIp',
    displayName: 'Request Ip'
  }
];

/********** role **********/
sortByFields.role = [
  {
    value: 'roleName',
    displayName: 'Role Name'
  },
  {
    value: 'createdAt',
    displayName: 'CreatedAt'
  }
];

/********** team **********/
sortByFields.team = [
  {
    value: 'teams.teamName',
    displayName: 'Team Name'
  },
  {
    value: 'totalMembers',
    displayName: 'Total Members'
  },
  {
    value: 'team_groups.id',
    displayName: 'TeamGroup'
  },
  {
    value: 'teams.createdAt',
    displayName: 'CreatedAt'
  }
];

/********** sale **********/
sortByFields.sale = [
  {
    value: 'sales.amount',
    displayName: 'Amount'
  },
  {
    value: 'user_profile.name',
    displayName: 'PostedBy'
  },
  {
    value: 'sales.createdAt',
    displayName: 'CreatedAt'
  }
];

/********** milestone **********/
sortByFields.milestone = [
  {
    value: 'milestones.createdAt',
    displayName: 'CreatedAt'
  },
  {
    value: 'user_profile.name',
    displayName: 'PostedBy'
  },
  {
    value: 'teams.teamName',
    displayName: 'Team'
  },
];

/********** youtubeLibrary **********/
sortByFields.youtubeLibrary = [
  {
    value: 'youtube_library.createdAt',
    displayName: 'CreatedAt'
  }
];

/********** webhook **********/
sortByFields.webhook = [
  {
    value: 'createdAt',
    displayName: 'CreatedAt'
  }
];

export default sortByFields;

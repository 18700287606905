import {
  SESSION_LOGIN_SUCCESS,
  SESSION_SET_INITIAL_STATE,
  SESSION_UPDATE_PROFILE_NAME,
  SESSION_UPDATE_THEME_NAME,
  SESSION_UPDATE_TEAM_LOGO
} from './sessionTypes'

const initialState = {
  isAuthenticated: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_LOGIN_SUCCESS:
      return action.payload
    case SESSION_SET_INITIAL_STATE:
      return initialState;
    case SESSION_UPDATE_PROFILE_NAME:
    return {
      ...state,
      name: action.payload
    }
    case SESSION_UPDATE_THEME_NAME:
    return {
      ...state,
      theme: action.payload
    }
    case SESSION_UPDATE_TEAM_LOGO:
    return {
      ...state,
      logoFileName: action.payload.logoFileName,
      logoUrl: action.payload.logoUrl,
      logoResizedUrl: action.payload.logoResizedUrl,
    }
    default: return state
  }
}

export default reducer

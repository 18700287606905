import React, { useEffect, useState, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Switch from '@mui/material/Switch';
import { Box } from '@mui/system';
import { setSnackBarAlert, findAllWebhooks, caughtError } from '../../redux';
import getAppConfig from '../../config';
const config = getAppConfig();

const initialState = {
   description: '',
   active: false,
   alertType: 'sales',
   isPublic: false,
}

const reducer = (state, action) => {
  return {
  ...state,
  ...action
  }
}

const EditForm = (props) => {

  const [state, updateState] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    if(event.target.type === 'checkbox'){
      updateState({ [event.target.name]: event.target.checked });
    } else {
      updateState({ [event.target.name]: event.target.value });
    }
  }

  useEffect(() => {
    if(loading && props.modalDiaLogId && props.modalDiaLogId > 0) {
      let URL = `${config.api.URL}/webhook/${props.modalDiaLogId}`;
      axios.get(URL).then(response => {
        updateState({
          description: response.data.data.description,
          alertType: response.data.data.alertType,
          isPublic: Boolean(response.data.data.isPublic),
          active: Boolean(response.data.data.active)
        });
        setBtnDisabled(false);
        setLoading(false);
      }).catch(error => {
        console.log(error);
        setLoading(false);
      })
    }
  }, [loading, props]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if(state.description === '') {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `description is required`
      }));
      return false;
    }

    setBtnDisabled(true);
    axios.patch(`${config.api.URL}/webhook/${props.modalDiaLogId}`, state).then(response => {
      dispatch(findAllWebhooks(props.filterInput));
      props.setOpen(false);
    }).catch(error => {
      setBtnDisabled(false);
      dispatch(caughtError(error));
    });
  }

  let _content = null
  if(loading === false) {
    _content = <form onSubmit={handleSubmit}>
      <DialogContent sx={{ marginTop: "-20px" }} >

        <TextField
          value={state.description}
          onChange={handleInputChange}
          name="description"
          label="Description"
          margin="dense"
          type="text"
          fullWidth
          variant="outlined"
          required
        />

        <FormControl fullWidth sx={{ marginTop: "9px" }}>
          <InputLabel>AlertType</InputLabel>
        <Select
          fullWidth
          label="alertType"
          name="alertType"
          variant="outlined"
          value={state.alertType}
          onChange={handleInputChange}
        >
          <MenuItem key={'sales'} value={'sales'}>Sales</MenuItem>
          <MenuItem key={'milestones'} value={'milestones'}>Milestones</MenuItem>
        </Select>
        </FormControl>

        <Box>
          <FormControlLabel
            className="float-end"
            control={<Switch
              name="active"
              checked={state.active}
              onChange={handleInputChange}
              />}
            label="Active"
            labelPlacement="start" />
        </Box>

        <Box>
          <FormControlLabel
            className="float-end"
            control={<Switch
              name="isPublic"
              checked={state.isPublic}
              onChange={handleInputChange}
              />}
            label="PublicLink"
            labelPlacement="start" />
        </Box>


      </DialogContent>
      <DialogActions>
        <button className="btn btn-secondary" onClick={ (e) => {
            e.preventDefault();
            props.setOpen(false);
          } }>Cancel</button>
        <button disabled={btnDisabled} type="submit" className="btn btn-primary" >Update Webhook</button>
      </DialogActions>
    </form>
  }

  return _content

}

export default EditForm;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box } from '@mui/system';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { setSnackBarAlert, caughtError } from '../../redux';
import getAppConfig from '../../config';
const config = getAppConfig();

const SubscriptionForm = (props) => {

  const session = useSelector((state) => state.session);
  const [loading, setLoading] = useState(true);
  const [availableTeams, setAvailableTeams] = useState([]);
  const [selectAllTeams, setSelectAllTeams] = useState(false);
  const [isRecordActive, setIsRecordActive] = useState(true);
  const dispatch = useDispatch();

  const handleSubscriptionSelection = (event) => {
    const screenId = props.modalDiaLogId;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const selectedTeamId = parseInt(target.name);

    if(value === false) {
      setSelectAllTeams(false);
    }

    if(target.name === 'selectAllTeams') {
      setSelectAllTeams(value);
      updateSubscriptionForSelectAll({
        screenId: screenId,
        subscribed: value
      });
      const newState = availableTeams.map((_row) => {
        if(value) {
          return {
            ..._row,
            checked: true
          };
        } else {
          return {
            ..._row,
            checked: false
          };
        }
      })
      setAvailableTeams(newState)
    } else {
      const newState = availableTeams.map((_row) => {
        if(_row.id === selectedTeamId) {
          let newRow = _row;
          newRow.checked = value
          return newRow;
        } else {
          return _row;
        }
      })
      setAvailableTeams(newState);

      //  update selected team subscription
      updateSubscription({
        screenId: screenId,
        teamId: selectedTeamId,
        subscribed: value
      })

    }

  }

  const updateSubscriptionForSelectAll = (data) => {
    if(session.roleId === 3) {
      const {
        screenId, subscribed
      } = data;

      axios.patch(`${config.api.URL}/custom-screen/${screenId}/toggle-subscription-all-teams`, {
        subscribed: subscribed
      }).then(response => {
        const success = response.data.success;
        if(success) {
          dispatch(setSnackBarAlert({
            openSnackBar: true,
            severity: 'success',
            message: `Success!`
          }))
        }
      }).catch(error => {
        dispatch(caughtError(error));
      })
    } else {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'warning',
        message: `Error! Access Forbidden`
      }));
    }
  }

  const updateSubscription = (data) => {
    const {
      screenId, teamId, subscribed
    } = data;

    axios.patch(`${config.api.URL}/custom-screen/${screenId}/${teamId}/subscription`, {
      subscribed: subscribed
    }).then(response => {
      const success = response.data.success;
      if(success) {
        dispatch(setSnackBarAlert({
          openSnackBar: true,
          severity: 'success',
          message: `Success!`
        }));
      }
    }).catch(error => {
      dispatch(caughtError(error));
    })
  }


  useEffect(() => {

    if(loading) {
      /** subscribed teams **/
      if(props.modalDiaLogId && props.modalDiaLogId > 0) {
        let URL = `${config.api.URL}/custom-screen/${props.modalDiaLogId}/subscribed-teams`;
        axios.get(URL).then(response => {

          const subscribedTeamList = response.data.data;
          const subscribedTeamIds = subscribedTeamList.map((_row) => _row.id);

          const team = props.team.data;
          let subscribedCount = 0;
          const availableTeams = team.map((_row) => {
            let newRow = _row;
            newRow.checked = false;
            if(subscribedTeamIds.includes(_row.id)) {
              subscribedCount++;
              newRow.checked = true;
            }
            return newRow;
          })

          if(subscribedCount === availableTeams.length) {
            setSelectAllTeams(true)
          }

          setAvailableTeams(availableTeams)


        }).catch(error => {
          console.log(error);
        })
      }

      /** get record details **/
      if(props.modalDiaLogId && props.modalDiaLogId > 0) {
        let URL = `${config.api.URL}/custom-screen/${props.modalDiaLogId}`;
        axios.get(URL).then(response => {
          setIsRecordActive(Boolean(response.data.data.active));
        }).catch(error => {
          console.log(error);
        })
      }
    }
    setLoading(false);
  }, [loading, props]);

  let _content = null;
  if(availableTeams.length > 0) {
    const teamSubscriptionSelection = availableTeams.map((_row) => {
      return <div className="col-3" key={_row.id}>
                <FormControlLabel
                  sx={{ margin: "auto", padding:'10px' }}
                  className="float-start"
                  control={<Switch name={`${_row.id}`}
                  onChange={handleSubscriptionSelection}
                  checked={_row.checked} />}
                  label={_row.teamName}
                  labelPlacement="end" />
              </div>
    })
    _content = teamSubscriptionSelection;
  }

  return (
    <form>
      <DialogContent sx={{ marginTop: "-20px" }} >

      <Box sx={{ p: 2, border: '1px dashed black' }} >
        <div className="row">
          { !isRecordActive && <Alert severity="error" >THIS PAGE IS NOT ACTIVE</Alert> }
          {_content}
        </div>
      </Box>

      </DialogContent>
      <DialogActions>
        {
          session.roleId === 3 &&
          <FormControlLabel
            sx={{ marginRight:'50px' }}
            control={<Switch color="secondary" name='selectAllTeams'
            onChange={handleSubscriptionSelection}
            checked={selectAllTeams} />}
            label="Select All Teams"
            labelPlacement="end" />
        }
        <button className="btn btn-secondary" onClick={ (e) => {
            e.preventDefault();
            props.setOpen(false);
          } }>Close</button>
      </DialogActions>
    </form>
  )

}

export default SubscriptionForm;

import axios from 'axios';
import getAppConfig from '../../config';
import { setSnackBarAlert, setGlobalLoader, caughtError } from '../ui/uiActions';
import { getQueryString } from '../../components/common/functions';

import {
  TEAM_SET_INITIAL_STATE,
  TEAM_FIND_ALL,
  TEAM_VIEW
} from './teamTypes';

const config = getAppConfig();

export const viewTeam = (teamId) => {
  let URL = `${config.api.URL}/team/${teamId}`;
  return (dispatch) => {
    dispatch(onSuccessViewTeam({data: false}))
    axios.get(URL).then(response => {
      dispatch(onSuccessViewTeam(response))
    }).catch(error => {
      dispatch(caughtError(error))
    })
  }
}

export const deleteTeam = (teamId, filterInput) => {
  let URL = `${config.api.URL}/team/mark-delete/${teamId}`;
  if(filterInput.deleted === true) {
    // if record already deleted
    URL = `${config.api.URL}/team/unmark-delete/${teamId}`;
  }
  return (dispatch) => {
    axios.patch(URL).then(response => {

      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'success',
        message: `Success! team ${filterInput.deleted ? 'restored' : ' moved to trash'}`
      }))

      dispatch(findAllTeams(filterInput));

    }).catch(error => {
      dispatch(caughtError(error))
    })
  }
}

export const findAllTeams  = (filters="") => {
  let URL = `${config.api.URL}/team`;
  if(filters !== '') {
    URL = `${URL}?${getQueryString(filters)}`;
  }

  return (dispatch) => {
    dispatch(setGlobalLoader(true))
    axios.get(URL).then(response => {
      dispatch(setGlobalLoader(false))
      dispatch(onSuccessFindAllTeam(response))
    }).catch(error => {
      dispatch(setGlobalLoader(false))
      dispatch(setTeamInitialState())
      dispatch(caughtError(error))
    })
  }
}

export const setTeamInitialState = () => {
  return {
    type: TEAM_SET_INITIAL_STATE
  }
}

export const onSuccessViewTeam = response => {
  return {
    type: TEAM_VIEW,
    payload: response.data
  }
}

export const onSuccessFindAllTeam = response => {
  return {
    type: TEAM_FIND_ALL,
    payload: response.data
  }
}

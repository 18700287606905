export const SCREEN_SET_INITIAL_STATE = 'SCREEN_SET_INITIAL_STATE';
export const SCREEN_SET_TODAY_SALES = 'SCREEN_SET_TODAY_SALES';
export const SCREEN_SET_TODAY_TOP_SALES = 'SCREEN_SET_TODAY_TOP_SALES';
export const SCREEN_SET_MONTH_MILESTONES = 'SCREEN_SET_MONTH_MILESTONES';
export const SCREEN_SET_COMMON = 'SCREEN_SET_COMMON';
export const SCREEN_SET_MONTH_REVENUES = 'SCREEN_SET_MONTH_REVENUES';
export const SCREEN_SET_TEAM_LIST = 'SCREEN_SET_TEAM_LIST';
export const SCREEN_SET_CURRENT_MONTH = 'SCREEN_SET_CURRENT_MONTH';
export const SCREEN_ADD_ALERT_ITEM = 'SCREEN_ADD_ALERT_ITEM';
export const SCREEN_CLEAR_ALERT_ITEMS = 'SCREEN_CLEAR_ALERT_ITEMS';
export const SCREEN_ALERT_PLAYING_COUNTER = 'SCREEN_ALERT_PLAYING_COUNTER';
export const SCREEN_SET_VIEW_ALERT = 'SCREEN_SET_VIEW_ALERT';
export const SCREEN_SET_AUTO_PLAY = 'SCREEN_SET_AUTO_PLAY';
export const SCREEN_SET_MUTE_ALL = 'SCREEN_SET_MUTE_ALL';
export const SCREEN_SET_AUTO_SLIDE_PAGES = 'SCREEN_SET_AUTO_SLIDE_PAGES';
export const SCREEN_TEAM_TOP_COLLECTORS = 'SCREEN_TEAM_TOP_COLLECTORS';
export const SCREEN_SALES_MONTHLY_AMOUNT = 'SCREEN_SALES_MONTHLY_AMOUNT';
export const SCREEN_SALES_DAILY_AMOUNT = 'SCREEN_SALES_DAILY_AMOUNT';
export const SCREEN_TOP_INDIVISUAL_COLLECTORS = 'SCREEN_TOP_INDIVISUAL_COLLECTORS';
export const SCREEN_SET_VPN_STATUS = 'SCREEN_SET_VPN_STATUS';
export const SCREEN_TEAM_SALES_MONTHLY_AMOUNT = 'SCREEN_TEAM_SALES_MONTHLY_AMOUNT';
export const SCREEN_SALES_INDUSTRIES = 'SCREEN_SALES_INDUSTRIES';

import React, { useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PageTitleContainer from '../common/PageTitleContainer';
import HeaderSection from './HeaderSection';
import TablePagination from '@mui/material/TablePagination';
import { findAllMilestoneTypes, deleteMilestoneType } from '../../redux';
import CustomDialog from '../ui/CustomDialog';
import DeleteConfirmDialog from '../ui/DeleteConfirmDialog';
import ViewDetails from './ViewDetails';
import EditForm from './EditForm';
import { GetTcolumns, GetTsearch, GetTvalues } from './TableData';
import SortTable from '../common/SortTable';
import ToggleTableTrash from '../common/ToggleTableTrash';

const initialStateFilter = {
  deleted: false,
  page: 0,
  pagesize: 10,
  sort: 'name',
  direction: 'ASC',
  name: '',
  active: ''
}

const initialStateOther = {
  pageLoaded: false,
  modalDiaLogTitle: '',
  modalDiaLogId: false,
  openDelete: false,
  openView: false,
  openEdit: false,
}

const reducerFilter = (state, action) => {
  return {
  ...state,
  ...action
  }
}

const MilestoneTypeContainer = (props) => {

  const [state, updateState] = useReducer(reducerFilter, initialStateFilter);
  const [state2, updateState2] = useReducer(reducerFilter, initialStateOther);
  const ui = useSelector((state) => state.ui);
  const milestoneType = useSelector((state) => state.milestoneType);
  const dispatch = useDispatch();

  /********** Handle VIEW/EDIT/DELETE **********/
  const setOpen = (open, modalDiaLogId=false, modalDiaLogTitle='', modalName=null) => {
    updateState2({ modalDiaLogId: modalDiaLogId });
    updateState2({ modalDiaLogTitle: modalDiaLogTitle });

    if(modalName !== null) {
      if(modalName === 'VIEW') {
        updateState2({ openView: open });
      } else if(modalName === 'EDIT') {
        updateState2({ openEdit: open });
      } else if(modalName === 'DELETE') {
        updateState2({ openDelete: open });
      }
    } else {
      updateState2({ openView: false });
      updateState2({ openEdit: false });
      updateState2({ openDelete: false });
    }

  }

  const _deleteMilestoneType = (modalDiaLogId) => {
    dispatch(deleteMilestoneType(modalDiaLogId, state));
  }

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    updateState({ [name]: value });

    /* on Selection */
    if(['sort','direction','active','deleted'].includes(name)) {
      updateState({ page: 0 });
      let newFilters = state;
      newFilters[name] = value;
      newFilters['page'] = 0;
      dispatch(findAllMilestoneTypes(newFilters));
    }

  }

  /********************** misc **********************/
  const handleTableChangePage = (event, newPage) => {
    dispatch(findAllMilestoneTypes({...state, page: newPage }));
    updateState({ page: newPage });
  }
  const handleTableChangeRowsPerPage = (event) => {
    dispatch(findAllMilestoneTypes({...state, page: 0, pagesize: parseInt(event.target.value, 10) }));
    updateState({ page: 0, pagesize: parseInt(event.target.value, 10) });
  }
  const onSearchInputEnterKey = () => {
    dispatch(findAllMilestoneTypes({...state, page: 0}));
    updateState({ page: 0 });
  }
  const resetTableSearchInput = () => {
    updateState2({ pageLoaded: false });
  }

  useEffect(() => {
    if(!state2.pageLoaded) {
      updateState(initialStateFilter);
      updateState2({ pageLoaded: true });
      dispatch(findAllMilestoneTypes(initialStateFilter));
    }
  }, [state2, dispatch]);


  /*** table columns ***/
  const t_data = milestoneType.data;
  const t_pagination = milestoneType.pagination;

  /*** table fields ***/
  const t_columns = <GetTcolumns openGlobalLoader={ui.openGlobalLoader}/>

  /*** table search ***/
  const t_search = <GetTsearch
    onSearchInputEnterKey={onSearchInputEnterKey}
    handleInputChange={handleInputChange}
    resetTableSearchInput={resetTableSearchInput}
    name={state.name}
    active={state.active}
    />

  /*** table data ***/
  const t_values = <GetTvalues t_data={t_data}
    setOpen={setOpen}
    deleted={state.deleted}
    t_index={state.page * state.pagesize}
    />

  return (
    <main id="main" className="main">
      <PageTitleContainer pageTitle={props.pageTitle} >
        <HeaderSection
          filterInput={state}
          />
      </PageTitleContainer>
      <section className="section">
        <div className="row">

          <CustomDialog title={`View MilestoneType ${state2.modalDiaLogTitle ? '- '+state2.modalDiaLogTitle : ''}`} open={state2.openView} setOpen={setOpen} >
            <ViewDetails
              modalDiaLogId={state2.modalDiaLogId}
              setOpen={setOpen}
            />
          </CustomDialog>

          <CustomDialog title={`Update MilestoneType ${state2.modalDiaLogTitle ? '- '+state2.modalDiaLogTitle : ''}`} open={state2.openEdit} setOpen={setOpen} >
            <EditForm
              modalDiaLogId={state2.modalDiaLogId}
              setOpen={setOpen}
              filterInput={state}
            />
          </CustomDialog>

          <DeleteConfirmDialog
              title={`${ state.deleted ? 'Restore': 'Delete' } MilestoneType ${state2.modalDiaLogTitle ? '- '+state2.modalDiaLogTitle : ''}`}
              modalDiaLogId={state2.modalDiaLogId}
              open={state2.openDelete}
              setOpen={setOpen}
              onConfirm={_deleteMilestoneType} >
            Are you sure you want to { state.deleted ? 'Restore': 'Delete' } milestone type <strong className="p-2"> {state2.modalDiaLogTitle} </strong> ?
          </DeleteConfirmDialog>

        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">

                <div className="row mb-1">
                  <div className="col col-sm-9">
                    <div className="float-start">
                      <SortTable sortName={'milestoneType'}
                        sort={state.sort}
                        direction={state.direction}
                        handleInputChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="float-end">
                      <ToggleTableTrash
                        deleted={state.deleted}
                        handleInputChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>{ t_columns }</thead>
                    <tbody>
                      { t_search }
                      { t_values }
                    </tbody>
                </table>
                </div>

                <TablePagination
                  component="div"
                  count={t_pagination.itemcount}
                  page={state.page}
                  onPageChange={handleTableChangePage}
                  rowsPerPage={state.pagesize}
                  onRowsPerPageChange={handleTableChangeRowsPerPage}
                  showFirstButton={true}
                  showLastButton={true} />
              </div>
            </div>
          </div>
        </div>

      </section>
    </main>
  )

}

export default MilestoneTypeContainer;

import React, { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { HtmlScreen } from '../../common/functions';

const IframeScreen = (props) => {

  const [loading, setLoading] = useState(true);

  return <>
    {
      loading ? (
        <>
        <CircularProgress />
        <p>Please wait, data is loading...</p>
        </>
      ) : null}
    <iframe onLoad={() => setLoading(false) } src={props.iframeUrl} title={props.title} width="100%" height="600px" />
  </>
}

const ExtraScreen = (props) => {

  const screenDetails = props.screenDetails;

  let _content = null;
  if(screenDetails.contentType === 'rawHtml') {
    _content = <HtmlScreen data={screenDetails.rawHtmlContent} />
  } else if(screenDetails.contentType === 'WYSIWIG') {
    _content = <HtmlScreen data={screenDetails.htmlContent} />
  } else if(screenDetails.contentType === 'iframe') {
    _content = <IframeScreen title={screenDetails.title} iframeUrl={screenDetails.iframeUrl} />
  }


  return (
    <>

      <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 float-start">
        <div className="card">
          <div className="card-body" style={{ minHeight: '700px' }}>
            <h5 className="card-title">{screenDetails.title}</h5>
            {_content}
          </div>
        </div>
      </div>

    </>
  );
}

export default ExtraScreen;

import React from 'react';
import { connect } from 'react-redux';
import YouTube from 'react-youtube';
import {
  setScreenAlertPlayingCounter
} from '../../../redux';

import getAppConfig from '../../../config';
const config = getAppConfig();
const musicExpireTime = 30;

const playerFixVars = {
  rel: 0,
  enablejsapi: 1,
  controls: 0,
  disablekb: 1,
  fs: 0,
  iv_load_policy: 3,
  modestbranding: 1,
  cc_load_policy: 1,
  origin: config.app.URL
};

class PlayerYoutube extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      playerStatus: -1,
      secondsCounter: 0,
      youtubeReadyToPlay: false
    }
  }

  updateCounter() {

    if(this.state.secondsCounter >= musicExpireTime) {
      this._onEnd();
    }

    this.props.setScreenAlertPlayingCounter(musicExpireTime-this.state.secondsCounter);

    this.setState((prevState) => {
      return {
        ...prevState,
        secondsCounter: prevState.secondsCounter + 1
      }
    })
  }

  componentDidMount() {
    this.timerId = setInterval(
      () => this.updateCounter(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  _onEnd = () => {
    //console.log(`In _onEnd...`);
    this.props.playNextItem();
  }

  _onReady = (event) => {
    //console.log("PlayerYoutube-->_onReady --->",event.target);
    //event.target.playVideo();
  }

  _onStateChange = (status) => {
    //console.log("status.data--->",status.data);
    if(status.data === 1) {
      this.setState({
        secondsCounter: 0,
        youtubeReadyToPlay: true
      });
    }
    this.setState({
      playerStatus: status.data
    })
  }

  _onError = (error) => {
    //console.log("PlayerYoutube-->error --->",error);
    setTimeout(
      () => this._onEnd(),
      5000
    );
  }


  render() {

    let autoplay = this.props.salesscreen.screenAutoPlay;
    const ytLibrary = this.props?.ytLibrary;
    const mute = this.props?.mute;

    if(ytLibrary) {
      const {
        nsfw, youtubeId, title, youtubeIdStart, youtubeIdEnd
      } = ytLibrary;

      const opts = {
        height: '100%',
        width: '100%',
        border: '2px solid black',
        borderRadius: '5px',
        playerVars: {
          ...playerFixVars,
          autoplay: autoplay ? 1 : 0,
          start: youtubeIdStart,
          end: youtubeIdEnd,
          mute: mute ? 1 : 0,
        }
      };

      return (
          <div className={nsfw ? 'd-none': ''}  style={{
            border: '2px solid black',
            borderRadius: '5px',
            height: 154,
            width: 324,
            }} >
            <YouTube
              videoId={youtubeId}
              opts={opts}
              onEnd={this._onEnd}
              onReady={ (event) => this._onReady(event) }
              onError={ (error) => this._onError(error) }
              title={title}
              onStateChange={ (status) => this._onStateChange(status) }
            />
        </div>

      );
    } else {
      return null;
    }

  }
}

const mapStateToProps = state => {
  return {
    salesscreen: state.screen
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setScreenAlertPlayingCounter: (input) => dispatch(setScreenAlertPlayingCounter(input))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerYoutube);

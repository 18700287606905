import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { viewWebhook } from '../../redux';
import getAppConfig from '../../config';
const config = getAppConfig();
const webhookUrl = `${config.api.HOST}/api/webhook/`;

const SuccessNewSaleResponse = {
    "success": true,
    "data": {
        "postType": "sale",
        "id": 136,
        "description": "Request from webhook",
        "amount": 0
    }
}

const SuccessNewMilestoneResponse = {
    "success": true,
    "data": {
        "postType": "milestone",
        "id": 551,
        "description": "test milestone description"
    }
}

/****** SALES ******/
function RequestBodySales() {
  return (<code>
    <ul>
      <li>description (String: Required)</li>
      <li>amount (Number: Required)</li>
      <li>
        youtubeCode (String: Optional) <br/>
        <small>
          e.g. <span style={{ textDecoration: "line-through" }}>
          https://www.youtube.com/watch?v=</span>
          <strong style={{ color: 'coral' }}>youtubeCode</strong>
        </small>
      </li>
      <li>nsfw (Boolean: true|false, default is false)</li>
    </ul>
  </code>);
}

function ResponseBodySales() {
  return (<code>
    <pre>
      {JSON.stringify(SuccessNewSaleResponse, null, 2)}
    </pre>
  </code>);
}

/****** MILESTONES ******/
function RequestBodyMilestones() {
  return (<code>
      <ul>
        <li>description (String: Required)</li>
        <li>
          youtubeCode (String: Optional) <br/>
          <small>
            e.g. <span style={{ textDecoration: "line-through" }}>
            https://www.youtube.com/watch?v=</span>
            <strong style={{ color: 'coral' }}>youtubeCode</strong>
          </small>
        </li>
        <li>nsfw (Boolean: true|false, default is false)</li>
        </ul>
    </code>);
}

function ResponseBodyMilestones() {
  return (<code>
    <pre>
      {JSON.stringify(SuccessNewMilestoneResponse, null, 2)}
    </pre>
  </code>);
}

const ViewDetails = (props) => {

  const viewResult = useSelector((state) => state.webhook.viewResult);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if(loading && props.modalDiaLogId && props.modalDiaLogId > 0) {
      dispatch(viewWebhook(props.modalDiaLogId));
      setLoading(false);
    }
  }, [props, loading, viewResult, dispatch]);

  let webHookEndpoint = ``;
  if(loading === false && viewResult) {
    webHookEndpoint = `${webhookUrl}${viewResult.secret}`;
  }

  let _html = ``;

  let _htmlSales = <ul className="list-group">
    <li className="list-group-item"><strong>Request URL</strong>: {webHookEndpoint} <button type="button" className="btn btn-link" onClick={ (e) => props.copyLink(viewResult.secret) } >CopyLink</button></li>
    <li className="list-group-item"><strong>Method</strong>: POST</li>
    <li className="list-group-item"><strong>Request Body</strong>:
      <RequestBodySales />
    </li>
    <li className="list-group-item"><strong>Response Body</strong>:
      <ResponseBodySales />
    </li>
  </ul>

  let _htmlMilestones = <ul className="list-group">
    <li className="list-group-item"><strong>Request URL</strong>: {webHookEndpoint} <button type="button" className="btn btn-link" onClick={ (e) => props.copyLink(viewResult.secret) } >CopyLink</button></li>
    <li className="list-group-item"><strong>Method</strong>: POST</li>
    <li className="list-group-item"><strong>Request Body</strong>:
      <RequestBodyMilestones />
    </li>
    <li className="list-group-item"><strong>Response Body</strong>:
      <ResponseBodyMilestones />
    </li>
  </ul>

  if(loading === false && viewResult){
    if(viewResult.alertType === 'sales') {
      _html = _htmlSales;
    } else if(viewResult.alertType === 'milestones') {
      _html = _htmlMilestones;
    }
  }


  const details = (
    loading === false && viewResult ?
    <div className="row">
      <div className="col-6">
        <ul className="list-group">
        <li className="list-group-item"><strong className="p-2">WebhookId:</strong> {viewResult.id}</li>
        <li className="list-group-item"><strong className="p-2">Description:</strong> {viewResult.description}</li>
        <li className="list-group-item"><strong className="p-2">AlertType:</strong> {viewResult.alertType}</li>
        <li className="list-group-item"><strong className="p-2">PostsCount:</strong> {viewResult.postsCount}</li>
        <li className="list-group-item"><strong className="p-2">Active:</strong> {viewResult.active ? "YES" : "NO"}</li>
        <li className="list-group-item"><strong className="p-2">PublicLink:</strong> {viewResult.isPublic ? "YES" : "NO"}</li>
      </ul>
      </div>
      <div className="col-6">
        {_html}
      </div>
    </div> : <CircularProgress /> )

  return (
    <>
    <DialogContent sx={{ marginTop: "-10px" }} >
      { details }
    </DialogContent>
    <DialogActions>
      <button className="btn btn-secondary" onClick={ () => props.setOpen(false) } >Close</button>
    </DialogActions>
    </>
  )

}

export default ViewDetails;

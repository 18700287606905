import React, { useState, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { setSnackBarAlert, findAllMilestones, caughtError } from '../../redux';
import getAppConfig from '../../config';
const config = getAppConfig();

const initialState = {
   description: '',
   milestoneTypeId: '',
   youtubeLibraryId: ''
}

const reducer = (state, action) => {
  return {
  ...state,
  ...action
  }
}

const CreateForm = (props) => {

  const [state, updateState] = useReducer(reducer, initialState);
  const myYoutubeList = props.youtubeLibrary.data
  const milestoneTypeList = props.milestoneType.data
  const [btnDisabled, setBtnDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    updateState({ [event.target.name]: event.target.value });
  }

  const handleSubmit = () => {

    if(state.description === '') {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `Description is required`
      }));
      return false;
    }

    if(state.milestoneTypeId === '') {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `MilestoneType is required`
      }));
      return false;
    }

    if(state.description !== '' && state.description.length > 700) {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `Description length must be less than 700`
      }));
      return false;
    }

    if(state.youtubeLibraryId === '') {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `YoutubeLibrary is required`
      }));
      return false;
    }

    setBtnDisabled(true);
    axios.post(`${config.api.URL}/me/milestone`, state).then(response => {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'success',
        message: `Success! new milestone created`
      }));

      dispatch(findAllMilestones(props.filterInput));
      props.setOpen(false);
    }).catch(error => {
      setBtnDisabled(false);
      dispatch(caughtError(error));
    })

  }

  let milestoneTypeSelect = '';
  if(milestoneTypeList.length > 0) {
    milestoneTypeSelect = milestoneTypeList.map( (row) => <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>)
  }

  let youtubeLibrarySelect = '';
  if(myYoutubeList.length > 0) {
    youtubeLibrarySelect = myYoutubeList.map( (row) => <MenuItem key={row.id} value={row.id}>{row.title}</MenuItem>)
  }

  return (
    <>
      <DialogContent sx={{ marginTop: "-5px" }} >

        <div className="row">

          <div className="col-12">
            <TextField
              multiline
              rows={2}
              autoFocus
              value={state.description}
              onChange={handleInputChange}
              name="description"
              label="Description"
              margin="dense"
              type="text"
              fullWidth
              variant="outlined"
              required
              onKeyPress={ (e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSubmit();
                  }
                }}
            />
          </div>

          <div className="col-12">
            <FormControl fullWidth style={{ marginTop: "19px" }}>
              <InputLabel>MilestoneType</InputLabel>
              <Select
                name="milestoneTypeId"
                label="milestoneTypeId"
                variant="outlined"
                margin="dense"
                value={state.milestoneTypeId}
                onChange={handleInputChange}
                required
              >
                <MenuItem value={''}>-- SELECTED NONE --</MenuItem>
                {milestoneTypeSelect}
              </Select>
            </FormControl>
          </div>

          <div className="col-12">
            <FormControl fullWidth style={{ marginTop: "19px" }}>
              <InputLabel>Youtube Library</InputLabel>
              <Select
                name="youtubeLibraryId"
                label="YoutubeLibraryId"
                variant="outlined"
                margin="dense"
                value={state.youtubeLibraryId}
                onChange={handleInputChange}
                required
              >
                <MenuItem value={''}>-- SELECTED NONE --</MenuItem>
                {youtubeLibrarySelect}
              </Select>
            </FormControl>
          </div>

        </div>

      </DialogContent>
      <DialogActions>


        <button className="btn btn-secondary" onClick={ (e) => {
            e.preventDefault();
            props.setOpen(false);
          } }>Cancel</button>
        <button disabled={btnDisabled} className="btn btn-primary" onClick={ (e) => {
            e.preventDefault();
            handleSubmit();
          }
        } >
          Add New Milestone
        </button>
      </DialogActions>
    </>
  )

}

export default CreateForm;

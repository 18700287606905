const defaultViz = "https://salesscreen.s3.us-east-2.amazonaws.com/assets/images/viz-og.gif";
const Viz1k = "https://salesscreen.s3.us-east-2.amazonaws.com/assets/images/viz-1k.gif";
const Viz2500 = "https://salesscreen.s3.us-east-2.amazonaws.com/assets/images/viz-2.5k.gif";
const Viz5k = "https://salesscreen.s3.us-east-2.amazonaws.com/assets/images/viz-5k.gif";
const Viz10k = "https://salesscreen.s3.us-east-2.amazonaws.com/assets/images/viz-10k.gif";

const Viz = (props) => {

  const saleAmount = props?.saleAmount;
  let imgUrl = defaultViz;

  let vizImgSx = {
    position: 'absolute',
    bottom: 0,
    left: '8.202vw',
    maxHeight: '15vh',
    maxWidth: '7vw'
  };

  if(saleAmount) {
    if(saleAmount >= 10000) {
      imgUrl = Viz10k;
      vizImgSx.maxHeight = '15vh';
      vizImgSx.maxWidth = '10vw';
      vizImgSx.bottom = -15;
      vizImgSx.left = 110;
    } else if(saleAmount >= 5000) {
      vizImgSx.maxHeight = '15vh';
      vizImgSx.maxWidth = '8vw';
      vizImgSx.bottom = -10;
      imgUrl = Viz5k;
    } else if(saleAmount >= 2500) {
      vizImgSx.maxHeight = '15vh';
      vizImgSx.maxWidth = '8vw';
      vizImgSx.bottom = -10;
      imgUrl = Viz2500;
    } else if(saleAmount >= 1000) {
      vizImgSx.maxHeight = '15vh';
      vizImgSx.maxWidth = '8vw';
      vizImgSx.bottom = -10;
      imgUrl = Viz1k;
    }
  }

  return <img style={vizImgSx} src={imgUrl} alt="salesscreen" />
}

export default Viz;

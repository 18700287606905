import { CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export const GetTcolumns = (props) => {
  const result = <tr>
    <th width="5%" scope="col">{ props.openGlobalLoader ? <CircularProgress size={'1rem'} /> : '#' }</th>
    <th width="70%" scope="col">Module</th>
    <th width="10%" scope="col">
      <span style={{marginLeft: "25%" }}>Active</span>
    </th>
    <th width="15%" scope="col">
      <span style={{marginLeft: "33%" }}>Action</span>
    </th>
  </tr>

  return result;
}

export const GetTsearch = (props) => {
  const result = <tr>
    <td></td>
    <td>
      <TextField
        onKeyPress={ (e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              props.onSearchInputEnterKey();
            }
          }
        }
        label="Module"
        size="small"
        name="moduleName"
        value={props.moduleName}
        onChange={props.handleInputChange}
      />
    </td>
    <td>
      <Select
        fullWidth
        label="Active"
        size="small"
        name="active"
        variant="standard"
        margin="dense"
        value={props.active}
        onChange={props.handleInputChange}
      >
        <MenuItem value={''}>-- SELECT --</MenuItem>
        <MenuItem key={'1'} value={'1'}>YES</MenuItem>
        <MenuItem key={'0'} value={'0'}>NO</MenuItem>
      </Select>
    </td>
    <td><button type="button" className="btn btn-link float-end" onClick={props.resetTableSearchInput}>Clear Filters</button></td>
  </tr>

  return result;
}

export const GetTvalues = (props) => {
  let t_index = props.t_index;
  const result = props.t_data.map((row) =>
    <tr key={row.id}>
      <td>{ ++t_index }</td>
      <td>{ row.moduleName }</td>
      <td align="center">{ row.active ? 'YES' : 'NO' }</td>
      <td align="center">
        <VisibilityIcon fontSize="small" color="primary" onClick={ (e) => props.setOpen(true, row.id, row.moduleName, 'VIEW') } /> {' '}
        <EditIcon fontSize="small" color="primary" onClick={ (e) => props.setOpen(true, row.id, row.moduleName, 'EDIT') } /> {' '}
        { props.deleted ? <RestoreFromTrashIcon fontSize="small" color="primary" onClick={ (e) => props.setOpen(true, row.id, row.moduleName, 'DELETE') } /> : <DeleteIcon fontSize="small" color="primary" onClick={ (e) => props.setOpen(true, row.id, row.moduleName, 'DELETE') } /> }
      </td>
    </tr>
  )

  return result;
}

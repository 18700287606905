import ReactAudioPlayer from 'react-audio-player';
const milestonTune ="https://salesscreen.s3.us-east-2.amazonaws.com/assets/tunes/mushroom.mp3";

const NewMilestone = (props) => {

  const postDetails = props?.postDetails;
  const mute = props?.mute;
  const totalCharLength = postDetails.milestoneTypeName.length;

  let maxWidth = '13vw'
  if(totalCharLength > 15 && totalCharLength <= 25) {
    maxWidth = '17vw';
  } else if(totalCharLength > 25) {
    maxWidth = '24vw';
  }

  if(postDetails) {

    return <>
    <h2 className="text-center post-type-box" style={{ maxWidth: maxWidth }}>
      {postDetails.milestoneTypeName}
    </h2>
      { !mute && <ReactAudioPlayer
      style={{ display: 'none' }}
      src={milestonTune}
      autoPlay
      controls /> }
    </>
  } else {
    return null;
  }

}

export default NewMilestone;

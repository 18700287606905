import React from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import { setScreenVpnStatus, callScreenPageServices, logOutUserSession } from '../../redux';
import getAppConfig from '../../config';
const config = getAppConfig();

const defaultCounterExpireSec = 30; // 30 seconds


/****** This is specific for /screen page
* when user not logged in and will call api only once
* when user vpn status become true from false
******/
class ConnectedInternalIp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      counter: 0
    }
  }

  updateCounter = () => {
    this.setState((prevState) => {
      if(prevState.counter >= defaultCounterExpireSec) {
        this.updateVpnStatus();
        return {
          ...prevState,
          counter: 0
        }
      } else {
        return {
          ...prevState,
          counter: prevState.counter + 1
        }
      }
    });
  }

  componentDidMount() {
    this.updateVpnStatus();
    this.timerId = setInterval(
      () => this.updateCounter(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  updateVpnStatus() {
    if(!this.props.session.isAuthenticated) {
      axios.post(`${config.api.URL}/me/has-connected-to-vpn`,{
        requestType: 'PUBLIC-REQUEST',
        requestCode: 'q$JNb=hS#TWL8dD^abRXh7%8CXrP67',
      }).then(response => {
        const connectedToVPN = response.data.connectedToVPN;
        console.log("connectedToVPN--->",connectedToVPN);
        if(this.props.salesscreen.connectedToVPN === false && connectedToVPN === true) {
          /** when user previous status false and now it's true then ***/
          if(window.location.pathname === '/screen') {
            console.log("-->callScreenPageServices");
            this.props.callScreenPageServices();
          }
        }
        if(this.props.salesscreen.connectedToVPN !== connectedToVPN) {
          this.props.setScreenVpnStatus(connectedToVPN);
        }
      }).catch(error => {});
    }
  }

  render() {
    //console.log("counter-->",this.state.counter);
    return null;
  }

}

const mapStateToProps = state => {
  return {
    session: state.session,
    salesscreen: state.screen
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOutUserSession: () => dispatch(logOutUserSession()),
    callScreenPageServices: () => dispatch(callScreenPageServices()),
    setScreenVpnStatus: (input) => dispatch(setScreenVpnStatus(input)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedInternalIp)

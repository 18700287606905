import React, { useState, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { setSnackBarAlert, findAllWebhooks, caughtError } from '../../redux';
import getAppConfig from '../../config';
const config = getAppConfig();

const initialState = {
  alertType: 'sales',
  description: '',
  isPublic: false,
}

const reducer = (state, action) => {
  return {
  ...state,
  ...action
  }
}

const CreateForm = (props) => {

  const [state, updateState] = useReducer(reducer, initialState);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    if(event.target.type === 'checkbox') {
      updateState({ [event.target.name]: event.target.checked });
    } else {
      updateState({ [event.target.name]: event.target.value });
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if(state.description === '') {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `description is required`
      }));
      return false;
    }

    setBtnDisabled(true);
    axios.post(`${config.api.URL}/webhook`, state).then(response => {
      dispatch(findAllWebhooks(props.filterInput));
      props.setOpen(false);
    }).catch(error => {
      setBtnDisabled(false);
      dispatch(caughtError(error));
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent sx={{ marginTop: "-20px" }} >

        <TextField
          autoFocus
          value={state.description}
          onChange={handleInputChange}
          name="description"
          label="Description"
          margin="dense"
          type="text"
          fullWidth
          variant="outlined"
          required
        />

        <FormControl fullWidth sx={{ marginTop: "9px" }}>
          <InputLabel>AlertType</InputLabel>
        <Select
          fullWidth
          label="alertType"
          name="alertType"
          variant="outlined"
          value={state.alertType}
          onChange={handleInputChange}
        >
          <MenuItem key={'sales'} value={'sales'}>Sales</MenuItem>
          <MenuItem key={'milestones'} value={'milestones'}>Milestones</MenuItem>
        </Select>
        </FormControl>

      <FormControlLabel
        className="float-end"
        control={<Switch name="isPublic" checked={state.isPublic} onChange={handleInputChange} />}
        label="IsPublicLink"
        labelPlacement="end"
        />

      </DialogContent>
      <DialogActions>
        <button className="btn btn-secondary" onClick={ (e) => {
            e.preventDefault();
            props.setOpen(false);
          } }>Cancel</button>
        <button disabled={btnDisabled} type="submit" className="btn btn-primary" >Add New Webhook</button>
      </DialogActions>
    </form>
  )

}


export default CreateForm;

import React, { useEffect, useState, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Switch from '@mui/material/Switch';
import { Box } from '@mui/system';
import { setSnackBarAlert, findAllUsers, caughtError } from '../../redux';
import getAppConfig from '../../config';
const config = getAppConfig();

const initialState = {
   name: '',
   contact: '',
   roleId: 0,
   teamId: 0,
   active: false
}

const reducer = (state, action) => {
  return {
  ...state,
  ...action
  }
}

const EditForm = (props) => {

  const [state, updateState] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    if(event.target.type === 'checkbox'){
      updateState({ [event.target.name]: event.target.checked });
    } else {
      updateState({ [event.target.name]: event.target.value });
    }
  }

  useEffect(() => {
    if(loading && props.modalDiaLogId && props.modalDiaLogId > 0) {
      let URL = `${config.api.URL}/user/${props.modalDiaLogId}`;
      axios.get(URL).then(response => {
        updateState({
          name: response.data.data.name,
          contact: response.data.data.contact ? response.data.data.contact : '',
          roleId: response.data.data.roleId,
          teamId: response.data.data.teamId,
          active: Boolean(response.data.data.active),
        });
        setBtnDisabled(false);
        setLoading(false);
      }).catch(error => {
        setLoading(false);
        console.log(error);
      })
    }
  }, [loading, props]);

  const handleSubmit = (event) => {
    event.preventDefault();

    for (const [key, value] of Object.entries(state)) {
      if(key === 'contact') continue;
      if(value === '') {
        dispatch(setSnackBarAlert({
          openSnackBar: true,
          severity: 'error',
          message: `${key} is required!`
        }));
        return false;
      }
    }

    setBtnDisabled(true);
    axios.patch(`${config.api.URL}/user/profile/${props.modalDiaLogId}`, {
      ...state,
      mobile: state.contact
    }).then(response => {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'success',
        message: `Success! ${state.name} user updated`
      }));

      dispatch(findAllUsers(props.filterInput));
      props.setOpen(false);
    }).catch(error => {
      setBtnDisabled(false);
      dispatch(caughtError(error));
    })

  }

  const rolesData = props.role.data;
  let rolesSelect = <MenuItem value={''}>NONE</MenuItem>
  if(rolesData.length > 0) {
    rolesSelect = rolesData.map( (row) => <MenuItem key={row.id} value={row.id}>{row.roleName}</MenuItem>)
  }

  const teamsData = props.team.data;
  let teamsSelect = <MenuItem value={''}>NONE</MenuItem>
  if(teamsData.length > 0) {
    teamsSelect = teamsData.map( (row) => <MenuItem key={row.id} value={row.id}>{row.teamName}</MenuItem>)
  }

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent sx={{ marginTop: "-20px" }}>
        <div className="row">

          {
            props.session.roleId === 3 &&
            <>
            <div className="col-12">
              <FormControl fullWidth sx={{ marginTop: "8px" }}>
                <InputLabel>Team</InputLabel>
                <Select
                  name="teamId"
                  label="Team"
                  variant="outlined"
                  margin="dense"
                  value={state.teamId}
                  onChange={handleInputChange}
                  required
                >{teamsSelect}</Select>
              </FormControl>
            </div>
            <div className="col-12">
              <FormControl fullWidth sx={{ marginTop: "12px" }}>
                <InputLabel>Role</InputLabel>
                <Select
                  name="roleId"
                  label="Role"
                  variant="outlined"
                  margin="dense"
                  value={state.roleId}
                  onChange={handleInputChange}
                  required
                >{rolesSelect}</Select></FormControl>
            </div>
            </>
          }



        <div className="col-12">
          <TextField
            value={state.name}
            onChange={handleInputChange}
            name="name"
            label="Profile Name"
            margin="dense"
            type="text"
            fullWidth
            variant="outlined"
            required
          />
        </div>

        <div className="col-12">
          <TextField
            value={state.contact}
            onChange={handleInputChange}
            name="contact"
            label="Contact"
            margin="dense"
            type="contact"
            fullWidth
            variant="outlined"
            sx={{ marginBottom: "10px" }}
          />
        </div>

        <div className="col-12">
          <Box sx={{ marginTop: "5px" }}>
            <FormControlLabel className="float-end" control={<Switch name="active" checked={state.active} onChange={handleInputChange} />} label="Active" labelPlacement="start" />
          </Box>
        </div>

        </div>
      </DialogContent>
      <DialogActions>
        <button className="btn btn-secondary" onClick={ (e) => {
            e.preventDefault();
            props.setOpen(false);
          } }>Cancel</button>
        <button disabled={btnDisabled} type="submit" className="btn btn-primary" >Update User</button>
      </DialogActions>
    </form>
  )

}


export default EditForm;

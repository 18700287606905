import React, { useState } from 'react';
import CreateForm from './CreateForm';
import CustomDialog from '../ui/CustomDialog';

const HeaderSection = (props) => {

  const [open, setOpen] = useState(false);

  let _rowContent = <button
     type="button"
     className="btn btn-primary"
     onClick={ (e) => setOpen(true) } >Add New Webhook</button>


  return (
    <>
    <div className="row" style={{marginBottom: "10px" }}>
      <div className="col-lg-12">
        <div className="float-end">
        {_rowContent}
        </div>
      </div>
    </div>

      <CustomDialog maxWidth="sm" title="Add New Webhook" open={open} setOpen={setOpen} >
        <CreateForm
          setOpen={setOpen}
          filterInput={props.filterInput}
        />
      </CustomDialog>

    </>
  );
}

export default HeaderSection;

import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const DeleteConfirmDialog = (props) => {
  const session = useSelector((state: RootState) => state.session);
  const { children, open, setOpen, onConfirm, modalDiaLogId } = props;
  return (
    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="confirm-dialog">
      <div className={"theme"+session.theme+'dialog'}>
      <DialogTitle>
        <span className="card-title" style={{ marginBottom: '-10px' }}>{ props.title ? props.title : '' }</span>
      </DialogTitle>
      <DialogContent sx={{ marginTop: "-20px" }} >{children}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setOpen(false)} color="secondary">No</Button>
        <Button variant="contained" onClick={() => { setOpen(false); onConfirm(modalDiaLogId); }} color="primary">Yes</Button>
      </DialogActions>
     </div>
    </Dialog>
  )
}
export default DeleteConfirmDialog;

import React, { useState, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import { setSnackBarAlert, findAllSales, caughtError } from '../../redux';
import ClientSearch from './ClientSearch';
import getAppConfig from '../../config';
const config = getAppConfig();

const initialState = {
   accountNumber: '',
   amount: '',
   description: '',
   youtubeLibraryId: '',
   industryId: ''
}

const reducer = (state, action) => {
  return {
  ...state,
  ...action
  }
}

const CreateForm = (props) => {

  const [state, updateState] = useReducer(reducer, initialState);
  const myYoutubeList = props.youtubeLibrary.data
  const industryList = props.industriesList;
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showAccountBox, setShowAccountBox] = useState(false);
  const [showClientSearch, setShowClientSearch] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    updateState({ [event.target.name]: event.target.value });
  }

  const handleSubmit = () => {

    if(state.description === '') {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `Description is required`
      }));
      return false;
    }

    if(state.description !== '' && state.description.length > 700) {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `Description length must less than 700`
      }));
      return false;
    }

    if(state.amount === '') {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `Amount is required`
      }));
      return false;
    }

    if(state.amount < 0) {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `Amount should not be less than zero`
      }));
      return false;
    }

    if(state.amount !== '' && state.amount.length > 7) {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `Amount length must less than or equal to 7`
      }));
      return false;
    }

    if(state.youtubeLibraryId === '') {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `YoutubeLibrary is required`
      }));
      return false;
    }

    if(state.industryId === '') {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `IndustrySelection is required`
      }));
      return false;
    }

    setBtnDisabled(true);
    axios.post(`${config.api.URL}/me/sale`,state).then(response => {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'success',
        message: `Success! new sale created`
      }));

      dispatch(findAllSales(props.filterInput));
      props.setOpen(false);
    }).catch(error => {
      setBtnDisabled(false);
      dispatch(caughtError(error));
    })

  }

  const toggleClientSearch = () => {
    props.setModalSize('md');
    setShowClientSearch(!showClientSearch);
  }

  const closeClientSearchForm = () => {
    props.setModalSize('sm');
    setShowClientSearch(false);
  }

  const updateAccountNumber = (accountNumber) => {
    props.setModalSize('sm');
    updateState({ accountNumber: accountNumber});
    setShowClientSearch(!showClientSearch);
  }

  let youtubeLibrarySelect = '';
  if(myYoutubeList.length > 0) {
    youtubeLibrarySelect = myYoutubeList.map( (row) => <MenuItem key={row.id} value={row.id}>{row.title}</MenuItem>)
  }

  let IndustrySelect = '';
  if(industryList.length > 0) {
    IndustrySelect = industryList.map( (row) => <MenuItem key={row.id} value={row.id}>{row.industryName}</MenuItem>)
  }

  return (
    <>

        <DialogContent sx={{ marginTop: "-5px" }} >

          <div className="row">
            <div className="col-12">
              <TextField
                multiline
                rows={2}
                autoFocus
                value={state.description}
                onChange={handleInputChange}
                name="description"
                label="Description"
                margin="dense"
                type="text"
                fullWidth
                variant="outlined"
                required
                onFocus={closeClientSearchForm}
                onKeyPress={ (e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSubmit();
                    }
                  }}
              />
            </div>
            <div className="col-lg-12">
              <TextField
                value={state.amount}
                onChange={handleInputChange}
                name="amount"
                label="Amount"
                margin="dense"
                type="number"
                fullWidth
                variant="outlined"
                required
                onFocus={closeClientSearchForm}
                onKeyPress={ (e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSubmit();
                    }
                  }
                }
              />
            </div>
            <div className="col-lg-12">
              <FormControl fullWidth style={{ marginTop: "9px" }}>
                <InputLabel>Youtube Library</InputLabel>
                <Select
                  name="youtubeLibraryId"
                  label="YoutubeLibraryId"
                  variant="outlined"
                  margin="dense"
                  value={state.youtubeLibraryId}
                  onChange={handleInputChange}
                  required
                >
                  <MenuItem value={''}>-- SELECTED NONE --</MenuItem>
                  {youtubeLibrarySelect}
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-12">
              <FormControl fullWidth style={{ marginTop: "11px" }}>
                <InputLabel>Industry</InputLabel>
                <Select
                  name="industryId"
                  label="Industry"
                  variant="outlined"
                  margin="dense"
                  value={state.industryId}
                  onChange={handleInputChange}
                  required
                >
                  <MenuItem value={''}>-- SELECTED NONE --</MenuItem>
                  {IndustrySelect}
                </Select>
              </FormControl>
            </div>
            <div  className="col-lg-12 mt-4">
              <Switch
                size="small"
                name="showAccountBox"
                checked={showAccountBox}
                onChange={ e => setShowAccountBox(e.target.checked) } />
              <small>
                Enter Client Account Details ?
              </small>
            </div>
            { showAccountBox &&
              <>
              <div className="col-lg-12 mt-2">
                <TextField
                value={state.accountNumber}
                onChange={handleInputChange}
                name="accountNumber"
                label="Account Number"
                margin="dense"
                type="text"
                fullWidth
                variant="outlined"
                onFocus={closeClientSearchForm}
                onKeyPress={ (e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSubmit();
                    }
                  }
                } />
              <span onClick={toggleClientSearch} className="btn btn-link float-start">Toggle search</span>
              </div>
              </>
            }

          </div>

          { showClientSearch &&
            <Box sx={{ p: 2, border: '1px dashed grey', mb: '10px' }} >
              <ClientSearch updateAccountNumber={updateAccountNumber} />
            </Box>
          }

        </DialogContent>

        <DialogActions>

          <button className="btn btn-secondary" onClick={ (e) => {
              e.preventDefault();
              props.setOpen(false);
            } }>Cancel</button>
          <button disabled={btnDisabled} className="btn btn-primary" onClick={ (e) => {
              e.preventDefault();
              handleSubmit();
            }
          } >
            Add New Sale
          </button>
        </DialogActions>

    </>
  )

}

export default CreateForm

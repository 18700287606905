import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmationDialog from '../ui/ConfirmationDialog';
import { setSnackBarAlert } from '../../redux';
import axios from 'axios';
import getAppConfig from '../../config';
const config = getAppConfig();

const ResetScreen = (props) => {

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const reloadScreen = (value) => {
    if(value === 'YES') {
      axios.get(`${config.api.URL}/app-setting/reload-screen`).then(apiResponse => {
        const response = apiResponse.data;
        if(response.success) {
          dispatch(setSnackBarAlert({
            openSnackBar: true,
            severity: 'success',
            message: response.message
          }));
        }
        setOpen(false);
      }).catch(error => {
        console.log(error);
      })
    }
  }

  return (
    <div className="row">
      <div className="col-lg-4 col-md-4 label ">
        Refresh Screen Page
      </div>
      <div className="col-lg-8 col-md-8">
        <li style={{ cursor: 'pointer' }} className="nav-link" onClick={ () => setOpen(true) }>Refresh Screen Page</li>
      </div>
      <ConfirmationDialog title={`Refresh Screen Page`} onConfirm={reloadScreen} open={open} setOpen={setOpen} >
        Are you sure ? all open screen link will reload.
      </ConfirmationDialog>
    </div>
  );
}

export default ResetScreen;

import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import axios from 'axios';
import FooterPagination from './partials/FooterPagination';
import SettingsPopOver from './partials/SettingsPopOver';
import ScreenAlert from './ScreenAlert';
import {
  callScreenPageServices,
  setScreenViewAlert,
  logOutUserSession,
  setScreenMuteAll,
  setScreenAutoSlidePages,
  setScreenAutoPlay } from '../../redux';
import getAppConfig from '../../config';
import Header from './partials/Header';
import ScreenPageTimer from './partials/ScreenPageTimer';
import ScreenPages from './ScreenPages'
import NotConnectedVPn from './NotConnectedVPn';
import CustomTimer from "../../components/ui/CustomTimer";
import ConnectedInternalIp from '../ui/ConnectedInternalIp';

const config = getAppConfig();
const defaultPagesCount = 3;

const Layout = (props) => {

  const session = useSelector((state) => state.session);
  const salesscreen = useSelector((state) => state.screen);

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(defaultPagesCount);
  const [subscribedPages, setSubscribedPages] = useState([]);
  const [enablePageTimer, setEnablePageTimer] = useState(true);
  const [initialData, setInitialData] = useState(false);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logOutUserSession());
    setCurrentPage(1);
    setTotalPages(defaultPagesCount);
  }

  const handelScreenSetting = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name === 'screenMuteAll') {
      window.localStorage.setItem("screenMuteAll", value ? 1 : 0);
      dispatch(setScreenMuteAll(value));
    }

    if(name === 'screenAutoPlay') {
      window.localStorage.setItem("screenAutoPlay", value ? 1 : 0);
      dispatch(setScreenAutoPlay(value));
    }

    /** toogle screenAutoSlidePages **/
    if(name === 'screenAutoSlidePages') {
      window.localStorage.setItem("screenAutoSlidePages", value ? 1 : 0);
      dispatch(setScreenAutoSlidePages(value));
    }

  }

  const updateLocalSettings = useCallback(() => {
    /*** update localStorage items into store ***/
    if(window.localStorage.getItem("screenMuteAll") !== null) {
      const _value = parseInt(window.localStorage.getItem("screenMuteAll")) === 1 ? true : false;
      dispatch(setScreenMuteAll(_value));
    } else {
      window.localStorage.setItem("screenMuteAll", 0);
      dispatch(setScreenMuteAll(false));
    }

    if(window.localStorage.getItem("screenAutoPlay") !== null) {
      const _value = parseInt(window.localStorage.getItem("screenAutoPlay")) === 1 ? true : false;
      dispatch(setScreenAutoPlay(_value));
    } else {
      window.localStorage.setItem("screenAutoPlay", 1);
      dispatch(setScreenAutoPlay(true));
    }

    if(window.localStorage.getItem("screenAutoSlidePages") !== null) {
      const _value = parseInt(window.localStorage.getItem("screenAutoSlidePages")) === 1 ? true : false;
      dispatch(setScreenAutoSlidePages(_value));
    } else {
      window.localStorage.setItem("screenAutoSlidePages", 1);
      dispatch(setScreenAutoSlidePages(true));
    }
  }, [dispatch]);

  /******* pagination - start ******/
  const setPrevPage = () => {
    setEnablePageTimer(false);
    setTimeout(()=>{
      if(page > 1) {
        setPage(page-1);
        setEnablePageTimer(true);
      } else {
        setPage(totalPages);
        setEnablePageTimer(true);
      }
    }, 100);
  }
  const setNextPage = () => {
    setEnablePageTimer(false);
    setTimeout(()=> {
      if(page < totalPages) {
        setPage(page+1);
        setEnablePageTimer(true);
      } else {
        setPage(1);
        setEnablePageTimer(true);
      }
    }, 100);
  }
  const setCurrentPage = (page) => {
    setTimeout(()=>{
      setPage(page);
      setEnablePageTimer(true);
    }, 100);
  }
  const slidePages = () => {
    if(page === totalPages) {
      setPage(1);
    } else {
      setPage(page + 1);
    }
  }
  /******* pagination - end ******/

  /****** extra screens - start ********/
  const updateExtraScreens = (teamSlug) => {
    if(teamSlug !== undefined) {
      setTotalPages(defaultPagesCount);
      axios.get(`${config.api.URL}/team/${teamSlug}/subscribed-screens`).then(response => {
        const success = response.data.success;
        const subscribedPages = response.data.data;
        if(success === true && subscribedPages.length) {
          setSubscribedPages(subscribedPages)
          setTotalPages(defaultPagesCount + subscribedPages.length);
          setCurrentPage(1);
        }
      }).catch(error => {
      });
    }
  }
  const setScreenTeamId = (screenTeamId, team) => {
    const _screenTeamId = parseInt(screenTeamId);
    if(_screenTeamId === 0) {
      setSubscribedPages([]);
      setTotalPages(defaultPagesCount);
      setCurrentPage(1);
    } else {
      updateExtraScreens(team.slug);
    }
  }
  /****** extra screens - end ********/


  useEffect(() => {
    if(loading) {
      setLoading(false);
      dispatch(setScreenViewAlert(false));
      updateLocalSettings();
    }

    if(initialData === false) {
      if(session.isAuthenticated || salesscreen.connectedToVPN) {
        dispatch(callScreenPageServices());
        setInitialData(true);
      }
    }

  }, [loading, initialData, dispatch, session, salesscreen, updateLocalSettings]);

  let view = null;

  if(salesscreen.connectedToVPN || session.isAuthenticated) {

    let _footer_pageTimeCounter = '';
    let _footer_pagination = '';

    if(salesscreen.screenAutoSlidePages && enablePageTimer) {
      _footer_pageTimeCounter = <ScreenPageTimer slidePages={slidePages} />
    }

    _footer_pagination = <FooterPagination
      totalPages={totalPages}
      page={page}
      setPrevPage={setPrevPage}
      setNextPage={setNextPage}
    />

  view = <div style={{ marginTop: '70px' }} className={"container-fluid theme"+session.theme}>
              <Header setScreenTeamId={setScreenTeamId} />

                <ScreenPages
                  page={page}
                  subscribedPages={subscribedPages}
                />

              <ScreenAlert  />

            <footer style={{ height: '60px' }}
              className={ session.theme === "dark" ? "footer fixed-bottom align-items-center" : "bg-white footer fixed-bottom align-items-center"}>
                <div className="row">


                  <div className="mx-2 col-lg-4 col-md-6 col-xm-8">

                    { session.isAuthenticated &&
                    <span
                      className="btn btn-sm btn-link"
                      onClick={() => { handleLogout() }} >
                      SignOut
                    </span>
                    }

                    <span
                      className="btn btn-sm btn-link">
                      <Link to={session.isAuthenticated ? '/': '/users/login'}>
                        { session.isAuthenticated ? 'Dashboard' : 'Login' }
                      </Link>
                    </span>

                    <SettingsPopOver handelScreenSetting={handelScreenSetting} />

                  </div>


                  <div className="col d-none d-lg-block d-md-block">
                    <div className="float-end">
                      {_footer_pageTimeCounter}
                    </div>
                  </div>

                  <div className="col d-none d-lg-block d-md-block">
                    <div className="float-start">
                      {_footer_pagination}
                    </div>
                  </div>


                  <div className="mt-2 col-2 d-none d-lg-block ">
                    <div style={{ fontSize: '1.1em', fontFamily: '"Poppins", sans-serif' }}>
                      <CustomTimer />
                    </div>
                  </div>


                </div>
              </footer>
            </div>
  } else {
    view = <NotConnectedVPn message={'USER CAN ACCESS THIS PAGE VIA INTERNAL IP'}/>
  }

  document.body.className = session.theme === 'dark' ? 'themedark' : '#FFF';

  return (<>
    {view}
    <ConnectedInternalIp />
    </>);

}

export default Layout;

import axios from 'axios';
import getAppConfig from '../../config';
import { setGlobalLoader, setSnackBarAlert, caughtError } from '../ui/uiActions';
import { getQueryString } from '../../components/common/functions';

import {
  SALE_SET_INITIAL_STATE,
  SALE_FIND_ALL,
  SALE_VIEW
} from './saleTypes';

const config = getAppConfig();

export const viewSale = (saleId) => {
  let URL = `${config.api.URL}/me/sale/${saleId}`;
  return (dispatch) => {
    dispatch(onSuccessViewSale({data: false}))
    axios.get(URL).then(response => {
      dispatch(onSuccessViewSale(response))
    }).catch(error => {
      dispatch(caughtError(error))
    })
  }
}

export const deleteSale = (saleId, filterInput) => {
  let URL = `${config.api.URL}/me/sale/mark-delete/${saleId}`;
  if(filterInput.deleted === true) {
    // if record already deleted
    URL = `${config.api.URL}/me/sale/unmark-delete/${saleId}`;
  }
  return (dispatch) => {
    axios.patch(URL).then(response => {

      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'success',
        message: `Success! sale ${filterInput.deleted ? 'restored' : ' moved to trash'}`
      }))

      dispatch(findAllSales(filterInput));
    }).catch(error => {
      dispatch(caughtError(error))
    })
  }
}

export const findAllSales  = (filters="") => {
  let URL = `${config.api.URL}/me/sale`;
  if(filters !== '') {
    URL = `${URL}?${getQueryString(filters)}`;
  }
  return (dispatch) => {
    dispatch(setGlobalLoader(true))
    axios.get(URL).then(response => {
      dispatch(setGlobalLoader(false))
      dispatch(onSuccessFindAllSale(response))
    }).catch(error => {
      dispatch(setGlobalLoader(false))
      dispatch(setSaleInitialState())
      dispatch(caughtError(error))
    })
  }
}
export const setSaleInitialState = () => {
  return {
    type: SALE_SET_INITIAL_STATE
  }
}

export const onSuccessViewSale = response => {
  return {
    type: SALE_VIEW,
    payload: response.data
  }
}

export const onSuccessFindAllSale = response => {
  return {
    type: SALE_FIND_ALL,
    payload: response.data
  }
}

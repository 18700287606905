import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { viewLog } from '../../redux';

const ViewDetails = (props) => {

  const viewResult = useSelector((state) => state.log.viewResult);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if(loading && props.modalDiaLogId && props.modalDiaLogId > 0) {
      dispatch(viewLog(props.modalDiaLogId));
      setLoading(false);
    }
  }, [props,loading, viewResult, dispatch]);


  const details = (
    loading === false && viewResult ?
    <>
    <div className="row">
      <div className="col-12">
        <ul className="list-group">
          <li className="list-group-item"><strong className="p-2">LogId:</strong> {viewResult.id}</li>
          <li className="list-group-item"><strong className="p-2">ActionPerformedBy:</strong> {viewResult.actionPerformedBy}</li>
          <li className="list-group-item"><strong className="p-2">RequestIp:</strong> {viewResult.requestIp}</li>
          <li className="list-group-item"><strong className="p-2">LogMessage:</strong> {viewResult.logMessage}</li>
          <li className="list-group-item"><strong className="p-2">CreatedAt:</strong> {viewResult.createdAt}</li>
          <li className="list-group-item"><strong className="p-2">TableRef:</strong> {viewResult.tableRef}</li>
          <li className="list-group-item"><strong className="p-2">RecordRef:</strong> <pre>{viewResult.recordRef}</pre></li>
        </ul>
      </div>
    </div>
    </> : <CircularProgress /> )

  return (
    <>
    <DialogContent sx={{ marginTop: "-10px" }} >
      { details }
    </DialogContent>
    <DialogActions>
      <button className="btn btn-secondary" onClick={ () => props.setOpen(false) } >Close</button>
    </DialogActions>
    </>
  )

}

export default ViewDetails;

import { CircularProgress } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


export const GetTcolumns = (props) => {
  const result =  <tr>
    <th scope="col" style={{ width: "5%" }}>{ props.openGlobalLoader ? <CircularProgress size={'1rem'} /> : '#' }</th>
    <th scope="col" style={{ width: "15%" }}>ActionPerformedBy</th>
    <th scope="col" style={{ width: "15%", textAlign: 'center' }}>RequestIp</th>
    <th scope="col" style={{ width: "30%" }}>LogMessage</th>
    <th scope="col" style={{ width: "20%" }}>CreatedAt</th>
    <td align="right" style={{ width: "25%", textAlign: 'center' }}><strong>Action</strong></td>
  </tr>
  return result;
}

export const GetTsearch = (props) => {
  const result = <tr>
    <td></td>
    <td>
      <TextField
        onKeyPress={ (e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              props.onSearchInputEnterKey();
            }
          }
        }
        label="actionPerformedBy"
        size="small"
        name="actionPerformedBy"
        value={props.actionPerformedBy}
        onChange={props.handleInputChange}
      />
    </td>
    <td>
      <TextField
        onKeyPress={ (e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              props.onSearchInputEnterKey();
            }
          }
        }
        label="Request Ip"
        size="small"
        name="requestIp"
        value={props.requestIp}
        onChange={props.handleInputChange}
      />
    </td>
    <td>
      <TextField
        onKeyPress={ (e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              props.onSearchInputEnterKey();
            }
          }
        }
        label="message"
        size="small"
        name="logMessage"
        value={props.logMessage}
        onChange={props.handleInputChange}
      />
    </td>
    <td>
      {/*
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            disableFuture
            onChange={(newValue) => {
              try {
                props.handleDateChange(newValue);
              } catch(error) {
                console.log('DesktopDatePicker-->error -->',error);
                alert("manual update not allowed...")
              }
            }}
            value={props.createdAt}
            label="CreatedAt"

            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
        */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker disableFuture
        onChange={(newValue) => {
          try {
            props.handleDateChange(newValue);
          } catch(error) {
            console.log('DatePicker-->error -->',error);
            alert("manual update not allowed...")
          }
        }}
        value={props.createdAt}
        label="CreatedAt"

        />
      </LocalizationProvider>
    </td>
    <td align="right">
      <button type="button" className="btn btn-link" onClick={props.resetTableSearchInput}>Clear Filters</button>
    </td>
  </tr>

  return result;
}

export const GetTvalues = (props) => {
  let t_index = props.t_index;
  const result = props.t_data.map((row) =>
    <tr key={row.id}>
      <td valign="center">{ ++t_index }</td>
      <td valign="center">{ row.actionPerformedBy }</td>
      <td align="center" valign="center">{ row.requestIp }</td>
      <td valign="center">{ row.logMessage }</td>
      <td valign="center">{ row.createdAt }</td>
      <td align="center" valign="center">
        <VisibilityIcon fontSize="small" color="primary" onClick={ (e) => props.setOpen(true, row.id, `${row.actionPerformedBy} - ${row.createdAt}`, 'VIEW') } /> {' '}
      </td>
    </tr>
  )

  return result;
}

import React, { useEffect, useCallback, useState } from 'react'
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import SquareIcon from '@mui/icons-material/Square';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import TopLogo from './TopLogo';
import { useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import { onLoginSuccess, caughtError, setSnackBarAlert } from '../../redux';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import getAppConfig from '../../config';
const config = getAppConfig();

const GoogleCallback = (props) => {
  let navigate = useNavigate();
  const [teams, setTeams] = useState([]);
  const [teamId, setTeamId] = useState('NOT_SET');
  const [isNewUser, setIsNewUser] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [responseUserData, setResponseUserData] = useState(false);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const getAllTeams = (event) => {
    axios.post(`${config.api.URL}/me/get-all-teams-first-time-login`,{
      requestType: 'PUBLIC-REQUEST',
      requestCode: 'q$JNb=hS#TWL8dD^abRXh7%8CXrP67',
    }).then(response => {
      setTeams(response.data.data)
    }).catch(error => {
      console.log(`public/all-teams error => `,error);
    })
  };

  const initFetch = useCallback(() => {
    const code = searchParams.get('code');
    if(code) {
      getAllTeams();

      axios.post(`${config.api.URL}/google/callback`,{
        code: code
      }).then(response => {
        setLoading(false);
        const success = response.data.success;
        if(success === false) {
          /****** invalid domain ******/
          setResponseUserData('triedExternalDomain');
          setMessage(response.data.message);
        } else {
          /****** valid domain ******/
          if(response.data.isNewUser === false) {
            /**** existign user login *****/
            dispatch(onLoginSuccess(response));
            setLoading(true);
            //setTimeout(()=> navigate(`/`), 100);
            navigate(`/`);
          } else {
            dispatch(onLoginSuccess(response));
            /**** new user - firt time login *****/
            setResponseUserData(response.data);
            setIsNewUser(true);
          }
        }
      }).catch(error => {
        setLoading(false);
        dispatch(caughtError(error));
        setResponseUserData('LoginError');
        setMessage('LoginError')
      })

    }
  }, [searchParams, navigate, dispatch]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  const handleTeamSelection = (event) => {
    const target = event.target;
    const teamId = target.value;
    setTeamId(teamId);
  };

  const loginAfterTeamSelection = (event) => {

    /** update team then login **/
    if(teamId === 'NOT_SET') {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `SELECT YOUR TEAM`
      }))
    } else {
      axios.post(`${config.api.URL}/me/change-team`,{
        newUserId: responseUserData.id,
        teamId: teamId
      }).then(response => {
        // redirect to dashboard page
        dispatch(onLoginSuccess(response));
        setLoading(true);
        //setTimeout(()=> navigate(`/my-profile`), 100);
        navigate(`/my-profile`)
      }).catch(error => {
        console.log(`me/change-team error ==>`,error);
      })
    }
  };

  let teamsSelect = '';
  if(teams.length > 0) {
    teamsSelect = teams.map( (row) => <MenuItem key={`${row.id}`} value={`${row.id}`} >
     {
       row.logoFileName ?
       <img src={row.logoResizedUrl} alt={row.teamName} style={{ maxHeight: '48px' }} /> :
         <>{ row.id > 0 && <SquareIcon style={{ color: row.colorCode }} fontSize="large" />}{''}</>
      }
     <span className="mx-2">{row.teamName}</span>
    </MenuItem>);
  }

  let viewResponse = '';

  if(responseUserData === 'triedExternalDomain') {
    viewResponse = <>
    <div className="mt-2 mb-2">
      <Alert severity="warning">{message}</Alert>
      <p className="text-center"><Link to="/">Login</Link></p>
    </div>
    </>
  } else if(responseUserData === 'LoginError') {
    viewResponse = <>
    <div className="mt-2 mb-2">
      <Alert severity="error">{message}</Alert>
      <p className="text-center"><Link to="/">Login</Link></p>
    </div>
    </>
  } else if(isNewUser && teamsSelect !== '') {
    viewResponse = <>
    <div className="col-12 mt-2 mb-2">
      <Select
        fullWidth
        label="Team"
        size="small"
        name="teamId"
        variant="standard"
        margin="dense"
        value={teamId}
        onChange={handleTeamSelection}
      >
        <MenuItem value={'NOT_SET'}>-- SELECT YOUR TEAM --</MenuItem>
        {teamsSelect}
      </Select>
      </div>
      <div className="col-12">
        <button onClick={loginAfterTeamSelection} className="btn btn-primary w-100" type="submit">Save Selected Team</button>
      </div>

    </>
  }

  return (
      <main>
        <div className="container">
          <section className="section register min-vh-100 d-flex flex-column align-items-center py-4">

            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                  <TopLogo />

                  {
                    viewResponse !== '' &&
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="col-12">
                        { viewResponse }
                        </div>
                      </div>
                    </div>
                  }

                  { loading &&
                    <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
                      <CircularProgress color="success" />
                    </Stack>
                  }

                </div>
              </div>
            </div>

          </section>
          </div>
      </main>
  );
}


export default GoogleCallback;

import { CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { HtmlScreen } from '../common/functions';

function getHeaderColums(props, roleId) {
  let returnResult = null;
  switch(roleId) {
    case 1:
    returnResult = <tr>
      <th scope="col" style={{ width: "5%" }}>{ props.openGlobalLoader ? <CircularProgress size={'1rem'} /> : '#' }</th>
      <th scope="col" style={{ width: "25%" }}>Description</th>
      <th scope="col" style={{ width: "10%" }}>Amount</th>
      <th scope="col" style={{ width: "20%" }}>Industry</th>
      <th scope="col" style={{ width: "20%" }}>CreatedAt</th>
      <td align="right" style={{ width: "20%" }}><strong style={{ marginRight: '12%' }}>Action</strong></td>
    </tr>
    break;
    case 2:
    returnResult = <tr>
      <th scope="col" style={{ width: "5%" }}>{ props.openGlobalLoader ? <CircularProgress size={'1rem'} /> : '#' }</th>
      <th scope="col" style={{ width: "15%" }}>PostedBy</th>
      <th scope="col" style={{ width: "30%" }}>Description</th>
      <th scope="col" style={{ width: "10%" }}>Amount</th>
      <th scope="col" style={{ width: "15%" }}>Industry</th>
      <th scope="col" style={{ width: "15%" }}>CreatedAt</th>
      <td align="right" style={{ width: "10%" }}><strong style={{ marginRight: '12%' }}>Action</strong></td>
    </tr>
    break;
    case 3:
    returnResult = <tr>
      <th scope="col" style={{ width: "5%" }}>{ props.openGlobalLoader ? <CircularProgress size={'1rem'} /> : '#' }</th>
      <th scope="col" style={{ width: "15%" }}>PostedBy</th>
      <th scope="col" style={{ width: "20%" }}>Description</th>
      <th scope="col" style={{ width: "10%" }}>Amount</th>
      <th scope="col" style={{ width: "13%" }}>Industry</th>
      <th scope="col" style={{ width: "12%" }}>CreatedAt</th>
      <td align="right" style={{ width: "10%" }}><strong style={{ marginRight: '12%' }}>Action</strong></td>
    </tr>
    break;
    default:
    returnResult = <tr><th>{''}</th></tr>
    break;
  }
  return returnResult;
}

export const GetTcolumns = (props) => {
  const result = getHeaderColums(props,props.session.roleId);
  return result;
}

export const GetTsearch = (props) => {

  const industriesData = props.industriesList;
  let industriesSelect = '';
  if(industriesData.length > 0) {
    industriesSelect = industriesData.map( (row) => <MenuItem key={row.id} value={row.id}>{row.industryName}</MenuItem>)
  }

  const result = <tr>
    <td></td>
    { [2,3].includes(props.session.roleId) &&  <td>
      <TextField
        onKeyPress={ (e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              props.onSearchInputEnterKey();
            }
          }
        }
        label="Name"
        size="small"
        name="postedBy"
        value={props.postedBy}
        onChange={props.handleInputChange}
      />
  </td> }
    <td></td>
    <td></td>
    <td>
      <Select
        fullWidth
        label="Industry"
        size="small"
        name="industryId"
        variant="standard"
        margin="dense"
        value={props.industryId}
        onChange={props.handleInputChange}
      >
        <MenuItem value={''}>-- SELECT --</MenuItem>
        {industriesSelect}
      </Select>
    </td>
    <td>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker disableFuture
          onChange={(newValue) => {
            try {
              props.handleDateChange(newValue);
            } catch(error) {
              console.log('DesktopDatePicker-->error -->',error);
              alert("manual update not allowed...")
            }
          }}
          value={props.createdAt}
          label="Date"

          />
        </LocalizationProvider>
    </td>
    <td align="right">
      <button type="button" className="btn btn-link" onClick={props.resetTableSearchInput}>Clear Filters</button>
    </td>
  </tr>

  return result;
}

export const GetTvalues = (props) => {
  let t_index = props.t_index;
  const result = props.t_data.map((row) =>
    <tr key={row.id}>
      <td>{ ++t_index }</td>
      { [2,3].includes(props.session.roleId) && <td>{ row.postedBy }</td> }
      <td><HtmlScreen data={ row.description } /></td>
      <td align="center" >{`$${parseFloat(row.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</td>
      <td>{row.industryName}</td>
      <td>{ row.createdAt }</td>
      <td>
        <div className="float-end" style={{ marginRight: '15%' }}>
          <VisibilityIcon fontSize="small" color="primary" onClick={ (e) => props.setOpen(true, row.id, `${row.teamName} - ${row.postedBy} - ${row.createdAt}`, 'VIEW') } /> {' '}
          { props.deleted ? <RestoreFromTrashIcon fontSize="small" color="primary" onClick={ (e) => props.setOpen(true, row.id, `${row.teamName} - ${row.postedBy} - ${row.createdAt}`, 'DELETE') } /> : <DeleteIcon fontSize="small" color="primary" onClick={ (e) => props.setOpen(true, row.id, `${row.teamName} - ${row.postedBy} - ${row.createdAt}`, 'DELETE') } /> }
        </div>
      </td>
    </tr>
  )

  return result;
}

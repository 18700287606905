import React, { useEffect, useState } from 'react'
import PageTitleContainer from '../common/PageTitleContainer';
import ResetScreen from './ResetScreen';
import SwitchSetting from './SwitchSetting';
import TextFieldSetting from './TextFieldSetting';
import axios from 'axios';
import getAppConfig from '../../config';
const config = getAppConfig();

const ApplicationSettingsContainer = (props) => {

  const [loading, setLoading] = useState(true);
  const [settingsList, setSettingsList] = useState([]);

  useEffect(() => {
    if(loading) {
      const URL = `${config.api.URL}/app-setting`;
      axios.get(URL).then(response => {
        const result = response.data.data;
        let settingsList = []
        for(const row of result) {
          if(parseInt(row.display) === 1) {
            if(row.inputType === 'boolean') {
              settingsList.push(<SwitchSetting
                item={row}
              />);
            } else if(row.inputType === 'text') {
              settingsList.push(<TextFieldSetting
                item={row}
              />);
            }
          }
        }
        setSettingsList(settingsList)
      }).catch(error => {
        console.log(error);
      })
    }
    setLoading(false);
  }, [loading]);

  let settingComponents = '';

  if(settingsList.length > 0) {
    settingComponents = settingsList.map((settingRow, index) => <div style={{ marginBottom: '20px' }} key={index} > {settingRow}</div>);
  }

  return (
    <main id="main" className="main">
      <PageTitleContainer pageTitle={props.pageTitle} />
      <section className="section profile">
        <div className="row">

          <div className="col-xl-12">

            <div className="card">
              <div className="card-body pt-3">

                <ul className="nav nav-tabs nav-tabs-bordered">

                  <li className="nav-item">
                    <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#setting-page">Settings</button>
                  </li>

                </ul>
                <div className="tab-content pt-2">

                  <div className="tab-pane fade show active setting-page" id="setting-page">
                    <h5 className="card-title">Settings</h5>
                    {settingComponents}
                    <div style={{ marginBottom: '20px' }}>
                      <ResetScreen />
                    </div>

                  </div>

                </div>

              </div>
            </div>

          </div>


        </div>
      </section>
    </main>
  );
}

export default ApplicationSettingsContainer

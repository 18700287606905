import { CircularProgress } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function getHeaderColums(props, roleId) {
  let returnResult = null;
  switch(roleId) {
    case 3:
    returnResult = <tr>
      <th scope="col" style={{ width: "5%" }}>{ props.openGlobalLoader ? <CircularProgress size={'1rem'} /> : '#' }</th>
      <th style={{ width: "5%" }}></th>
      <th scope="col" style={{ width: "20%" }}>Name</th>
      <th scope="col" style={{ width: "15%" }}>Email</th>
      { props.session.roleId === 3 && <th scope="col" style={{ width: "15%", textAlign: 'center' }}>Team</th> }
      { props.session.roleId === 3 && <th scope="col" style={{ width: "10%", textAlign: 'center' }}>Role</th> }
      <th scope="col" style={{ width: "10%", textAlign: 'center' }}>Active</th>
      <td align="right" style={{ width: "15%" }}><strong style={{ marginRight: '22%' }}>Action</strong></td>
    </tr>
    break;
    default:
    returnResult = <tr>
      <th scope="col" style={{ width: "5%" }}>{ props.openGlobalLoader ? <CircularProgress size={'1rem'} /> : '#' }</th>
      <th style={{ width: "5%" }}></th>
      <th scope="col" style={{ width: "20%" }}>Name</th>
      <th scope="col" style={{ width: "25%" }}>Email</th>
      <th scope="col" style={{ width: "25%", textAlign: 'center' }}>Active</th>
      <td align="right" style={{ width: "20%" }}><strong style={{ marginRight: '22%' }}>Action</strong></td>
    </tr>
    break;
  }
  return returnResult;
}

export const GetTcolumns = (props) => {
  const result = getHeaderColums(props, props.session.roleId);
  return result;
}

export const GetTsearch = (props) => {

  const rolesData = props.role.data;
  let rolesSelect = '';
  if(rolesData.length > 0) {
    rolesSelect = rolesData.map( (row) => <MenuItem key={row.id} value={row.id}>{row.roleName}</MenuItem>)
  }

  const teamsData = props.team.data;
  let teamsSelect = '';
  if(teamsData.length > 0) {
    teamsSelect = teamsData.map( (row) => <MenuItem key={row.id} value={row.id}>{row.teamName}</MenuItem>)
  }

  const result = <tr>
    <td></td>
    <td></td>
    <td>
      <TextField
        onKeyPress={ (e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              props.onSearchInputEnterKey();
            }
          }
        }
        fullWidth
        label="Name"
        size="small"
        name="name"
        value={props.name}
        onChange={props.handleInputChange}
      />
    </td>
    <td>
    <TextField
      onKeyPress={ (e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            props.onSearchInputEnterKey();
          }
        }
      }
      fullWidth
      label="Email"
      size="small"
      name="email"
      value={props.email}
      onChange={props.handleInputChange}
    /></td>

    { props.session.roleId === 3 && <td><Select
      fullWidth
      label="Team"
      size="small"
      name="teamId"
      variant="standard"
      margin="dense"
      value={props.teamId}
      onChange={props.handleInputChange}
    >
      <MenuItem value={''}>-- SELECT --</MenuItem>
      {teamsSelect}
    </Select></td> }


    { props.session.roleId === 3 && <td><Select
      fullWidth
      label="Role"
      size="small"
      name="roleId"
      variant="standard"
      margin="dense"
      value={props.roleId}
      onChange={props.handleInputChange}
    >
      <MenuItem value={''}>-- SELECT --</MenuItem>
      {rolesSelect}
    </Select></td> }

    <td>
      <Select
        fullWidth
        label="Active"
        size="small"
        name="active"
        variant="standard"
        margin="dense"
        value={props.active}
        onChange={props.handleInputChange}
      >
        <MenuItem value={''}>-- SELECT --</MenuItem>
        <MenuItem key={'1'} value={'1'}>YES</MenuItem>
        <MenuItem key={'0'} value={'0'}>NO</MenuItem>
      </Select>
    </td>
    <td align="right">
      <button type="button" className="btn btn-link" onClick={props.resetTableSearchInput}>Clear Filters</button>
    </td>
  </tr>

  return result;
}

const ListItem = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    row,
    rowIndex
  } = props;

  let menuItems = null;
  if(props.session.roleId === 3) {
    menuItems = <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.name, 'VIEW'); }} disableRipple>
        <VisibilityIcon />
        View
      </MenuItem>

      {
        props.session.id !== row.id &&
        <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.name, 'EDIT'); }} disableRipple>
          <EditIcon /> Edit
        </MenuItem>
      }

      {
        props.session.id !== row.id &&
        <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.name, 'DELETE'); }} disableRipple>
          { props.deleted ? <><RestoreFromTrashIcon />Restore</> : <><DeleteIcon />Delete</> }
        </MenuItem>
      }


    </Menu>
  } else {
    menuItems = <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.name, 'VIEW'); }} disableRipple>
        <VisibilityIcon />
        View
      </MenuItem>
      <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.name, 'EDIT'); }} disableRipple>
        <EditIcon />
        Edit
      </MenuItem>
      <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.name, 'DELETE'); }} disableRipple>
        { props.deleted ? <><RestoreFromTrashIcon />Restore</> : <><DeleteIcon />Delete</> }
      </MenuItem>
    </Menu>
  }

  return (
      <tr>
      <td>{rowIndex}</td>
      <td>
        <Avatar variant="circle" src={row.googlePicture} sx={{ bgcolor: deepOrange[500] }}>
          {row.name.charAt(0)}
        </Avatar>
      </td>
      <td valign="middle">
        {row.name}
      </td>
      <td valign="middle">{ row.email }</td>
      { props.session.roleId === 3 && <td valign="middle"><CircleIcon style={{ color: row.colorCode }} /> { row.teamName }</td> }
      { props.session.roleId === 3 && <td valign="middle">{ row.roleName }</td> }
      <td valign="middle" align="center">{ row.active ? 'YES' : 'NO' }</td>
      <td valign="middle" align="right">
        <Button
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon /> }
      >
        Action
      </Button>
      {menuItems}
      </td>

    </tr>
  );
}

export const GetTvalues = (props) => {
  let rowIndex = props.t_index;
  const result = props.t_data.map((row) =>
    <ListItem
      rowIndex={++rowIndex}
      key={row.id}
      row={row}
      setOpen={props.setOpen}
      session={props.session}
      deleted={props.deleted}
    />
  )

  return result;
}

import React from 'react';
import { connect } from 'react-redux'
class CustomTimer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentTimeStamp: new Date().toLocaleString("en-US", {
        timeZone: `America/Denver`,
        //timeZoneName: 'short'
      })
    }
  }

  updateCurrentTimeStamp() {
    this.setState({
      currentTimeStamp: new Date().toLocaleString("en-US", {
        timeZone: `America/Denver`
      })
    })
  }

  componentDidMount() {
    this.timerId = setInterval(
      () => this.updateCurrentTimeStamp(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  render() {
    return <>{this.state.currentTimeStamp}</>
  }

}

const mapStateToProps = state => {
  return {
    session: state.session
  }
}

export default connect(
  mapStateToProps,
  null
)(CustomTimer)

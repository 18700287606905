const CryptoJS = require('crypto-js');
const passphrase = 'madwireXe?2e{]>8;yNLRM^u;,V/rK?_Kkp2@rLF.]*U&,,!kn3PaMm6]Salesscreen';

export const encryptWithAES = (text) => {
  try {
    return CryptoJS.AES.encrypt(text, passphrase).toString();
  } catch(error) {
    console.log("encryptTokenError");
    return false;
  }
};

export const decryptWithAES = (ciphertext) => {
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  } catch(error) {
    console.log("decryptTokenError");
    return false;
  }
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from "react-router-dom";
import SideBar from './SideBar';
import Header from './Header';
import LoginForm from '../components/misc/LoginFormContainer';

function PrivateContent({ children }) {
  const session = useSelector((state: RootState) => state.session);
  //console.log("session>",session);
  if(session.isAuthenticated) {
    return children;
  } else {
    //window.location.href = '/users/login';
    return <LoginForm />
  }
}

const Layout = (props) => {

  const session = useSelector((state: RootState) => state.session);
  document.body.className = session.theme === 'dark' ? 'themedark' : '';

  return (
    <>
      <PrivateContent>
        <Header />
        <SideBar />
        <Outlet />
      </PrivateContent>
    </>
  );

}

export default Layout

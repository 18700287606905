import React from 'react';
import Avatar from '@mui/material/Avatar';
const defaultCounterExpireSec = 15;

class ScreenPageTimer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      counter: defaultCounterExpireSec
    }
  }

  updateCounter = () => {
    this.setState((prevState) => {
      if(prevState.counter <= 0) {
        this.props.slidePages();
        return {
          ...prevState,
          counter: defaultCounterExpireSec
        }
      } else {
        return {
          ...prevState,
          counter: prevState.counter - 1
        }
      }
    });
  }

  componentDidMount() {
    this.timerId = setInterval(
      () => this.updateCounter(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  render() {
    //console.log("counter-->",this.state.counter);
    return <Avatar
      className="text-white"
      sx={{
        width: 33,
        height: 33,
        bgcolor: '#808080',
        fontFamily: 'Poppins, sans-serif',
        marginTop: '2px'
      }}>
      <span style={{ fontSize: '0.7em' }}>{this.state.counter}</span>
    </Avatar>
  }

}


export default ScreenPageTimer;

import axios from 'axios';
import getAppConfig from '../../config';
import { setGlobalLoader } from '../ui/uiActions';
import { setSnackBarAlert, caughtError } from '../ui/uiActions';
import { getQueryString } from '../../components/common/functions';

import {
  WEBHOOK_SET_INITIAL_STATE,
  WEBHOOK_FIND_ALL,
  WEBHOOK_VIEW
} from './webhookTypes';

const config = getAppConfig();

export const viewWebhook = (webhookId) => {
  let URL = `${config.api.URL}/webhook/${webhookId}`;
  return (dispatch) => {
    dispatch(onSuccessViewWebhook({data: false}))
    axios.get(URL).then(response => {
      dispatch(onSuccessViewWebhook(response))
    }).catch(error => {
      dispatch(caughtError(error))
    })
  }
}

export const deleteWebhook = (webhookId, filterInput) => {
  let URL = `${config.api.URL}/webhook/mark-delete/${webhookId}`;
  if(filterInput.deleted === true) {
    // if record already deleted
    URL = `${config.api.URL}/webhook/unmark-delete/${webhookId}`;
  }
  return (dispatch) => {
    axios.patch(URL).then(response => {

      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'success',
        message: `Success! webhook ${filterInput.deleted ? 'restored' : ' moved to trash'}`
      }))

      dispatch(findAllWebhooks(filterInput));
    }).catch(error => {
      dispatch(caughtError(error))
    })
  }
}

export const findAllWebhooks  = (filters="") => {
  let URL = `${config.api.URL}/webhook`;
  if(filters !== '') {
    URL = `${URL}?${getQueryString(filters)}`;
  }
  return (dispatch) => {
    dispatch(setGlobalLoader(true))
    axios.get(URL).then(response => {
      dispatch(setGlobalLoader(false))
      dispatch(onSuccessFindAllWebhook(response))
    }).catch(error => {
      dispatch(setGlobalLoader(false))
      dispatch(setWebhookInitialState())
      dispatch(caughtError(error))
    })
  }
}
export const setWebhookInitialState = () => {
  return {
    type: WEBHOOK_SET_INITIAL_STATE
  }
}

export const onSuccessViewWebhook = response => {
  return {
    type: WEBHOOK_VIEW,
    payload: response.data
  }
}

export const onSuccessFindAllWebhook = response => {
  return {
    type: WEBHOOK_FIND_ALL,
    payload: response.data
  }
}

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { viewTeamGroup } from '../../redux';

const ViewDetails = (props) => {

  const viewResult = useSelector((state) => state.teamGroup.viewResult);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if(loading && props.modalDiaLogId && props.modalDiaLogId > 0) {
      dispatch(viewTeamGroup(props.modalDiaLogId));
      setLoading(false);
    }
  }, [props, loading, viewResult, dispatch]);


  const details = (
    loading === false && viewResult ?
    <ul className="list-group">
    <li className="list-group-item"><strong className="p-2">TeamGroupId:</strong> {viewResult.id}</li>
    <li className="list-group-item"><strong className="p-2">TeamGroupName:</strong> {viewResult.groupName}</li>
    <li className="list-group-item"><strong className="p-2">ShortCode:</strong> {viewResult.shortCode}</li>
    <li className="list-group-item"><strong className="p-2">Active:</strong> {viewResult.active ? "YES" : "NO"}</li>
  </ul> : <CircularProgress /> )

  return (
    <>
    <DialogContent sx={{ marginTop: "-10px" }} >
      { details }
    </DialogContent>
    <DialogActions>
      <button className="btn btn-secondary" onClick={ () => props.setOpen(false) } >Close</button>
    </DialogActions>
    </>
  )

}

export default ViewDetails;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { setSnackBarAlert, caughtError } from '../../redux';
import axios from 'axios';
import getAppConfig from '../../config';
const config = getAppConfig();

const LogoForm = (props) => {
  const [industryPicture, setIndustryPicture] = useState('');
  const [industryPictureUrl, setIndustryPictureUrl] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const onImageChange = event => {
   if (event.target.files && event.target.files[0]) {
     let file = event.target.files[0];
     setImage(URL.createObjectURL(file));

     let formData = new FormData();
     formData.append("file", file);
     setLoading(true);
     axios.put(`${config.api.URL}/industry/logo/${props.modalDiaLogId}`, formData).then(apiResponse => {
       const response = apiResponse.data;
       setLoading(false);
       dispatch(setSnackBarAlert({
         openSnackBar: true,
         severity: 'success',
         message: `Success! ${response.message}`
       }));

       props.setOpen(false);
     }).catch(error => {
       console.log(error);
       setLoading(false);
       dispatch(caughtError(error));
     });

   }

  };

  useEffect(() => {
    if(loading && props.modalDiaLogId && props.modalDiaLogId > 0) {
      let URL = `${config.api.URL}/industry/${props.modalDiaLogId}`;
      axios.get(URL).then(apiResponse => {
        const response = apiResponse.data;
        setIndustryPicture(response.data.industryPicture);
        setIndustryPictureUrl(response.data.industryPictureUrl);
      }).catch(error => {
        console.log(error);
      })
    }
    setLoading(false)
  }, [loading, props]);


  let industryLogo = '';
  if(industryPicture !== '' && industryPicture !== null) {
    industryLogo = <p><img className="float-end" src={industryPictureUrl} alt="" /></p>
  }

  return (
    <form>
      <DialogContent sx={{ marginTop: "-20px" }} >

        <div className="row">
          <div className="col">
            { image && <img src={image} alt="industry logo" style={{ maxHeight: '100px' }} /> }
            <input type="file" onChange={onImageChange} accept="image/png, image/jpeg" /><br/>
            {loading && <CircularProgress className="mt-4" />}
          </div>
          <div className="col">
            {industryLogo}
          </div>
        </div>


      </DialogContent>
      <DialogActions>
        <button className="btn btn-secondary" onClick={ (e) => {
            e.preventDefault();
            props.setOpen(false);
          } }>Close</button>
      </DialogActions>
    </form>
  )

}

export default LogoForm;

import {
  MILESTONE_TYPE_FIND_ALL,
  MILESTONE_TYPE_SET_INITIAL_STATE,
  MILESTONE_TYPE_VIEW
} from './milestoneTypeTypes'


const initialState = {
  data: [],
  pagination: {
    currentpage: 1,
    itemcount: 0,
    pagesize: 10,
    firstpage: '',
    lastpage: '',
    nextpages: [],
    prevpages: [],
    totalpages: 0
  },

  viewResult: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MILESTONE_TYPE_SET_INITIAL_STATE:
      return initialState;
    case MILESTONE_TYPE_FIND_ALL:
      return {
        data: action.payload.data,
        pagination: action.payload.pagination
      }
    case MILESTONE_TYPE_VIEW:
    return {
      ...state,
      viewResult: action.payload.data
    }
    default: return state
  }
}

export default reducer

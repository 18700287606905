import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const ToggleTableTrash = (props) => {

  return <>
    <FormGroup>
      <FormControlLabel
        control={<Switch 
        name="deleted"
        checked={props.deleted}
        onChange={props.handleInputChange} />}
        label="Trash"
        labelPlacement="start" />
    </FormGroup>
  </>

}

export default ToggleTableTrash;

import React from 'react';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import { useSelector } from 'react-redux';

const IndividualTopCollector = (props) => {

  const salesscreen = useSelector((state) => state.screen);

  const {
    Last30DaysTopMembers
  } = salesscreen;

  let _content = null;
  if(Last30DaysTopMembers.length > 0) {
    _content = Last30DaysTopMembers.map( (row) =>
    <tr key={row.name} value={row.name}>
      <td>
        <Avatar
          variant="circle"
          src={row.googlePicture}
          sx={{
            width: 40, height: 40,
            bgcolor: deepOrange[500]
          }}>
          {row.name.charAt(0)}
        </Avatar>
      </td>
      <td valign="middle">{ row.name }</td>
      <td valign="middle">{ `$${row.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` }</td>
    </tr>
  )
  }

  if(_content !== null) {
    _content = <table className="table table-striped">
      <tbody>
        { _content }
      </tbody>
    </table>
  }

  return <>
    <h5 className="card-title" style={{ marginTop: '-15px', marginBottom: '-5px' }}>Top 5 By Individual - Last 30 Days</h5>
    { _content !== null ? _content : <p>No data found</p> }

  </>

}

export default IndividualTopCollector;

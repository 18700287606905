import ReactAudioPlayer from 'react-audio-player';

const saleTune = "https://salesscreen.s3.us-east-2.amazonaws.com/assets/tunes/ching.mp3";


function isFloat(n){
    return Number(n) === n && n % 1 !== 0;
}

const NewSale = (props) => {

  const postDetails = props?.postDetails;
  const mute = props?.mute;
  const totalCharLength = postDetails.industryName.length;

  let maxWidth = '15vw'
  if(totalCharLength > 15 && totalCharLength <= 25) {
    maxWidth = '20vw';
  } else if(totalCharLength > 25) {
    maxWidth = '25vw';
  }

  let formattedAmount = `$${postDetails.amount}`;
  if(isFloat(postDetails.amount)) {
    formattedAmount = `$${postDetails.amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`;
  }

  if(postDetails) {
    return <>
        <h2 className="sale-amount">
          {formattedAmount}
        </h2>

        <h2 className="text-center post-type-box" style={{ maxWidth: maxWidth }}>
          {
            postDetails.industryPictureUrl && <img
            style={{ marginRight: '10px' }}
            alt={postDetails.industryName}
            src={postDetails.industryPictureUrl}
            height="30"/>
        }
          {postDetails.industryName}
        </h2>

        { !mute && <ReactAudioPlayer
        style={{ display: 'none' }}
        src={saleTune}
        autoPlay
        controls /> }
  </>
  } else {
    return null;
  }

}

export default NewSale;

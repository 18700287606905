import { ConvertUrlToLink } from '../../common/functions';

const PostDescription = (props) => {
  const totalCharLength = props.description.length;
  let width = "75%";
  let fontSize = '1.8vw';

  if(totalCharLength > 150 && totalCharLength <= 180) {
    width = "90%";
    fontSize = '1.7vw';
  } else if(totalCharLength > 180) {
    width = "90%";
    fontSize = '1.6vw';
  }

  return <div className="post-description" style={{ marginTop: '-0.8vw', fontSize: fontSize, width: width }}>
    <ConvertUrlToLink text={props.description} />
  </div>
}

export default PostDescription;

import { CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TextField from '@mui/material/TextField';

function getHeaderColums(props, roleId) {
  let returnResult = null;
  switch(roleId) {
    case 3:
    returnResult = <tr>
      <th scope="col" style={{ width: '5%' }}>{ props.openGlobalLoader ? <CircularProgress size={'1rem'} /> : '#' }</th>
      <th scope="col" style={{ width: '15%' }}>AddedBy</th>
      <th scope="col" style={{ width: '30%' }}>Title</th>
      <th scope="col" style={{ width: '10%' }}>NSFW</th>
      <th scope="col" style={{ width: '13%' }}>YoutubeId</th>
      <th scope="col" style={{ width: '12%', textAlign: 'center' }}>startFrom<br/>(In Sec)</th>
      <td align="right" style={{ width: '15%' }}><strong style={{ marginRight: '12%' }}>Action</strong></td>
    </tr>
    break;
    default:
    returnResult = <tr>
      <th scope="col" style={{ width: '5%' }}>{ props.openGlobalLoader ? <CircularProgress size={'1rem'} /> : '#' }</th>
      <th scope="col" style={{ width: '45%' }}>Title</th>
      <th scope="col" style={{ width: '10%' }}>NSFW</th>
      <th scope="col" style={{ width: '13%' }}>YoutubeId</th>
      <th scope="col" style={{ width: '12%', textAlign: 'center' }}>startFrom<br/>(In Sec)</th>
      <td align="right" style={{ width: '15%' }}><strong style={{ marginRight: '12%' }}>Action</strong></td>
    </tr>
    break;
  }
  return returnResult;
}

export const GetTcolumns = (props) => {
  const result = getHeaderColums(props,props.session.roleId);
  return result;
}

export const GetTsearch = (props) => {
  const result = <tr>
    <td></td>
      { props.session.roleId === 3 &&  <td>
        <TextField
          onKeyPress={ (e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                props.onSearchInputEnterKey();
              }
            }
          }
          label="AddedBy"
          size="small"
          name="addedBy"
          value={props.addedBy}
          onChange={props.handleInputChange}
        />
    </td> }
    <td>
      <TextField
        onKeyPress={ (e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              props.onSearchInputEnterKey();
            }
          }
        }
        label="Title"
        size="small"
        name="title"
        value={props.title}
        onChange={props.handleInputChange}
      />
  </td>
    <td></td>
    <td>
      <TextField
        onKeyPress={ (e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              props.onSearchInputEnterKey();
            }
          }
        }
        label="YoutubeId"
        size="small"
        name="youtubeId"
        value={props.youtubeId}
        onChange={props.handleInputChange}
      />
    </td>
    <td></td>
    <td align="right">
      <button type="button" className="btn btn-link" onClick={props.resetTableSearchInput}>Clear Filters</button>
    </td>
  </tr>

  return result;
}

export const GetTvalues = (props) => {
  let t_index = props.t_index;
  const result = props.t_data.map((row) =>
    <tr key={row.id}>
      <td>{ ++t_index }</td>
      { props.session.roleId === 3 && <td>{ row.createdBy }</td> }
      <td>{ row.title }</td>
      <td>{ row.nsfw ? 'YES' : 'NO' }</td>
      <td>{ row.youtubeId }</td>
      <td align="center">{ row.youtubeIdStart }</td>
      <td>
        <div className="float-end" style={{ marginRight: '15%' }}>
          <VisibilityIcon fontSize="small" color="primary" onClick={ (e) => props.setOpen(true, row.id, `${row.title} - ${row.createdBy}`, 'VIEW') } /> {' '}
          <EditIcon fontSize="small" color="primary" onClick={ (e) => props.setOpen(true, row.id, row.title, 'EDIT') } /> {' '}
          { props.deleted ? <RestoreFromTrashIcon fontSize="small" color="primary" onClick={ (e) => props.setOpen(true, row.id, `${row.title} - ${row.createdBy}`, 'DELETE') } /> : <DeleteIcon fontSize="small" color="primary" onClick={ (e) => props.setOpen(true, row.id, `${row.title} - ${row.createdBy}`, 'DELETE') } /> }
        </div>
      </td>
    </tr>
  )

  return result;
}

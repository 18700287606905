import React from 'react';
import PageTitleContainer from '../common/PageTitleContainer';
import { useSelector } from 'react-redux';
import Overview from './Overview';
import EditProfile from './EditProfile';
import TeamSettings from './TeamSettings';

const ProfileContainer = (props) => {
  const profile = useSelector((state: RootState) => state.profile);
  const session = useSelector((state: RootState) => state.session);

  return (
    <main id="main" className="main">
      <PageTitleContainer pageTitle={props.pageTitle} />
      <section className="section profile">
        <div className="row">
          <div className="col-xl-12">

            <div className="card">
              <div className="card-body pt-3">

                <ul className="nav nav-tabs nav-tabs-bordered">

                  <li className="nav-item">
                    <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Overview</button>
                  </li>

                  <li className="nav-item">
                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit">Edit Profile</button>
                  </li>

                  { [2,3].includes(session.roleId) && <li className="nav-item">
                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-team-setting">My Team</button>
                  </li> }

                </ul>
                <div className="tab-content pt-2">

                  <div className="tab-pane fade show active profile-overview" id="profile-overview">
                    <Overview profile={profile} session={session}/>
                  </div>

                  <div className="tab-pane fade profile-edit pt-3" id="profile-edit">
                    <EditProfile profile={profile} session={session}/>
                  </div>

                  { [2,3].includes(session.roleId) && <div className="tab-pane fade pt-3" id="profile-team-setting">
                    <TeamSettings />
                  </div> }


                </div>

              </div>
            </div>

          </div>
        </div>
      </section>

    </main>
  );
}

export default ProfileContainer;

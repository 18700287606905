import React from 'react';
import { CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Divider from '@mui/material/Divider';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';

export const GetTcolumns = (props) => {
  const result = <tr>
    <th scope="col" style={{ width: '5%' }} >{ props.openGlobalLoader ? <CircularProgress size={'1rem'} /> : '#' }</th>
    <th scope="col" style={{ width: '10%' }} >Logo</th>
    <th scope="col" style={{ width: '20%' }} >Team</th>
    <th scope="col" style={{ textAlign: 'center', width: '10%' }}>TotalMembers</th>
    <th scope="col" style={{ textAlign: 'center', width: '25%' }} >Group</th>
    <th scope="col" style={{ width: '10%' }} >ColorCode</th>
    <th scope="col" style={{ textAlign: 'center', width: '10%' }}>Active</th>
    <td align="right"><strong style={{ width: '10%', marginRight: '20%' }}>Action</strong></td>
  </tr>

  return result;
}

export const GetTsearch = (props) => {

  const teamGroupData = props.teamGroup.data;
  let teamGroupSelect = '';
  if(teamGroupData.length > 0) {
    teamGroupSelect = teamGroupData.map( (row) => <MenuItem key={row.id} value={row.id}>{row.groupName}</MenuItem>)
  }

  const result = <tr>
    <td></td>
    <td></td>
    <td>
      <TextField
        onKeyPress={ (e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              props.onSearchInputEnterKey();
            }
          }
        }
        fullWidth
        label="Team"
        size="small"
        name="teamName"
        value={props.teamName}
        onChange={props.handleInputChange}
      />
    </td>
    <td></td>
      <td><Select
        fullWidth
        label="Group"
        size="small"
        name="groupId"
        variant="standard"
        margin="dense"
        value={props.groupId}
        onChange={props.handleInputChange}
      >
        <MenuItem value={''}>-- SELECT --</MenuItem>
        {teamGroupSelect}
      </Select></td>
    <td></td>
    <td>
      <Select
        fullWidth
        label="Active"
        size="small"
        name="active"
        variant="standard"
        margin="dense"
        value={props.active}
        onChange={props.handleInputChange}
      >
        <MenuItem value={''}>-- SELECT --</MenuItem>
        <MenuItem key={'1'} value={'1'}>YES</MenuItem>
        <MenuItem key={'0'} value={'0'}>NO</MenuItem>
      </Select>
    </td>
    <td align="right">
      <button type="button" className="btn btn-link" onClick={props.resetTableSearchInput}>Clear Filters</button>
    </td>
  </tr>

  return result;
}

const ListItem = (props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    row,
    rowIndex
  } = props
  return (
      <tr>
      <td valign="middle">{rowIndex}</td>
      <td valign="middle">
        {
          row.logoFileName ? <img src={row.logoResizedUrl} alt="" style={{ width: '48px' }}  /> :
          <div className="mx-1"><CircleIcon style={{ color: row.colorCode }} fontSize="large" /></div>
        }
      </td>
      <td valign="middle" >{row.teamName}</td>
      <td valign="middle" align="center" >{ row.totalMembers }</td>
      <td valign="middle" >{ row.groupName }</td>
      <td align="center" valign="middle"><CircleIcon style={{ color: row.colorCode }} fontSize="small" /></td>
      <td align="center" valign="middle">{ row.active ? 'YES' : 'NO' }</td>
      <td align="right" valign="middle">
        <Button
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Action
      </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
        <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.teamName, 'VIEW'); }} disableRipple>
          <VisibilityIcon />
          View
        </MenuItem>
        <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.teamName, 'EDIT'); }} disableRipple>
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.teamName, 'DELETE'); }} disableRipple>
          { props.deleted ? <><RestoreFromTrashIcon />Restore</> : <><DeleteIcon />Delete</> }
        </MenuItem>
        <Divider />
        <MenuItem onClick={(e) => { handleClose(); props.setOpen(true, row.id, row.teamName, 'LOGO'); }} disableRipple>
          <UploadFileIcon />
          Logo
        </MenuItem>
        </Menu>
      </td>

    </tr>
  );
}

export const GetTvalues = (props) => {
  let rowIndex = props.t_index;
  const result = props.t_data.map((row) =>
    <ListItem
      rowIndex={++rowIndex}
      key={row.id}
      row={row}
      setOpen={props.setOpen}
      deleted={props.deleted}
    />
  )

  return result;
}

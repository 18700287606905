import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { decryptWithAES } from '../common/CryptoServices';
import TopLogo from './TopLogo';
import { caughtError, onLoginSuccess } from '../../redux';
import getAppConfig from '../../config';
const config = getAppConfig();

const LoginFormContainer = (props) => {

  const session = useSelector((state) => state.session);
  const [loading, setLoading] = useState(true);
  const [showGmailBtn, setShowGmailBtn] = useState(true);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const redirectToGoogleOauthUrl = (event) => {
    setShowGmailBtn(false);
    event.preventDefault();
    axios.get(`${config.api.URL}/google/oauth-url`).then(response => {
      window.location.href = response.data.url;
    }).catch(error => {
      setShowGmailBtn(true);
      dispatch(caughtError(error));
    })
  }

  const iscookiectokenactive = useCallback(() => {
    const encrypted_cookie_ctoken = window.localStorage.getItem("cookie_ctoken");
    if(encrypted_cookie_ctoken) {
      const cookie_ctoken = decryptWithAES(encrypted_cookie_ctoken);
      if(cookie_ctoken) {
        axios.post(`${config.api.URL}/google/iscookiectokenactive`, {
          cookie_ctoken: cookie_ctoken
        }).then(response => {
          dispatch(onLoginSuccess(response));
        }).catch(error => {
          console.log("error>",error);
          console.log("user previous not active");
        })
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if(session.isAuthenticated) {
      navigate(`/`);
    } else {
      iscookiectokenactive();
    }
    setLoading(false);
  }, [loading, navigate, session, iscookiectokenactive]);

  let _content = null;
  if(props?.showOnlyLoginButton && props.showOnlyLoginButton === true && showGmailBtn) {
    _content = <img style={{ cursor: 'pointer' }} onClick={redirectToGoogleOauthUrl} src="/assets/img/google_signin.png" alt="" />
  } else {
    _content = <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center py-4">
          <div className="container">

            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                <br/> <br/> <br/> <br/> <br/> <br/> <TopLogo />
                { showGmailBtn && <img style={{ cursor: 'pointer' }} onClick={redirectToGoogleOauthUrl} src="/assets/img/google_signin.png" alt="" /> }

              </div>
            </div>
          </div>

        </section>
      </div>
    </main>
  }
  return (_content);

}

export default LoginFormContainer;

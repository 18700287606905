import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSnackBarAlert,
  setScreenVpnStatus,
  callScreenPageServices,
  callDashboardPageServices,
  addScreenAlertItem
} from '../../redux';

import { socket } from './socket';

import getAppConfig from '../../config';
const config = getAppConfig();

/** Data refreshed only on new event **/
const ListenSocketEvents = (props) => {

  const session = useSelector((state) => state.session);
  const salesscreen = useSelector((state) => state.screen);
  const dispatch = useDispatch();

  const reloadScreenPage = () => {
    if(window.location.pathname === '/screen') {
      window.location.reload(true);
    }
  }

  const refreshDashboardPage = useCallback((postType = null) => {
    if(window.location.pathname === '/' &&
    postType === 'sale' &&
    session.isAuthenticated) {
      console.log(`${postType} ---> refresh dashboard page!!!`);
      dispatch(callDashboardPageServices());
    }
  }, [dispatch, session]);

  const refreshScreenPage = useCallback((postType = null) => {
    if(window.location.pathname === '/screen' &&
    (session.isAuthenticated ||
    salesscreen.connectedToVPN)) {
      console.log(`${postType} ---> refresh screen page!!!`);
      dispatch(callScreenPageServices());
    }
  }, [dispatch, session, salesscreen]);

  const handleNotificationMessages = useCallback((data) => {
    if(window.location.pathname !== '/screen' && window.location.pathname !== '/users/login') {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'success',
        message: `${data.message}`
      }));
    }
  }, [dispatch]);

  const onDisconnectUpdateRealTimePages = useCallback((data) => {
    // refresh screen page
    refreshScreenPage();

    // refresh dashboard page
    if(window.location.pathname === '/' &&
    session.isAuthenticated) {
      dispatch(callDashboardPageServices());
    }

    if(!session.isAuthenticated) {
      /** call connectedToVPN service only when user not logged in **/
      dispatch(setScreenVpnStatus(false));
    }
  }, [dispatch, session, refreshScreenPage]);

  const _addScreenAlertItem = useCallback((_newPost) => {
    //console.log("_newPost--->",_newPost);
    const postType = _newPost.postType;

    setTimeout(() => {
      if(window.location.pathname === '/screen') {
        dispatch(addScreenAlertItem(_newPost));
      }
      refreshScreenPage(postType);
      refreshDashboardPage(postType);
    }, 500);
  }, [dispatch, refreshDashboardPage, refreshScreenPage]);

  useEffect(() => {

    socket.on("connect",() => {
      console.log("success! connected to socket server...");
    });

    socket.on("disconnect", (reason) => {
      console.log("disconnected socket connection...", reason);
      onDisconnectUpdateRealTimePages();
    });

    socket.on(`${config.socket.eventPrefix}-NEW-SALE`, _addScreenAlertItem);
    socket.on(`${config.socket.eventPrefix}-NEW-MILESTONE`, _addScreenAlertItem);

    socket.on(`${config.socket.eventPrefix}-DELETE-SALE`, () => {
      refreshScreenPage('sale');
      refreshDashboardPage('sale');
    });

    socket.on(`${config.socket.eventPrefix}-DELETE-MILESTONE`, () => {
      refreshScreenPage('milestone');
    });

    socket.on(`${config.socket.eventPrefix}-NEW-USER`, handleNotificationMessages);
    socket.on(`${config.socket.eventPrefix}-USER-LOGGED-IN`, handleNotificationMessages);
    socket.on(`${config.socket.eventPrefix}-RELOAD-SCREEN`, reloadScreenPage);

    return () => {
      console.log("socket connection cleaned up...");
      socket.off('connect');
      socket.off('disconnect');
      socket.off('reconnect');
      socket.off(`${config.socket.eventPrefix}-NEW-SALE`);
      socket.off(`${config.socket.eventPrefix}-NEW-MILESTONE`);
      socket.off(`${config.socket.eventPrefix}-DELETE-SALE`);
      socket.off(`${config.socket.eventPrefix}-DELETE-MILESTONE`);
      socket.off(`${config.socket.eventPrefix}-NEW-USER`);
      socket.off(`${config.socket.eventPrefix}-USER-LOGGED-IN`);
      socket.off(`${config.socket.eventPrefix}-RELOAD-SCREEN`);
    };

  }, [
    refreshScreenPage,
    refreshDashboardPage,
    onDisconnectUpdateRealTimePages,
    _addScreenAlertItem,
    handleNotificationMessages
  ]);

  return null;
}


export default ListenSocketEvents;

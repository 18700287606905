import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmDialog = (props) => {
  const { children, open, setOpen, onConfirm } = props;
  return (
    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="confirm-dialog">
      <DialogTitle>
        <span className="card-title" style={{ marginBottom: '-10px' }}>
          { props.title ? props.title : '' }
        </span>
      </DialogTitle>
      <DialogContent sx={{ marginTop: "-20px" }} >{children}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setOpen(false)} color="secondary">No</Button>
        <Button variant="contained" onClick={() => { setOpen(false); onConfirm('YES'); }} color="primary">Yes</Button>
      </DialogActions>
    </Dialog>
  )
}
export default ConfirmDialog;

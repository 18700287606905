import React from 'react';
import { useSelector } from 'react-redux';
import ScreenTodayUserSales from './pages/ScreenTodayUserSales';  // 1
import ScreenTodayTeamAndGroupStats from './pages/ScreenTodayTeamAndGroupStats';  // 2
import ScreenCurrentMonthStats from './pages/ScreenCurrentMonthStats'; // 3
import ExtraScreen from './pages/ExtraScreen'; // 4,5,6 etc...

const defaultPagesCount = 3;

const ScreenPages = (props) => {

  const salesscreen = useSelector((state) => state.screen);
  const {
    page,
    subscribedPages
  } = props;

  let _countP = defaultPagesCount;
  let _subscribedScreens = '';

  /** main common screen pages **/
  const _mainScreens = <>

      <div className={page===1 ? 'row flipscreen on' : 'row flipscreen off'} >
        <ScreenTodayUserSales salesscreen={salesscreen} />
      </div>


      <div className={page===2 ? 'row flipscreen on' : 'row flipscreen off'} >
        <ScreenTodayTeamAndGroupStats  salesscreen={salesscreen} />
      </div>

      <div className={page===3 ? 'row flipscreen on' : 'row flipscreen off'} >
        <ScreenCurrentMonthStats salesscreen={salesscreen} />
      </div>

  </>

  /** subscribed screens pages **/
  if(subscribedPages.length > 0) {
    _subscribedScreens = subscribedPages.map((_row) =>{
      _countP++;
      return <div key={_countP} className={page===_countP ? 'row flipscreen on' : 'row flipscreen off'} >
        <ExtraScreen key={_countP} screenDetails={_row} />
      </div>
    })
  }

  return (
    <div className="py-2">
      <div id="mescreen">
        <div className="flip-container">
          {_mainScreens}
          {_subscribedScreens}
        </div>
      </div>
    </div>
  )

}

export default ScreenPages;

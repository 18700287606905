import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import sortByFields from './sortByFields';

const SortTable = (props) => {

  if(props.sortName) {
    const sortByFieldsMenuItems = sortByFields[props.sortName].map((row) => <MenuItem key={row.value} value={row.value}>{row.displayName}</MenuItem> )

    const result = <>
      <FormControl sx={{ m: 1, minWidth: 150 }}>
        <InputLabel>Sort</InputLabel>
        <Select
          fullWidth
          label="Sort"
          size="small"
          name="sort"
          variant="outlined"
          margin="dense"
          value={props.sort}
          onChange={props.handleInputChange}
        >
          {sortByFieldsMenuItems}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 150 }}>
        <InputLabel>Direction</InputLabel>
        <Select
          fullWidth
          label="Direction"
          size="small"
          name="direction"
          variant="outlined"
          margin="dense"
          value={props.direction}
          onChange={props.handleInputChange}
        >
          <MenuItem key={'ASC'} value={'ASC'}>Ascending</MenuItem>
          <MenuItem key={'DESC'} value={'DESC'}>Descending</MenuItem>
        </Select>
      </FormControl>
    </>

    return result;
  } else {
    return <></>;
  }

}

export default SortTable;

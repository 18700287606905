import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircleIcon from '@mui/icons-material/Circle';
import { viewMilestone } from '../../redux';

const ViewDetails = (props) => {

  const viewResult = useSelector((state) => state.milestone.viewResult);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if(loading && props.modalDiaLogId && props.modalDiaLogId > 0) {
      dispatch(viewMilestone(props.modalDiaLogId));
      setLoading(false);
    }
  }, [props, loading, viewResult, dispatch]);


  const details = (
    loading === false && viewResult ?
    <>
    <div className="row">
      <div className="col-12">
        <ul className="list-group">
        <li className="list-group-item"><strong className="p-2">MilestoneId:</strong> {viewResult.id}</li>
        <li className="list-group-item"><strong className="p-2">TeamName:</strong> <CircleIcon style={{ color: viewResult.colorCode }} /> {viewResult.teamName}</li>
        <li className="list-group-item"><strong className="p-2">PostedBy:</strong> {viewResult.postedBy}</li>
        <li className="list-group-item"><strong className="p-2">MilestoneType:</strong> {viewResult.milestoneTypeName}</li>
        <li className="list-group-item"><strong className="p-2">Description:</strong> {viewResult.description}</li>
        <li className="list-group-item"><strong className="p-2">CreatedAt:</strong> {viewResult.createdAt}</li>
      </ul>
      </div>
    </div>
    </> : <CircularProgress /> )

  return (
    <>
    <DialogContent sx={{ marginTop: "-10px" }} >
      { details }
    </DialogContent>
    <DialogActions>
      <button className="btn btn-secondary" onClick={ () => props.setOpen(false) } >Close</button>
    </DialogActions>
    </>
  )

}


export default ViewDetails;

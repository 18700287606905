import React from 'react';
import { useSelector } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

const graphTitle = 'Total Industry Sales';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const _options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: false,
      text: '',
    },
  },
  scales: {
      y: {
          ticks: {
              // Include a dollar sign in the ticks
              callback: function(value, index, ticks) {
                  return '$' + value;
              }
          }
      }
  }
};

const SalesAmountByIndustries = (props) => {

    const salesscreen = useSelector((state) => state.screen);
    const industrySales = salesscreen.industrySales;

    let _labels = []; let _data = [];
    for(const row of industrySales) {
      _labels.push(row.industryName);
      _data.push(row.amount);
    }

    const data = {
      labels: _labels,
      datasets: [
        {
          label: graphTitle,
          data: _data,
          backgroundColor: props.rgba ? props.rgba : 'rgba(119, 93, 204, 0.4)'
        }
      ],
    }

    return <>
    <h5 className="card-title"
      style={{ marginTop: '-15px', marginBottom: '-5px' }}>
      {graphTitle}
    </h5>
    {
      data.labels.length > 0 ? <Bar options={_options} data={data} /> : <p>No data found</p>
    }
    </>
}


export default SalesAmountByIndustries;

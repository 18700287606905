import axios from 'axios';
import getAppConfig from '../../config';
import { setGlobalLoader } from '../ui/uiActions';
import { setSnackBarAlert, caughtError } from '../ui/uiActions';
import { getQueryString } from '../../components/common/functions';

import {
  MILESTONE_TYPE_SET_INITIAL_STATE,
  MILESTONE_TYPE_FIND_ALL,
  MILESTONE_TYPE_VIEW
} from './milestoneTypeTypes';

const config = getAppConfig();

export const viewMilestoneType = (milestoneTypeId) => {
  let URL = `${config.api.URL}/milestone-type/${milestoneTypeId}`;
  return (dispatch) => {
    dispatch(onSuccessViewMilestoneType({data: false}))
    axios.get(URL).then(response => {
      dispatch(onSuccessViewMilestoneType(response))
    }).catch(error => {
      dispatch(caughtError(error))
    })
  }
}

export const deleteMilestoneType = (milestoneTypeId, filterInput) => {
  let URL = `${config.api.URL}/milestone-type/mark-delete/${milestoneTypeId}`;
  if(filterInput.deleted === true) {
    // if record already deleted
    URL = `${config.api.URL}/milestone-type/unmark-delete/${milestoneTypeId}`;
  }
  return (dispatch) => {
    axios.patch(URL).then(response => {

      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'success',
        message: `Success! milestoneType ${filterInput.deleted ? 'restored' : ' moved to trash'}`
      }))

      dispatch(findAllMilestoneTypes(filterInput));
    }).catch(error => {
      dispatch(caughtError(error))
    })
  }
}

export const findAllMilestoneTypes  = (filters="") => {
  let URL = `${config.api.URL}/milestone-type`;
  if(filters !== '') {
    URL = `${URL}?${getQueryString(filters)}`;
  }
  return (dispatch) => {
    dispatch(setGlobalLoader(true))
    axios.get(URL).then(response => {
      dispatch(setGlobalLoader(false))
      dispatch(onSuccessFindAllMilestoneType(response))
    }).catch(error => {
      dispatch(setGlobalLoader(false))
      dispatch(setMilestoneTypeInitialState())
      dispatch(caughtError(error))
    })
  }
}
export const setMilestoneTypeInitialState = () => {
  return {
    type: MILESTONE_TYPE_SET_INITIAL_STATE
  }
}

export const onSuccessViewMilestoneType = response => {
  return {
    type: MILESTONE_TYPE_VIEW,
    payload: response.data
  }
}

export const onSuccessFindAllMilestoneType = response => {
  return {
    type: MILESTONE_TYPE_FIND_ALL,
    payload: response.data
  }
}

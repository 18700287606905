export default function getAppConfig() {

  switch (process.env.REACT_APP_ENV_MODE) {
    case 'local':
      return {
        ENV: process.env.REACT_APP_ENV_MODE,
        app: {
          URL: process.env.REACT_APP_APP_URL || 'http://localhost:20501',
        },
        api: {
          URL: process.env.REACT_APP_API_URL || 'http://localhost:8081/api/v1',
          HOST: 'http://localhost:8081'
        },
        socket: {
          eventPrefix: 'LOCAL'
        }
      }
    case 'stage':
      return {
      ENV: process.env.REACT_APP_ENV_MODE,
      app: {
        URL: 'https://salesscreen-frontend.madwire.network',
      },
      api: {
        URL: 'https://salesscreen-backend.madwire.network/api/v1',
        HOST: 'https://salesscreen-backend.madwire.network'
      },
      socket: {
        eventPrefix: 'STAGE'
      }
    }
    default:
      return {
      ENV: process.env.REACT_APP_ENV_MODE,
      app: {
        URL: 'http://salesscreen.madwire.net',
      },
      api: {
        URL: 'https://salesscreen-backend.madwire.net/api/v1',
        HOST: 'https://salesscreen-backend.madwire.net'
      },
      socket: {
        eventPrefix: 'PRODUCTION'
      }
    }
  }
}

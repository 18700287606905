import React, { useState, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import { setSnackBarAlert, findAllYoutubeLibrarys, caughtError } from '../../redux';
import getAppConfig from '../../config';
const config = getAppConfig();

const initialState = {
   title: '',
   youtubeId: '',
   youtubeIdStart: 0,
   nsfw: false,
}

const reducer = (state, action) => {
  return {
  ...state,
  ...action
  }
}

const CreateForm = (props) => {

  const [state, updateState] = useReducer(reducer, initialState);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    if(event.target.type === 'checkbox') {
      updateState({ [event.target.name]: event.target.checked });
    } else {
      updateState({ [event.target.name]: event.target.value });
    }
  }

  const handleSubmit = () => {

    const input = {
      title: state.title,
      youtubeId: state.youtubeId,
      youtubeIdStart: parseInt(state.youtubeIdStart),
      youtubeIdEnd: parseInt(state.youtubeIdStart) + 30,
      nsfw: state.nsfw
    }

    if(input.youtubeId === '') {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `YoutubeId is required`
      }));
      return false;
    }

    if(input.youtubeId !== '' && input.youtubeId.length !== 11) {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `YoutubeId length must equal to 11`
      }));
      return false;
    }

    if(input.youtubeIdStart === '') {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `youtubeId Start Second is required`
      }));
      return false;
    }

    if(input.youtubeIdStart < 0) {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `youtubeId Start Second should be greater than zero`
      }));
      return false;
    }

    if(input.title === '') {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `Song Title is required`
      }));
      return false;
    }

    setBtnDisabled(true);
    axios.post(`${config.api.URL}/me/youtube-library`, input).then(response => {
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'success',
        message: `Success! new youtubeLibrary added`
      }));

      dispatch(findAllYoutubeLibrarys(props.filterInput));
      props.setOpen(false);
    }).catch(error => {
      setBtnDisabled(false);
      dispatch(caughtError(error));
    })

  }

  return (
    <>
      <DialogContent sx={{ marginTop: "-15px" }} >

        <Box sx={{ p: 2, border: '0px dashed grey', mb: '10px' }} >
          <div className="row">
            <div className="col-12" style={{ marginBottom: "15px" }}>
              <TextField
                autoFocus
                value={state.youtubeId}
                onChange={handleInputChange}
                name="youtubeId"
                label="youtubeId"
                margin="dense"
                type="text"
                fullWidth
                variant="outlined"
                required
                onKeyPress={ (e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSubmit();
                    }
                  }
                }
              />
              <small>
                e.g. <span style={{ textDecoration: "line-through" }}>
                https://www.youtube.com/watch?v=</span>
                <strong style={{ color: 'coral' }}>youtubeId</strong>
              </small>
            </div>
            <div className="col-12" style={{ marginBottom: "15px" }}>
              <TextField
                value={state.youtubeIdStart}
                onChange={handleInputChange}
                name="youtubeIdStart"
                label="Play from (In seconds)"
                margin="dense"
                type="number"
                fullWidth
                variant="outlined"
                required
                onKeyPress={ (e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSubmit();
                    }
                  }
                }
              />
            </div>
            <div className="col-12">
              <TextField
                value={state.title}
                onChange={handleInputChange}
                name="title"
                label="Song Title"
                margin="dense"
                type="text"
                fullWidth
                variant="outlined"
                required
                onKeyPress={ (e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSubmit();
                    }
                  }
                } />
            </div>
          </div>
          <div className="col-12">
            <FormControlLabel
              className="mt-3"
              control={<Switch name="nsfw" checked={state.nsfw} onChange={handleInputChange} />}
              label="NSFW Video"
              labelPlacement="end" />
          </div>
          <div className="col-12">
            <small>(plays audio only)</small>
          </div>
        </Box>

      </DialogContent>
      <DialogActions>
        <button className="btn btn-secondary" onClick={ (e) => {
            e.preventDefault();
            props.setOpen(false);
          } }>Cancel</button>
        <button disabled={btnDisabled} className="btn btn-primary" onClick={ (e) => {
            e.preventDefault();
            handleSubmit();
          }
        } >
          Add New Song
        </button>
      </DialogActions>
    </>
  )

}


export default CreateForm;

import React from 'react';
import SquareIcon from '@mui/icons-material/Square';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const ScreenTodayUserSales = (props) => {

  const {
    todayTopSales,
    todaySales
  } = props.salesscreen;

  let todayTopSalesContent = null; let todaySalesContent = null;

  try {
    if(todayTopSales.length > 0) {
      todayTopSalesContent = todayTopSales.map( (row) =>
      <>
      <tr key={row.id}>
        <td style={{ width: '10%' }} valign="top">
          {
            row.logoFileName ? <img src={row.logoResizedUrl} alt="" style={{ maxHeight: '3.028vw' }}/> :
            <SquareIcon style={{ color: row.colorCode }} fontSize="large" />
          }
        </td>
        <td style={{ width: '90%', textAlign: 'left' }} valign="top">
          <div className="row">
            <div className="col-5" >
              { row.postedBy } | { row.teamName }
            </div>
            <div className="col-4" >
                  { `$${row.amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}` }
            </div>
            <div className="col-3">
              <AccessTimeIcon />&nbsp;{ row.createdAt }
            </div>
          </div>
        </td>
      </tr>
      <tr  key={`${row.id}-desc`}>
        <td>&nbsp;</td>
        <td style={{ width: '90%', textAlign: 'left' }} valign="top">{ row.description }</td>
      </tr>
    </> )
    }
    if(todaySales.length > 0) {
      todaySalesContent = todaySales.map( (row) =>
      <>
      <tr key={row.id}>
        <td style={{ width: '10%' }} valign="top">
          {
            row.logoFileName ? <img src={row.logoResizedUrl} alt="" style={{ maxHeight: '3.028vw' }}/> :
            <SquareIcon style={{ color: row.colorCode }} fontSize="large" />
          }
        </td>
        <td style={{ width: '90%', textAlign: 'left' }} valign="middle">
          <div className="row">
            <div className="col-5" >
              { row.postedBy } | { row.teamName }
            </div>
            <div className="col-4" >
                  { `$${row.amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}` }
            </div>
            <div className="col-3">
              <AccessTimeIcon />&nbsp;{ row.createdAt }
            </div>
          </div>
        </td>
      </tr>
      <tr  key={`${row.id}-desc`}>
        <td>&nbsp;</td>
        <td style={{ width: '90%', textAlign: 'left' }} valign="top">{ row.description }</td>
      </tr>
    </> )
    }
  } catch(error) {
    console.log("ScreenTodayUserSales--->Error-->",error);
  }

  return (
    <>

      <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12">
        <div className="card">
          <div className="card-body" style={{ minHeight: '700px' }}>
            <h5 className="card-title">TODAY'S TOP 10 SALES</h5>
            <table className="table table-striped" style={{ fontFamily: "'Circular', poppins" }}>
              <tbody>{todayTopSalesContent}</tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12">
        <div className="card">
          <div className="card-body" style={{ minHeight: '700px' }}>
            <h5 className="card-title">TODAY'S LATEST SALES</h5>
            <table className="table table-striped" style={{ fontFamily: "'Circular', poppins" }}>
              <tbody>{todaySalesContent}</tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  );
}


export default ScreenTodayUserSales;

import React, { useState } from 'react';
import CreateForm from './CreateForm';
import CustomDialog from '../ui/CustomDialog';

const HeaderSection = (props) => {

  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="row" style={{marginBottom: "10px" }}>
        <div className="col-lg-12">
          <button type="button" className="btn btn-primary float-end" onClick={ e => setOpen(true) } >Add New Milestone</button>
        </div>
      </div>

      <CustomDialog maxWidth="sm" title="Add New Milestone" open={open} setOpen={setOpen} >
        <CreateForm
          youtubeLibrary={props.youtubeLibrary}
          milestoneType={props.milestoneType}
          setOpen={setOpen}
          filterInput={props.filterInput}
        />
      </CustomDialog>

    </>
  );
}

export default HeaderSection;

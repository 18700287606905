import React, { useState } from 'react';
import CreateForm from './CreateForm';
import CustomDialog from '../ui/CustomDialog';

const HeaderSection = (props) => {

  const [open, setOpen] = useState(false);
  const [hideAddButton, setHideAddButton] = useState(true);

  let _rowContent = '';

  if(hideAddButton) {
    _rowContent = <span onClick={ e => setHideAddButton(false) }
      className="text-danger"
      style={{ fontSize: '0.7em', fontStyle: 'italic' }}>
      DEVELOPERS WILL CREATE NEW ENTRIES WHEN REQUIRED BUT ADMIN CAN UPDATE EXISTING RECORDS.
    </span>
  } else {
    _rowContent = <button
   type="button"
   className="btn btn-primary"
   onClick={ (e) => setOpen(true) } >Add New TeamGroup</button>
  }

  return (
    <>
    <div className="row" style={{marginBottom: "10px" }}>
      <div className="col-lg-12">
        <div className="float-end">
        {_rowContent}
        </div>
      </div>
    </div>

      <CustomDialog maxWidth="sm" title="Add New TeamGroup" open={open} setOpen={setOpen} >
        <CreateForm
          setOpen={setOpen}
          filterInput={props.filterInput}
        />
      </CustomDialog>

    </>
  );
}

export default HeaderSection;

import {
  PROFILE_SET_OVERVIEW,
  PROFILE_SET_INITIAL_STATE,
  PROFILE_SET_MY_MODULES
} from './profileTypes'

const initialState = {
  userId: null,
  email: '',
  name: '',
  mobile: '',
  theme: 'light',
  myModules: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_SET_OVERVIEW:
      return {
        ...state,
        ...action.payload
      }
    case PROFILE_SET_MY_MODULES:
      return {
        ...state,
        myModules: action.payload
      }
    case PROFILE_SET_INITIAL_STATE:
      return initialState;
    default: return state
  }
}

export default reducer

import React from 'react';
import SquareIcon from '@mui/icons-material/Square';

const ScreenTodayTeamAndGroupStats = (props) => {

  let byTeam = null; let byGroup = null;

  try {
    const {
      todaySalesTotalAmountByTeam,
      todaySalesByGroup
    } = props.salesscreen.common;


    if(todaySalesTotalAmountByTeam.length > 0){
      byTeam = todaySalesTotalAmountByTeam.map((row) =>
        <tr key={row.teamName}>
          <td valign="middle" width="10%">
            {
              row.logoFileName ? <img src={row.logoResizedUrl} alt="" style={{ maxHeight: '48px' }}/> :
              <SquareIcon style={{ color: row.colorCode }} fontSize="large" />
            }
          </td>
          <td width="60%" align="left" valign="middle">{row.teamName}</td>
          <td width="30%" align="left" valign="middle" >${row.amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
        </tr>
      )
    }

    if(todaySalesByGroup.length > 0){
      const copied = todaySalesByGroup.map((row) => row);
      const groupTotalSorted = copied.sort((a, b) => {
        return b.amount - a.amount;
      });
      byGroup = groupTotalSorted.map((row) =>
      <tr key={row.shortCode}>
        <td align="left" width="70%">
          {row.shortCode}
        </td>
        <td align="left" width="30%" >
          ${row.amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
        </td>
      </tr>
    )
    }
  } catch(error) {
    console.log("ScreenTodayTeamAndGroupStats--->Error-->",error);
  }

  return (
    <>

      <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12 float-start">
        <div className="card">
          <div className="card-body" style={{ minHeight: '700px' }}>
            <h5 className="card-title">TODAY'S TOP TEAMS</h5>
            <table className="table table-striped" style={{ fontFamily: "'Circular', poppins", fontSize: '1vw' }}>
              <tbody>
                {byTeam}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12 float-end">
        <div className="card">
          <div className="card-body" style={{ minHeight: '700px' }}>
            <h5 className="card-title">TODAY'S TOP GROUP</h5>
            <table className="table table-striped"  style={{ fontFamily: "'Circular', poppins", fontSize: '1vw' }}>
              <tbody>
                {byGroup}
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  );

}

export default ScreenTodayTeamAndGroupStats

import axios from 'axios';
import getAppConfig from '../../config';
import { setGlobalLoader, caughtError } from '../ui/uiActions';
import { getQueryString } from '../../components/common/functions';

import {
  LOG_SET_INITIAL_STATE,
  LOG_FIND_ALL,
  LOG_VIEW
} from './logTypes';

const config = getAppConfig();

export const viewLog = (logId) => {
  let URL = `${config.api.URL}/log/${logId}`;
  return (dispatch) => {
    dispatch(onSuccessViewLog({data: false}))
    axios.get(URL).then(response => {
      dispatch(onSuccessViewLog(response))
    }).catch(error => {
      dispatch(caughtError(error))
    })
  }
}

export const findAllLogs  = (filters="") => {
  let URL = `${config.api.URL}/log`;
  if(filters !== '') {
    URL = `${URL}?${getQueryString(filters)}`;
  }
  return (dispatch) => {
    dispatch(setGlobalLoader(true))
    axios.get(URL).then(response => {
      dispatch(setGlobalLoader(false))
      dispatch(onSuccessFindAllLog(response))
    }).catch(error => {
      dispatch(setGlobalLoader(false))
      dispatch(setLogInitialState())
      dispatch(caughtError(error))
    })
  }
}

export const setLogInitialState = () => {
  return {
    type: LOG_SET_INITIAL_STATE
  }
}

export const onSuccessViewLog = response => {
  return {
    type: LOG_VIEW,
    payload: response.data
  }
}

export const onSuccessFindAllLog = response => {
  return {
    type: LOG_FIND_ALL,
    payload: response.data
  }
}

import React from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogCloseIconButton from './DialogCloseIconButton';

const CustomDialog = (props) => {
  const session = useSelector((state: RootState) => state.session);

  return (
    <Dialog maxWidth={props.maxWidth ? props.maxWidth : 'sm'} fullWidth={true} open={props.open} onClose={ (e) => props.setOpen(false) }>
      <div className={"theme"+session.theme+"dialog"}>
        <DialogTitle>
          <span className="card-title" style={{ marginBottom: '-10px' }}>{ props.title ? props.title : '' }</span>
          <DialogCloseIconButton open={props.open} setOpen={props.setOpen} />
        </DialogTitle>
        { props.children }
      </div>
    </Dialog>
  )
}

export default CustomDialog;

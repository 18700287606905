import React from 'react';
import Layout from './layout/Layout';
import ScreenLayout from './components/screen/Layout';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import ListenSocketEvents from './components/common/ListenSocketEvents';

/************* COMPONENENTS ****************/
import CustomSnackBar from './components/ui/CustomSnackBar';
import NotFound from './components/misc/NotFoundContainer';
import LoginForm from './components/misc/LoginFormContainer';
import GoogleCallback from './components/misc/GoogleCallback';
import Dashboard from './components/dashboard/DashboardContainer'
import Team from './components/team/TeamContainer';
import CustomScreenContainer from './components/custom-screen/CustomScreenContainer';
import Role from './components/role/RoleContainer';
import Profile from './components/profile/ProfileContainer';
import User from './components/user/UserContainer';
import Module from './components/module/ModuleContainer';
import TeamGroup from './components/team-group/TeamGroupContainer';
import MilestoneType from './components/milestone-type/MilestoneTypeContainer';
import Log from './components/log/LogContainer';
import Sale from './components/sale/SaleContainer';
import Milestone from './components/milestone/MilestoneContainer';
import YoutubeLibrary from './components/youtube-library/YoutubeLibraryContainer';
import ApplicationSettings from './components/application-settings/ApplicationSettingsContainer';
import Industry from './components/industry/IndustryContainer';
import Webhook from './components/webhook/WebhookContainer';

function App() {

  return (
      <>

        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Dashboard pageTitle="Dashboard"  />} />
              <Route path="team" element={<Team pageTitle="Team" />} />
              <Route path="role" element={<Role pageTitle="Role" />} />
              <Route path="my-profile" element={<Profile pageTitle="My Profile" />} />
              <Route path="user" element={<User pageTitle="User" />} />
              <Route path="module" element={<Module pageTitle="Module" />} />
              <Route path="industries" element={<Industry pageTitle="Industry" />} />
              <Route path="webhooks" element={<Webhook pageTitle="Webhook" />} />
              <Route path="team-group" element={<TeamGroup pageTitle="TeamGroup" />} />
              <Route path="milestone-type" element={<MilestoneType pageTitle="MilestoneType" />} />
              <Route path="log" element={<Log pageTitle="User Action Logs" />} />
              <Route path="custom-screen" element={<CustomScreenContainer pageTitle="Screen Pages" />} />
              <Route path="sale" element={<Sale pageTitle="Announce Your Sale" />} />
              <Route path="milestone" element={<Milestone pageTitle="Announce Your Milestone" />} />
              <Route path="my-youtube-library" element={<YoutubeLibrary pageTitle="My Youtube Library" />} />
              <Route path="app-settings" element={<ApplicationSettings  pageTitle="Application Settings" />} />
            </Route>
            <Route path="users/google/callback" element={<GoogleCallback />} />
            <Route path="users/login" element={<LoginForm />} />
            <Route path="screen" element={<ScreenLayout  />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>

        <CustomSnackBar />
        <ListenSocketEvents />
      </>
  );
}

export default App;

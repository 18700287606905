import React from 'react';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { deepOrange } from '@mui/material/colors';
import Chip from '@mui/material/Chip';
import { logOutUserSession } from '../../redux';

class NavItemSection extends React.Component {

  handleLogout = () => {
    this.props.logOutUserSession();
    window.location.href = '/users/login';
  }

  render() {

    return (
      <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown pe-3">
              <a className="nav-link nav-profile d-flex align-items-center pe-0" href="/" data-bs-toggle="dropdown">
                <Avatar
                  sx={{ width: 35, height: 35, bgcolor: this.props.session.colorCode ? this.props.session.colorCode : deepOrange[500] }}
                  variant="round"
                  src={this.props.session.googlePicture}
                >{this.props.session.name ? this.props.session.name.charAt(0) : ''}
                </Avatar>
                <span className="d-none d-md-block dropdown-toggle ps-2">{this.props.session.name}</span>
              </a>
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li className="dropdown-header">
                  <h6><Chip label={this.props.session.teamName.toUpperCase()}  size="medium" variant="filled" /></h6>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link to="/my-profile" className="dropdown-item d-flex align-items-center" >
                    <i className="bi bi-person" />
                    <span>My Profile - {this.props.session.roleName.toUpperCase()}</span>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link to="/my-youtube-library" className="dropdown-item d-flex align-items-center" >
                    <i className="bi bi-youtube" />
                    <span>My Youtube Library</span>
                  </Link>
                </li>
                {
                  this.props.session.roleId === 3 &&
                  <li>
                    <Link to="/app-settings" className="dropdown-item d-flex align-items-center" >
                      <i className="bi bi-gear" />
                      <span>Application Settings</span>
                    </Link>
                  </li>
                }
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <div className="dropdown-item d-flex align-items-center">
                    <i className="bi bi-box-arrow-right" />
                    <span>
                      <button type="button" className="btn btn-link"
                        onClick={() => {
                          this.handleLogout()
                        }}
                      >Sign Out</button>
                  </span>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
      </nav>
    );
  }
}

const mapStateToProps = state => {
  return {
    session: state.session
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOutUserSession: () => dispatch(logOutUserSession())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavItemSection)

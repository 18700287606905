import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircleIcon from '@mui/icons-material/Circle';
import { viewTeam } from '../../redux';

const SubscribedScreen = (props) => {

  if(props._screens.length > 0) {
    const returnResult = props._screens.map((_row) => <li key={_row.id}>{_row.title}</li>);
    return <ul>{returnResult}</ul>
  } else {
    return null;
  }
}

const ViewDetails = (props) => {

  const viewResult = useSelector((state) => state.team.viewResult);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  let teamLogo = '';
  if(viewResult?.logoFileName) {
    teamLogo = <img className="float-end" src={viewResult.logoResizedUrl} alt="" />
  }

  useEffect(() => {
    if(loading && props.modalDiaLogId && props.modalDiaLogId > 0) {
      dispatch(viewTeam(props.modalDiaLogId));
      setLoading(false);
    }
  }, [props, loading, viewResult, dispatch]);


  const details = (
    loading === false && viewResult ?
    <ul className="list-group">
    <li className="list-group-item">
      <strong className="p-2">TeamId:</strong> {viewResult.id}
      {teamLogo}
    </li>
    <li className="list-group-item"><strong className="p-2">TeamName:</strong> <CircleIcon style={{ color: viewResult.colorCode }} /> {viewResult.teamName}</li>
    <li className="list-group-item"><strong className="p-2">TeamGroupName:</strong> {viewResult.groupName}</li>
    <li className="list-group-item"><strong className="p-2">TotalMembers:</strong> {viewResult.totalMembers}</li>
    <li className="list-group-item"><strong className="p-2">ColorCode:</strong> {viewResult.colorCode}</li>
    <li className="list-group-item"><strong className="p-2">Active:</strong> {viewResult.active ? "YES" : "NO"}</li>
    <li className="list-group-item"><strong className="p-2">CreatedAt:</strong> {viewResult.createdAt}</li>
    <li className="list-group-item"><strong className="p-2">UpdatedAt:</strong> {viewResult.updatedAt}</li>
    <li className="list-group-item"><strong className="p-2">ScreenPages:</strong><SubscribedScreen _screens={viewResult.subscribedScreens} /></li>
  </ul> : <CircularProgress /> )

  return (
    <>
    <DialogContent sx={{ marginTop: "-10px" }} >
      { details }
    </DialogContent>
    <DialogActions>
      <button className="btn btn-secondary" onClick={ () => props.setOpen(false) } >Close</button>
    </DialogActions>
    </>
  )

}

export default ViewDetails;

import {
  SCREEN_SET_INITIAL_STATE,
  SCREEN_SET_TODAY_SALES,
  SCREEN_SET_COMMON,
  SCREEN_SET_TODAY_TOP_SALES,
  SCREEN_SET_MONTH_MILESTONES,
  SCREEN_SET_MONTH_REVENUES,
  SCREEN_SET_TEAM_LIST,
  SCREEN_SET_CURRENT_MONTH,
  SCREEN_ADD_ALERT_ITEM,
  SCREEN_CLEAR_ALERT_ITEMS,
  SCREEN_ALERT_PLAYING_COUNTER,
  SCREEN_SET_VIEW_ALERT,
  SCREEN_SET_AUTO_PLAY,
  SCREEN_SET_MUTE_ALL,
  SCREEN_SET_AUTO_SLIDE_PAGES,
  SCREEN_TEAM_TOP_COLLECTORS,
  SCREEN_SALES_MONTHLY_AMOUNT,
  SCREEN_TEAM_SALES_MONTHLY_AMOUNT,
  SCREEN_SALES_DAILY_AMOUNT,
  SCREEN_TOP_INDIVISUAL_COLLECTORS,
  SCREEN_SALES_INDUSTRIES,
  SCREEN_SET_VPN_STATUS,
} from './screenTypes'


const initialState = {
  connectedToVPN: false,

  /** screen alerts related **/
  screenAlertPlayingCounter: 0,
  screenViewAlert: false,
  screenAutoPlay: true,
  screenAutoSlidePages: true,
  screenMuteAll: false,
  screenAlertItem: null,

  /** screen data related **/
  common: {
    todaySalesCount: 0,
    todaySalesTotalAmount: 0,
    todaySalesTotalAmountByTeam: [],
    todaySalesByGroup: []
  },
  todaySales: [],
  todayTopSales: [],
  monthMilestones: [],
  monthRevenues: [],
  teamList: [],
  monthText: '',

  /*** misc ***/
  Last30DaysTopTeams: [],
  Last30DaysTopMembers: [],
  salesMonthlyAmount: [],
  teamSalesMonthlyAmount: [],
  salesDailyAmount: [],
  industrySales: []

}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SCREEN_SET_INITIAL_STATE:
      return initialState;
    case SCREEN_SET_TODAY_SALES:
      return {
        ...state,
        todaySales: action.payload
      }
    case SCREEN_SET_COMMON:
      return {
        ...state,
        common: action.payload
      }
    case SCREEN_SET_TODAY_TOP_SALES:
      return {
        ...state,
        todayTopSales: action.payload
      }
    case SCREEN_SET_MONTH_REVENUES:
      return {
        ...state,
        monthRevenues: action.payload
      }
    case SCREEN_SET_MONTH_MILESTONES:
      return {
        ...state,
        monthMilestones: action.payload
      }
    case SCREEN_SET_TEAM_LIST:
      return {
        ...state,
        teamList: action.payload
      }
    case SCREEN_SET_CURRENT_MONTH:
      return {
        ...state,
        monthText: action.payload
      }
    case SCREEN_ADD_ALERT_ITEM:
    //console.log("action.payload>",action.payload);
      return {
        ...state,
        screenAlertItem: action.payload
      }
    case SCREEN_CLEAR_ALERT_ITEMS:
      return {
        ...state,
        screenAlertItem: null
      }
    case SCREEN_ALERT_PLAYING_COUNTER:
      return {
        ...state,
        screenAlertPlayingCounter: action.payload
      }
    case SCREEN_SET_VIEW_ALERT:
      return {
        ...state,
        screenViewAlert: action.payload
      }
    case SCREEN_SET_AUTO_PLAY:
      return {
        ...state,
        screenAutoPlay: action.payload
      }
    case SCREEN_SET_MUTE_ALL:
      return {
        ...state,
        screenMuteAll: action.payload
      }
    case SCREEN_SET_AUTO_SLIDE_PAGES:
      return {
        ...state,
        screenAutoSlidePages: action.payload
      }
    case SCREEN_TEAM_TOP_COLLECTORS:
      return {
        ...state,
        Last30DaysTopTeams: action.payload
      }
    case SCREEN_TOP_INDIVISUAL_COLLECTORS:
      return {
        ...state,
        Last30DaysTopMembers: action.payload
      }
    case SCREEN_SALES_INDUSTRIES:
      return {
        ...state,
        industrySales: action.payload
      }
    case SCREEN_SALES_MONTHLY_AMOUNT:
      return {
        ...state,
        salesMonthlyAmount: action.payload
      }
    case SCREEN_TEAM_SALES_MONTHLY_AMOUNT:
      return {
        ...state,
        teamSalesMonthlyAmount: action.payload
      }
    case SCREEN_SALES_DAILY_AMOUNT:
      return {
        ...state,
        salesDailyAmount: action.payload
      }
    case SCREEN_SET_VPN_STATUS:
      return {
        ...state,
        connectedToVPN: action.payload
      }
    default: return state
  }
}

export default reducer

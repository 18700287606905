import axios from "axios";
import getAppConfig from '../../config';
import { encryptWithAES } from '../../components/common/CryptoServices';
import {
  SESSION_LOGIN_SUCCESS,
  SESSION_SET_INITIAL_STATE,
  SESSION_UPDATE_PROFILE_NAME,
  SESSION_UPDATE_THEME_NAME,
  SESSION_UPDATE_TEAM_LOGO
} from "./sessionTypes";

import {
  setProfileOverview,
  setMyProfileModules,
} from "../profile/profileActions";

axios.defaults.withCredentials = true;

const config = getAppConfig();

export const logOutUserSession = () => {
  window.localStorage.removeItem("cookie_ctoken");
  let URL = `${config.api.URL}/google/logout`;
  axios.get(URL).then(response => {
    console.log(response);
  }).catch(error => {
    console.log("logOutUserSession error",error);
  })
  return {
    type: SESSION_SET_INITIAL_STATE,
  };
};

export const onLoginSuccess = (response) => {
  return (dispatch) => {
    const cookie_ctoken = encryptWithAES(response.data.cookie_ctoken);
    window.localStorage.setItem("cookie_ctoken",cookie_ctoken);
    const input = {
      isAuthenticated: true,
      ...response.data,
    };
    dispatch(onSuccessUserLogin(input));
    dispatch(setProfileOverview());
    dispatch(setMyProfileModules({ sort: "moduleId", direction: "ASC" }));
  };
};

export const updateSessionProfileName = (newProfileName) => {
  return {
    type: SESSION_UPDATE_PROFILE_NAME,
    payload: newProfileName,
  };
};

export const updateSessionThemeName = (theme) => {
  return {
    type: SESSION_UPDATE_THEME_NAME,
    payload: theme,
  };
};

export const updateSessionTeamLogo = (input) => {
  return {
    type: SESSION_UPDATE_TEAM_LOGO,
    payload: input,
  };
};

/************** local actions **************/
const onSuccessUserLogin = (user) => {
  return {
    type: SESSION_LOGIN_SUCCESS,
    payload: user,
  };
};

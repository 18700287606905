import axios from 'axios';
import getAppConfig from '../../config';
import { setGlobalLoader, setSnackBarAlert, caughtError } from '../ui/uiActions';
import { getQueryString } from '../../components/common/functions';

import {
  MILESTONE_SET_INITIAL_STATE,
  MILESTONE_FIND_ALL,
  MILESTONE_VIEW
} from './milestoneTypes';

const config = getAppConfig();

export const viewMilestone = (milestoneId) => {
  let URL = `${config.api.URL}/me/milestone/${milestoneId}`;
  return (dispatch) => {
    dispatch(onSuccessViewMilestone({data: false}))
    axios.get(URL).then(response => {
      dispatch(onSuccessViewMilestone(response))
    }).catch(error => {
      dispatch(caughtError(error))
    })
  }
}

export const deleteMilestone = (milestoneId, filterInput) => {
  let URL = `${config.api.URL}/me/milestone/mark-delete/${milestoneId}`;
  if(filterInput.deleted === true) {
    // if record already deleted
    URL = `${config.api.URL}/me/milestone/unmark-delete/${milestoneId}`;
  }
  return (dispatch) => {
    axios.patch(URL).then(response => {

      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'success',
        message: `Success! milestone ${filterInput.deleted ? 'restored' : ' moved to trash'}`
      }))

      dispatch(findAllMilestones(filterInput));
    }).catch(error => {
      dispatch(caughtError(error))
    })
  }
}

export const findAllMilestones  = (filters="") => {
  let URL = `${config.api.URL}/me/milestone`;
  if(filters !== '') {
    URL = `${URL}?${getQueryString(filters)}`;
  }
  return (dispatch) => {
    dispatch(setGlobalLoader(true))
    axios.get(URL).then(response => {
      dispatch(setGlobalLoader(false))
      dispatch(onSuccessFindAllMilestone(response))
    }).catch(error => {
      dispatch(setGlobalLoader(false))
      dispatch(setMilestoneInitialState())
      dispatch(caughtError(error))
    })
  }
}
export const setMilestoneInitialState = () => {
  return {
    type: MILESTONE_SET_INITIAL_STATE
  }
}

export const onSuccessViewMilestone = response => {
  return {
    type: MILESTONE_VIEW,
    payload: response.data
  }
}

export const onSuccessFindAllMilestone = response => {
  return {
    type: MILESTONE_FIND_ALL,
    payload: response.data
  }
}

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackBarAlert } from '../../redux';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import axios from 'axios';
import getAppConfig from '../../config';
const config = getAppConfig();

const SwitchSetting = (props) => {

  const metaKey = props.item.metaKey;
  const display = props.item.display;
  const [metaValue, setMetaValue] = useState(Boolean(parseInt(props.item.metaValue)));
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setMetaValue(value);

    if(name === 'metaValue') {
      updateSetting(value);
    }
  }

  const updateSetting = (value) => {
    const URL = `${config.api.URL}/app-setting/${props.item.id}`;
    axios.patch(URL,{
      metaValue: value ? '1':'0'
    }).then(response => {
      const result = response.data.data;
      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'success',
        message: `${metaKey} ${parseInt(result.metaValue) ? 'Active' : 'In-Active'}`
      }));
    }).catch(error => {
      props.setSnackBarAlert({
        openSnackBar: true,
        severity: 'error',
        message: `Unable to update ${metaKey}`
      });
    })
  }

  if(display) {
    return (
      <div className="row">
        <div className="col-lg-4 col-md-4 label">
          {metaKey}
        </div>
        <div className="col-lg-8 col-md-8">
          <FormGroup>
            <Switch name="metaValue" checked={metaValue} onChange={handleInputChange} />
          </FormGroup>
        </div>
      </div>
    );
  } else {
    return null;
  }
}


export default SwitchSetting

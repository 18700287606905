import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { viewYoutubeLibrary } from '../../redux';
import YouTube from 'react-youtube';
import getAppConfig from '../../config';
const config = getAppConfig();

const ViewDetails = (props) => {

  const viewResult = useSelector((state) => state.youtubeLibrary.viewResult);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if(loading && props.modalDiaLogId && props.modalDiaLogId > 0) {
      dispatch(viewYoutubeLibrary(props.modalDiaLogId));
      setLoading(false);
    }
  }, [props, loading, viewResult, dispatch]);


  const details = (
    loading === false && viewResult ?
    <>
    <div className="row">
      <div className="col-sm">
        {
          viewResult && viewResult.youtubeId && <YouTube
          videoId={viewResult.youtubeId}
          opts={{
            height: '327',
            width: '100%',
            playerVars: {
              rel: 0,
              enablejsapi: 1,
              origin: config.app.URL,
              //autoplay: 1,
              start: viewResult.youtubeIdStart ? viewResult.youtubeIdStart : 0,
              end: viewResult.youtubeIdEnd ? viewResult.youtubeIdEnd : 30
            },
          }}
          title={viewResult.title} />
        }
      </div>
      <div className="col-sm">
        <ul className="list-group">
        <li className="list-group-item"><strong className="p-2">SongId:</strong> {viewResult.id}</li>
        <li className="list-group-item"><strong className="p-2">Title:</strong> {viewResult.title}</li>
        <li className="list-group-item"><strong className="p-2">NSFW (Audio only):</strong> {viewResult.nsfw ? "YES" : "NO"}</li>
        <li className="list-group-item"><strong className="p-2">YouTubeId:</strong> {viewResult.youtubeId}</li>
        <li className="list-group-item"><strong className="p-2">StartFrom(In Sec):</strong> {viewResult.youtubeIdStart}</li>
        <li className="list-group-item"><strong className="p-2">PlayUpto(In Sec):</strong> {viewResult.youtubeIdEnd}</li>
        <li className="list-group-item"><strong className="p-2">AddedBy:</strong> {viewResult.createdBy}</li>
        <li className="list-group-item"><strong className="p-2">CreatedAt:</strong> {viewResult.createdAt}</li>
      </ul>
      </div>
    </div>
    </> : <CircularProgress /> )

  return (
    <>
    <DialogContent sx={{ marginTop: "-10px" }} >
      { details }
    </DialogContent>
    <DialogActions>
      <button className="btn btn-secondary" onClick={ () => props.setOpen(false) } >Close</button>
    </DialogActions>
    </>
  )

}

export default ViewDetails;

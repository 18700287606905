import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';

const AppLogoSection = () => {
  const session = useSelector((state: RootState) => state.session);
  const [hideSidebar, toggleSidebar] = useState(false);

  function handleSideBarToggle () {
    toggleSidebar(!hideSidebar)
  }

  useEffect(() => {
    if(hideSidebar) {
      document.body.classList.add('toggle-sidebar');
    } else {
      document.body.classList.remove('toggle-sidebar');
    }
  })

  let appLogo = <img src="/assets/img/logo.png" alt="" />
  if(session.theme === 'dark') {
    appLogo = <img src="/assets/img/madwire-logo-white-text.png" alt="" />
  }

  return (
    <div className="d-flex align-items-center justify-content-between">
      <i className="bi bi-list toggle-sidebar-btn" onClick={ () => handleSideBarToggle() } />
      <Link to="/screen" className="logo d-flex align-items-center">
        {appLogo}
      </Link>
    </div>
  );
}

export default AppLogoSection;

import { useSelector } from 'react-redux';
import LogoForm from './team/LogoForm';

const TeamSettings = () => {
  const session = useSelector((state) => state.session);

  return (
    <>
    <h5 className="card-title">{session.teamName} - Team Details</h5>
    <div className="row">
      <div className="col-lg-3 col-md-4 label ">Logo</div>
      <div className="col-lg-9 col-md-8"><LogoForm /></div>
    </div>
    </>
  );
}

export default TeamSettings;

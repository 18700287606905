import React from 'react';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ConvertUrlToLink } from '../../common/functions';

const ScreenCurrentMonthStats = (props) => {

  const {
    monthRevenues,
    monthMilestones,
    monthText
  } = props.salesscreen;

  let monthRevenuesContent = null; let monthMilestonesContent = null;

  try {
    if(monthRevenues.length > 0) {
      monthRevenuesContent = monthRevenues.map( (row) =>
      <tr key={`${row.postedBy}-${Math.random()}`}>
        <td style={{ width: '10%' }} valign="middle">
          <Avatar
            variant="circle"
            src={row.googlePicture}
            sx={{
              width: 40, height: 40,
              bgcolor: deepOrange[500]
            }}>
            {row.postedBy.charAt(0)}
          </Avatar>
        </td>
        <td valign="middle" style={{ textAlign: 'left' }}>{ row.postedBy }</td>
        <td valign="middle" >{ `$${row.amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}` }</td>
        <td valign="middle" style={{ textAlign: 'center' }}>{ row.totalSaleQuantity }</td>
      </tr> )
    }

    if(monthMilestones.length > 0) {
      monthMilestonesContent = monthMilestones.map( (row) =>
      <>
      <tr key={row.id} >
        <td style={{ width: '10%' }} valign="middle">
          <Avatar
            variant="circle"
            src={row.googlePicture}
            sx={{
              width: 30, height: 30,
              bgcolor: deepOrange[500]
            }}>
            {row.postedBy.charAt(0)}
          </Avatar>
        </td>
        <td style={{ width: '90%', textAlign: 'left' }} valign="middle">
          <div className="row">
            <div className="col-9">{ row.postedBy }</div>
            <div className="col-3"><AccessTimeIcon />&nbsp;{ row.createdAt }</div>
          </div>
        </td>
      </tr>
      <tr key={`${row.id}-desc`} >
        <td>&nbsp;</td>
        <td style={{ width: '90%' }} valign="top">
          <ConvertUrlToLink text={row.description} />
        </td>
      </tr>
      </> )
    }
  } catch(error) {
    console.log("ScreenCurrentMonthStats--->Error-->",error);
  }

  return (
    <>

      <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12 float-start">
        <div className="card">
          <div className="card-body" style={{ minHeight: '700px' }}>
            <h5 className="card-title">{monthText+"'S"} MVPS BY REVENUE</h5>
            <table className="table table-striped" style={{ fontFamily: "'Circular', poppins" }}>
              <tbody>
                <tr>
                  <td style={{ textAlign: 'center' }}>#</td>
                  <td style={{ textAlign: 'left' }}>Name</td>
                  <td style={{ textAlign: 'left' }}>TotalSales</td>
                  <td style={{ textAlign: 'center' }}>Number of Sales</td>
                </tr>
              </tbody>
              <tbody>{ monthRevenuesContent }</tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="col-lg-6 col-md-12 col-xs-12 col-sm-12 float-end">
        <div className="card">
          <div className="card-body" style={{ minHeight: '700px' }}>
            <h5 className="card-title">{monthText+"'S"} MILESTONES</h5>
            <table className="table table-striped" style={{ fontFamily: "'Circular', poppins" }}>
              <tbody>{ monthMilestonesContent }</tbody>
            </table>
          </div>
        </div>
      </div>


    </>
  );
}

export default ScreenCurrentMonthStats;

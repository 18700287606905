import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PageTitleContainer from '../common/PageTitleContainer';
import TodayStats from './TodayStats';
import SalesAmountByMonthly from './SalesAmountByMonthly';
import SalesAmountByIndustries from './SalesAmountByIndustries';
import SalesAmountByDaily from './SalesAmountByDaily';
import TeamTopCollector from './TeamTopCollector';
import IndividualTopCollector from './IndividualTopCollector';
import {
  callDashboardPageServices,
} from '../../redux';

const DashboardContainer = (props) => {

  const session = useSelector((state) => state.session);
  const dispatch = useDispatch();

  const initFetch = useCallback(() => {
    if(session.isAuthenticated) {
      dispatch(callDashboardPageServices());
    }
  }, [session, dispatch]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  return (
    <main id="main" className="main">
      <PageTitleContainer pageTitle={props.pageTitle} />
      <section className="section">

        <TodayStats />

        <div className="row">
          <div className="card col-lg-8 col-md-8 col-sm-12 p-3 border" style={{ width: '58%' }}>
            <SalesAmountByDaily   />
          </div>
          <div className="card col-lg-4 col-md-4 col-sm-12 p-3 border" style={{ width: '40%', marginLeft: '1%' }}>
            <IndividualTopCollector   />
          </div>
        </div>

        <div className="row">
          <div className="card col-lg-8 col-md-8 col-sm-12 p-3 border" style={{ width: '58%' }}>
            <SalesAmountByMonthly teamName={session.teamName} />
          </div>
          <div className="card col-lg-4 col-md-4 col-sm-12 p-3 border" style={{ width: '40%', marginLeft: '1%' }}>
            <TeamTopCollector   />
          </div>
        </div>

        {
          session.roleId === 3 &&
          <>
          <div className="row">
            <div className="card col-lg-12 col-md-12 col-sm-12 p-3 border">
              <SalesAmountByMonthly rgba={'rgba(58,137,251,0.4)'}   />
            </div>
          </div>
          <div className="row">
            <div className="card col-lg-12 col-md-12 col-sm-12 p-3 border">
              <SalesAmountByIndustries rgba={'rgba(158,37,151,0.4)'}   />
            </div>
          </div>
          </>
        }

      </section>
    </main>
  );
}

export default DashboardContainer;

import React from 'react';
import { useSelector } from 'react-redux';
import SquareIcon from '@mui/icons-material/Square';

const TeamTopCollector = (props) => {

    const salesscreen = useSelector((state) => state.screen);
    const {
      Last30DaysTopTeams
    } = salesscreen;

    let _content = null;
    if(Last30DaysTopTeams.length > 0) {
      _content = Last30DaysTopTeams.map( (row) => <tr key={row.teamName}>
      <td valign="middle" >
        {
          row.logoFileName ? <img src={row.logoResizedUrl} alt="" style={{ width: '60px' }} /> :
          <SquareIcon style={{ color: row.colorCode }} fontSize="large" />
        }
      </td>
      <td valign="middle" style={{ textAlign: 'center' }}>{ row.teamName }</td>
      <td valign="middle" style={{ textAlign: 'center' }}>{ `$${row.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` }</td>
      </tr>)
    }

    if(_content !== null) {
      _content = <table className="table table-striped">
        <tbody>
          { _content }
        </tbody>
      </table>
    }

    return <>
    <h5 className="card-title"  style={{ marginTop: '-15px', marginBottom: '-5px' }}>Top 5 By Team - Last 30 Days</h5>
    { _content !== null ? _content : <p>No data found</p> }
    </>

}

export default TeamTopCollector;

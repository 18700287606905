import axios from 'axios';
import getAppConfig from '../../config';
import { setGlobalLoader } from '../ui/uiActions';
import { setSnackBarAlert, caughtError } from '../ui/uiActions';
import { getQueryString } from '../../components/common/functions';

import {
  INDUSTRY_SET_INITIAL_STATE,
  INDUSTRY_FIND_ALL,
  INDUSTRY_VIEW
} from './industryTypes';

const config = getAppConfig();

export const viewIndustry = (industryId) => {
  let URL = `${config.api.URL}/industry/${industryId}`;
  return (dispatch) => {
    dispatch(onSuccessViewIndustry({data: false}))
    axios.get(URL).then(response => {
      dispatch(onSuccessViewIndustry(response))
    }).catch(error => {
      dispatch(caughtError(error))
    })
  }
}

export const deleteIndustry = (industryId, filterInput) => {
  let URL = `${config.api.URL}/industry/mark-delete/${industryId}`;
  if(filterInput.deleted === true) {
    // if record already deleted
    URL = `${config.api.URL}/industry/unmark-delete/${industryId}`;
  }
  return (dispatch) => {
    axios.patch(URL).then(response => {

      dispatch(setSnackBarAlert({
        openSnackBar: true,
        severity: 'success',
        message: `Success! industry ${filterInput.deleted ? 'restored' : ' moved to trash'}`
      }))

      dispatch(findAllIndustries(filterInput));
    }).catch(error => {
      dispatch(caughtError(error))
    })
  }
}

export const findAllIndustries  = (filters="") => {
  let URL = `${config.api.URL}/industry`;
  if(filters !== '') {
    URL = `${URL}?${getQueryString(filters)}`;
  }
  return (dispatch) => {
    dispatch(setGlobalLoader(true))
    axios.get(URL).then(response => {
      dispatch(setGlobalLoader(false))
      dispatch(onSuccessFindAllIndustry(response))
    }).catch(error => {
      dispatch(setGlobalLoader(false))
      dispatch(setIndustryInitialState())
      dispatch(caughtError(error))
    })
  }
}
export const setIndustryInitialState = () => {
  return {
    type: INDUSTRY_SET_INITIAL_STATE
  }
}

export const onSuccessViewIndustry = response => {
  return {
    type: INDUSTRY_VIEW,
    payload: response.data
  }
}

export const onSuccessFindAllIndustry = response => {
  return {
    type: INDUSTRY_FIND_ALL,
    payload: response.data
  }
}

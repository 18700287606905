import { CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import VisibilityIcon from '@mui/icons-material/Visibility';
//import CircleIcon from '@mui/icons-material/Circle';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { HtmlScreen } from '../common/functions';
//import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function getHeaderColums(props, roleId) {
  let returnResult = null;
  switch(roleId) {
    case 1:
    returnResult = <tr>
      <th scope="col" style={{ width: "5%" }}>{ props.openGlobalLoader ? <CircularProgress size={'1rem'} /> : '#' }</th>
      <th scope="col" style={{ width: "40%" }}>Description</th>
      <th scope="col" style={{ width: "20%" }}>Type</th>
      <th scope="col" style={{ width: "20%" }}>CreatedAt</th>
      <td align="right" width="15%"><strong style={{ marginRight: '12%', width: "15%" }}>Action</strong></td>
    </tr>
    break;
    case 2:
    returnResult = <tr>
      <th scope="col" style={{ width: "5%" }}>{ props.openGlobalLoader ? <CircularProgress size={'1rem'} /> : '#' }</th>
      <th scope="col" style={{ width: "15%" }}>PostedBy</th>
      <th scope="col" style={{ width: "33%" }}>Description</th>
      <th scope="col" style={{ width: "15%" }}>Type</th>
      <th scope="col" style={{ width: "17%" }}>CreatedAt</th>
      <td align="right" width="15%"><strong style={{ marginRight: '12%', width: "15%" }}>Action</strong></td>
    </tr>
    break;
    case 3:
    returnResult = <tr>
      <th scope="col" style={{ width: "5%" }}>{ props.openGlobalLoader ? <CircularProgress size={'1rem'} /> : '#' }</th>
      <th scope="col" style={{ width: "15%" }}>PostedBy</th>
      <th scope="col" style={{ width: "33%" }}>Description</th>
      <th scope="col" style={{ width: "15%" }}>Type</th>
      <th scope="col" style={{ width: "17%" }}>CreatedAt</th>
      <td align="right" width="15%"><strong style={{ marginRight: '12%', width: "15%" }}>Action</strong></td>
    </tr>
    break;
    default:
    returnResult = <tr><th>{''}</th></tr>
    break;
  }
  return returnResult;
}

export const GetTcolumns = (props) => {
  const result = getHeaderColums(props,props.session.roleId);
  return result;
}

export const GetTsearch = (props) => {

  const milestoneTypeData = props.milestoneType.data;
  let milestoneTypeSelect = '';
  if(milestoneTypeData.length > 0) {
    milestoneTypeSelect = milestoneTypeData.map( (row) => <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>)
  }

  const result = <tr>
    <td></td>
    <td></td>
    { [2,3].includes(props.session.roleId) && <td>
      <TextField
        onKeyPress={ (e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              props.onSearchInputEnterKey();
            }
          }
        }
        label="Name"
        size="small"
        name="postedBy"
        value={props.postedBy}
        onChange={props.handleInputChange}
      />
  </td> }
  <td><Select
    fullWidth
    label="MilestoneType"
    size="small"
    name="milestoneTypeId"
    variant="standard"
    margin="dense"
    value={props.milestoneTypeId}
    onChange={props.handleInputChange}
  >
    <MenuItem value={''}>-- SELECT --</MenuItem>
    {milestoneTypeSelect}
  </Select></td>
    <td>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker disableFuture
        onChange={(newValue) => {
          try {
            props.handleDateChange(newValue);
          } catch(error) {
            console.log('DesktopDatePicker-->error -->',error);
            alert("manual update not allowed...")
          }
        }}
        value={props.createdAt}
        label="Date"

        />
      </LocalizationProvider>
    </td>
    <td align="right">
      <button type="button" className="btn btn-link" onClick={props.resetTableSearchInput}>Clear Filters</button>
    </td>
  </tr>

  return result;
}

export const GetTvalues = (props) => {
  let t_index = props.t_index;
  const result = props.t_data.map((row) =>
    <tr key={row.id}>
      <td>{ ++t_index }</td>
      { [2,3].includes(props.session.roleId) && <td>{row.postedBy}</td> }

      { /*  [2,3].includes(props.session.roleId)
        &&  { props.session.roleId === 3 &&
          <td><CircleIcon style={{ color: row.colorCode }} />
          { row.teamName }
      </td>  */ }
      <td><HtmlScreen data={ row.description } /></td>
      <td>{ row.milestoneTypeName }</td>
      <td>{ row.createdAt }</td>
      <td>
        <div className="float-end" style={{ marginRight: '15%' }}>
          <VisibilityIcon fontSize="small" color="primary" onClick={ (e) => props.setOpen(true, row.id, `${row.teamName} - ${row.postedBy}`, 'VIEW') } /> {' '}
          { props.deleted ? <RestoreFromTrashIcon fontSize="small" color="primary" onClick={ (e) => props.setOpen(true, row.id, `${row.teamName} - ${row.postedBy}`, 'DELETE') } /> : <DeleteIcon fontSize="small" color="primary" onClick={ (e) => props.setOpen(true, row.id, `${row.teamName} - ${row.postedBy}`, 'DELETE') } /> }
        </div>
      </td>
    </tr>
  )

  return result;
}

import {
  SET_GLOBAL_LOADER,
  SET_SNACKBAR_ALERT
} from './uiTypes'

const initialState = {
  openGlobalLoader: false,

  /* SnackBar */
  openSnackBar: false,
  severity: '', // info, error, warning, success
  message: ''
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GLOBAL_LOADER:
      return {
        ...state,
        openGlobalLoader: action.payload
      }
    case SET_SNACKBAR_ALERT:
    return {
      ...state,
      openSnackBar: action.payload.openSnackBar,
      severity: action.payload.severity,
      message: action.payload.message,
    }
    default: return state
  }
}

export default reducer

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function DialogCloseIconButton(props) {
  const dialogCloseBtn = props.open ? (
      <IconButton
      aria-label="close"
      onClick={ (e) => props.setOpen(false) }
      sx={{
        position: 'absolute',
        right: 8,
        top: 8
      }}
    >
  <CloseIcon />
  </IconButton>) : null;

  return dialogCloseBtn;
}

export default DialogCloseIconButton;

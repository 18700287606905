import React from 'react';
import { useSelector } from 'react-redux';
import SquareIcon from '@mui/icons-material/Square';

const TodayStats = (props) => {

    const salesscreen = useSelector((state) => state.screen);
    const {
      todaySalesCount,
      todaySalesTotalAmount,
      todaySalesByGroup,
      todaySalesTotalAmountByTeam
    } = salesscreen.common;

    let todaySalesByGroupContent = null;
    if(todaySalesByGroup.length > 0){
      todaySalesByGroupContent = todaySalesByGroup.map((row) =>
        <tr key={row.shortCode}><td>{row.shortCode}</td><td>${row.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td></tr>
      )
    }

    let byTeam = null;
    if(todaySalesTotalAmountByTeam.length > 0){
      byTeam = todaySalesTotalAmountByTeam.map((row) =>
        <tr key={row.teamName}>
          <td valign="middle">
            {
              row.logoFileName ? <img src={row.logoResizedUrl} alt="" style={{ width: '60px' }} /> :
              <SquareIcon style={{ color: row.colorCode }} />
            }
          </td>
          <td valign="middle" style={{ textAlign: 'center' }}>{row.teamName}</td>
          <td valign="middle" style={{ textAlign: 'center' }}>
            ${row.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </td>
        </tr>
      )
    }

    let leftContent = null; let rightContent = null;

    if(todaySalesCount > 0) {
      leftContent = <table className="table table-striped">
        <tbody>
          <tr><td>Number Of Sales</td><td>{todaySalesCount} </td></tr>
          <tr><td>Total Sales Amount</td><td>{ `$${todaySalesTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` } </td></tr>
          { todaySalesByGroupContent !== null && todaySalesByGroupContent }
        </tbody>
      </table>

      if(byTeam !== null) {
        rightContent = <table className="table table-striped">
          <tbody>
            { byTeam }
          </tbody>
        </table>
      }
    } else {
      leftContent = <p>No data found</p>;
      rightContent = <p>No data found</p>;
    }

    return (
      <>
      <div className="row">
        <div className="card col-lg-8 col-md-8 col-sm-12 p-3 border" style={{ width: '58%' }}>
          <h5 className="card-title" style={{ marginTop: '-15px', marginBottom: '-5px' }}>Today's Stats</h5>
          { leftContent }

        </div>
        <div className="card col-lg-4 col-md-4 col-sm-12 p-3 border"  style={{ width: '40%', marginLeft: '1%' }}>
          <h5 className="card-title" style={{ marginTop: '-15px', marginBottom: '-5px' }}>Today's Top Teams</h5>
          { rightContent }

        </div>
      </div>
      </>
    );

}

export default TodayStats;

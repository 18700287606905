import React, { useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PageTitleContainer from '../common/PageTitleContainer';
import HeaderSection from './HeaderSection';
import TablePagination from '@mui/material/TablePagination';
import { findAllCustomScreens, deleteCustomScreen, findAllTeams } from '../../redux';
import CustomDialog from '../ui/CustomDialog';
import DeleteConfirmDialog from '../ui/DeleteConfirmDialog';
import ViewDetails from './ViewDetails';
import EditForm from './EditForm';
import SubscriptionForm from './SubscriptionForm';
import { GetTcolumns, GetTsearch, GetTvalues } from './TableData';
import SortTable from '../common/SortTable';
import ToggleTableTrash from '../common/ToggleTableTrash';

const initialStateFilter = {
  deleted: false,
  page: 0,
  pagesize: 10,
  sort: 'custom_screens.createdAt',
  direction: 'ASC',
  title: '',
  active: '',
  screenOwner: ''
}

const initialStateOther = {
  pageLoaded: false,
  modalDiaLogTitle: '',
  modalDiaLogId: false,
  openDelete: false,
  openView: false,
  openEdit: false,
  openSubscription: false
}

const reducerFilter = (state, action) => {
  return {
  ...state,
  ...action
  }
}

const CustomScreenContainer = (props) => {

    const [state, updateState] = useReducer(reducerFilter, initialStateFilter);
    const [state2, updateState2] = useReducer(reducerFilter, initialStateOther);;
    const ui = useSelector((state) => state.ui);
    const customScreen = useSelector((state) => state.customScreen);
    const team = useSelector((state) => state.team);
    const session = useSelector((state) => state.session);
    const dispatch = useDispatch();

    const setOpen = (open, modalDiaLogId=false, modalDiaLogTitle='', modalName=null) => {
      updateState2({ modalDiaLogId: modalDiaLogId });
      updateState2({ modalDiaLogTitle: modalDiaLogTitle });

      if(modalName !== null) {
        if(modalName === 'VIEW') {
          updateState2({ openView: open });
        } else if(modalName === 'EDIT') {
          updateState2({ openEdit: open });
        } else if(modalName === 'DELETE') {
          updateState2({ openDelete: open });
        } else if(modalName === 'SUBSCRIPTION') {
          updateState2({ openSubscription: open });
        }
      } else {
        updateState2({ openView: false });
        updateState2({ openEdit: false });
        updateState2({ openDelete: false });
        updateState2({ openSubscription: false })
      }

    }
    const _deleteCustomScreen = (modalDiaLogId) => {
      dispatch(deleteCustomScreen(modalDiaLogId, state));
    }
    const handleInputChange = (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      updateState({ [name]: value });

      /* on Selection */
      if(['sort','direction','active','deleted'].includes(name)) {
        updateState({ page: 0 });
        let newFilters = state;
        newFilters[name] = value;
        newFilters['page'] = 0;
        dispatch(findAllCustomScreens(newFilters));
      }

    }
    const handleTableChangePage = (event, newPage) => {
      dispatch(findAllCustomScreens({...state, page: newPage }));
      updateState({ page: newPage });
    }
    const handleTableChangeRowsPerPage = (event) => {
      dispatch(findAllCustomScreens({...state, page: 0, pagesize: parseInt(event.target.value, 10) }));
      updateState({ page: 0, pagesize: parseInt(event.target.value, 10) });
    }
    const onSearchInputEnterKey = () => {
      dispatch(findAllCustomScreens({...state, page: 0}));
      updateState({ page: 0 });
    }
    const resetTableSearchInput = () => {
      updateState2({ pageLoaded: false });
    }

    useEffect(() => {
      if(!state2.pageLoaded) {
        updateState(initialStateFilter);
        updateState2({ pageLoaded: true });
        dispatch(findAllTeams({ active:1, pagesize:1000, sort:'teams.teamName', direction:'ASC'}));
        dispatch(findAllCustomScreens(initialStateFilter));
      }
    }, [state2, dispatch]);

  /*** table columns ***/
  const t_data = customScreen.data;
  const t_pagination = customScreen.pagination;

  /*** table fields ***/
  const t_columns = <GetTcolumns
    session={session}
    openGlobalLoader={ui.openGlobalLoader}/>

  /*** table search ***/
  const t_search = <GetTsearch
    onSearchInputEnterKey={onSearchInputEnterKey}
    handleInputChange={handleInputChange}
    session={session}
    resetTableSearchInput={resetTableSearchInput}
    title={state.title}
    active={state.active}
    screenOwner={state.screenOwner}
    />

  /*** table data ***/
  const t_values = <GetTvalues t_data={t_data}
    setOpen={setOpen}
    deleted={state.deleted}
    session={session}
    t_index={state.page * state.pagesize}
    />

  return (
    <main id="main" className="main">
      <PageTitleContainer pageTitle={props.pageTitle} >
        <HeaderSection
          session={session}
          filterInput={state}
          />
      </PageTitleContainer>
      <section className="section">
        <div className="row">

          <CustomDialog maxWidth="sm" title={`View Page ${state2.modalDiaLogTitle ? '- '+state2.modalDiaLogTitle : ''}`} open={state2.openView} setOpen={setOpen} >
            <ViewDetails
              modalDiaLogId={state2.modalDiaLogId}
              setOpen={setOpen}
            />
          </CustomDialog>

          <CustomDialog maxWidth="md" title={`Update Page ${state2.modalDiaLogTitle ? '- '+state2.modalDiaLogTitle : ''}`} open={state2.openEdit} setOpen={setOpen} >
            <EditForm
              modalDiaLogId={state2.modalDiaLogId}
              setOpen={setOpen}
              filterInput={state}
            />
          </CustomDialog>

          <DeleteConfirmDialog
              title={`${ state.deleted ? 'Restore': 'Delete' } Page ${state2.modalDiaLogTitle ? '- '+state2.modalDiaLogTitle : ''}`}
              modalDiaLogId={state2.modalDiaLogId}
              open={state2.openDelete}
              setOpen={setOpen}
              onConfirm={_deleteCustomScreen} >
            Are you sure you want to { state.deleted ? 'Restore': 'Delete' } Page <strong className="p-2"> {state2.modalDiaLogTitle} </strong> ?
          </DeleteConfirmDialog>

          <CustomDialog maxWidth={session.roleId === 3 ? 'lg' : 'md'} title={`Page Subscription ${state2.modalDiaLogTitle ? '- '+state2.modalDiaLogTitle : ''}`} open={state2.openSubscription} setOpen={setOpen} >
            <SubscriptionForm
              session={session}
              team={team}
              modalDiaLogId={state2.modalDiaLogId}
              setOpen={setOpen}
              filterInput={state}
            />
          </CustomDialog>

        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">

                <div className="row mb-1">
                  <div className="col col-sm-9">
                    <div className="float-start">
                      <SortTable sortName={'customScreen'}
                        sort={state.sort}
                        direction={state.direction}
                        handleInputChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="float-end">
                      <ToggleTableTrash
                        deleted={state.deleted}
                        handleInputChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-hover table-sm">
                    <thead>{ t_columns }</thead>
                    <tbody>
                      { t_search }
                      { t_values }
                    </tbody>
                  </table>
                </div>

                <TablePagination
                  component="div"
                  count={t_pagination.itemcount}
                  page={state.page}
                  onPageChange={handleTableChangePage}
                  rowsPerPage={state.pagesize}
                  onRowsPerPageChange={handleTableChangeRowsPerPage}
                  showFirstButton={true}
                  showLastButton={true} />
              </div>
            </div>
          </div>
        </div>

      </section>
    </main>
  )

}


export default CustomScreenContainer;

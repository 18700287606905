import axios from 'axios';
import getAppConfig from '../../config';
import { setGlobalLoader, caughtError } from '../ui/uiActions';
import { getQueryString } from '../../components/common/functions';

import {
  PROFILE_SET_OVERVIEW,
  PROFILE_SET_INITIAL_STATE,
  PROFILE_SET_MY_MODULES
} from './profileTypes';

const config = getAppConfig();

export const setMyProfileModules= (filters="") => {
  let URL = `${config.api.URL}/me/my-modules`;
  if(filters !== '') {
    URL = `${URL}?${getQueryString(filters)}`;
  }
  return (dispatch) => {
    dispatch(setGlobalLoader(true))
    axios.get(URL).then(response => {
      dispatch(setGlobalLoader(false))
      dispatch(OnSuccessSetMyProfileModules(response))
    }).catch(error => {
      dispatch(setGlobalLoader(false))
      dispatch(setProfileInitialState())
      dispatch(caughtError(error))
    })
  }
}

export const setProfileOverview = () => {
  let URL = `${config.api.URL}/me/my-profile`;
  return (dispatch) => {
    dispatch(setGlobalLoader(true))
    axios.get(URL).then(response => {
      dispatch(setGlobalLoader(false))
      dispatch(OnSuccessSetProfileOverview(response))
    }).catch(error => {
      dispatch(setGlobalLoader(false))
      dispatch(setProfileInitialState())
      dispatch(caughtError(error))
    })
  }
}

export const setProfileInitialState = () => {
  return {
    type: PROFILE_SET_INITIAL_STATE
  }
}

export const OnSuccessSetProfileOverview = (response) => {
  return {
    type: PROFILE_SET_OVERVIEW,
    payload: response.data.data
  }
}

export const OnSuccessSetMyProfileModules = (response) => {
  return {
    type: PROFILE_SET_MY_MODULES,
    payload: response.data.data
  }
}

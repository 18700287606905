import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircleIcon from '@mui/icons-material/Circle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { viewSale } from '../../redux';

const ViewDetails = (props) => {

  const viewResult = useSelector((state) => state.sale.viewResult);
  const [loading, setLoading] = useState(true);
  const [accountRow, toggleAccountRow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if(loading && props.modalDiaLogId && props.modalDiaLogId > 0) {
      dispatch(viewSale(props.modalDiaLogId));
      setLoading(false);
    }
  }, [props, loading, viewResult, dispatch]);


  const details = (
    loading === false && viewResult ?
    <>
    <div className="row">
      <div className="col-12">
        <ul className="list-group">
          <li className="list-group-item"><strong className="p-2">SaleId:</strong> {viewResult.id}</li>
          <li className="list-group-item"><strong className="p-2">TeamName:</strong> <CircleIcon style={{ color: viewResult.colorCode }} /> {viewResult.teamName}</li>
          <li className="list-group-item"><strong className="p-2">Industry:</strong> {viewResult.industryName}</li>
          <li className="list-group-item"><strong className="p-2">PostedBy:</strong> {viewResult.postedBy}</li>
          <li className="list-group-item"><strong className="p-2">Amount:</strong> {`$${parseFloat(viewResult.amount).toFixed(2)}`}</li>
          <li className="list-group-item"><strong className="p-2">Description:</strong> {viewResult.description}</li>
          <li className="list-group-item"><strong className="p-2">CreatedAt:</strong> {viewResult.createdAt}</li>

          <li className="list-group-item">
            <FormGroup>
              <FormControlLabel
                control={<Switch
                name="accountRow"
                checked={accountRow}
                onChange={ () => toggleAccountRow(!accountRow) } />}
                label="DisplayAccountDetails"
                labelPlacement="end" />
            </FormGroup>
          </li>
          {
            accountRow &&
            <>
            <li className="list-group-item"><strong className="p-2">AccountName:</strong> {viewResult.accountName}</li>
            <li className="list-group-item"><strong className="p-2">AccountNumber:</strong> {viewResult.accountNumber}</li>
            <li className="list-group-item"><strong className="p-2">ExternalAccountName:</strong> {viewResult.externalAccountNumber}</li>
            </>
          }


        </ul>
      </div>
    </div>
    </> : <CircularProgress /> )

  return (
    <>
    <DialogContent sx={{ marginTop: "-10px" }} >
      { details }
    </DialogContent>
    <DialogActions>
      <button className="btn btn-secondary" onClick={ () => props.setOpen(false) } >Close</button>
    </DialogActions>
    </>
  )

}

export default ViewDetails;

import { createStore, applyMiddleware } from 'redux';
import rootReducer from './rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

let store;

if(typeof(process.env.REACT_APP_ENV_MODE) !== 'undefined') {
  console.log(`REACT_APP_ENV_MODE: ${process.env.REACT_APP_ENV_MODE}`);
} else {
  console.log(`REACT_APP_ENV_MODE IS NOT DEFINED.`);
}

if(process.env.REACT_APP_ENV_MODE === 'local') {
  const composeEnhancers = composeWithDevTools(applyMiddleware(thunk));
  store = createStore(
    rootReducer,
    composeEnhancers
  );
} else {
  store = createStore(
    rootReducer,
    applyMiddleware(thunk)
  );
}

export default store;

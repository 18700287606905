import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Switch from '@mui/material/Switch';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import getAppConfig from '../../config';
const config = getAppConfig();

const RoleModuleForm = (props) => {

  const [loading, setLoading] = useState(true);
  const [roleId, setRoleId] = useState(0);
  const [modules, setModules] = useState([]);

  const handleCheckBoxSelection = (event) => {
    const currentModuleState = modules;
    const target = event.target;
    const prms = target.value.split("-");
    const isChecked = target.checked;
    const accessType = prms[0];
    const roleId = parseInt(prms[1]);
    const moduleId = parseInt(prms[2]);

    axios.patch(`${config.api.URL}/role/${roleId}/module/${moduleId}`, {
      isChecked: isChecked,
      accessType: accessType,
    }).then(response => {
      let newState = []
      for(const pModule of currentModuleState) {
        let newRow = pModule;
        if(pModule.id === moduleId) {
          newRow[accessType] = isChecked
        }
        newState.push(newRow);
      }

      setModules(newState);

    }).catch(error => {
      console.log("error =>",error);
    })

  }

  useEffect(() => {
    if(loading && props.modalDiaLogId && props.modalDiaLogId > 0) {
      const roleId = props.modalDiaLogId;
      const modules = props.module.data;

      setRoleId(roleId);

      let newState = []
      for(const pModule of modules) {
        let newRow = pModule;
        newRow.readAccess = false;
        newRow.writeAccess = false;
        newState.push(newRow);
      }

      setModules(newState);

      let URL = `${config.api.URL}/role/${props.modalDiaLogId}/modules`;
      axios.get(URL).then(response => {
        if(response.data.data.length > 0) {
          const existingPermission = response.data.data;
          let newState = []
            for(const pModule of modules) {
              let newRow = pModule;
              const existingRow = existingPermission.find( (row) => row.moduleId === pModule.id );
              if(existingRow) {
                newRow.readAccess = existingRow.readAccess;
                newRow.writeAccess = existingRow.writeAccess;
              }
              newState.push(newRow)
            }

            setModules(newState);
        }
      }).catch(error => {
        console.log(error);
      })
    }
    setLoading(false);
  }, [loading, props]);

  let t_values = <tr><td colSpan={4}>{''}</td></tr>;
  if(modules.length > 0) {
    t_values = modules.map((row) =>
      <tr key={row.id}>
        <td>{row.moduleName}</td>
        <td><Switch checked={row.readAccess} onChange={handleCheckBoxSelection} value={`readAccess-${roleId}-${row.id}`} /></td>
        <td><Switch checked={row.writeAccess} onChange={handleCheckBoxSelection} value={`writeAccess-${roleId}-${row.id}`} /></td>
      </tr>
    )
  }

  return (
    <form>
      <DialogContent sx={{ marginTop: "-20px" }} >

        <table className="table table-hover table-sm">
          <thead>
            <tr>
              <th>Module</th>
              <th>Read</th>
              <th>Write</th>
            </tr>
          </thead>
          <tbody>{t_values}</tbody>
        </table>

      </DialogContent>
      <DialogActions>
        <button className="btn btn-secondary" onClick={ (e) => {
            e.preventDefault();
            props.setOpen(false);
          } }>Close</button>
      </DialogActions>
    </form>
  )

}


export default RoleModuleForm;
